
export const arena4DefaultValues: any = {
    cardSlots: [
        {
            x: 0,
            y: 0,
            slotIsUnlocked: false,
            slotType: 'boost',
            unlockCurrency: 'gold',
            unlockPrice: 10000,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 1,
            y: 0,
            slotIsUnlocked: false,
            slotType: 'boost',
            unlockCurrency: 'gold',
            unlockPrice: 50,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 2,
            y: 0,
            slotIsUnlocked: false,
            slotType: 'boost',
            unlockCurrency: 'wood',
            unlockPrice: 100,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 3,
            y: 0,
            slotIsUnlocked: false,
            slotType: 'boost',
            unlockCurrency: 'wood',
            unlockPrice: 1000,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 0,
            y: 1,
            slotIsUnlocked: false,
            slotType: 'monster',
            currentHP: 0,
            maxHP: 0,
            unlockCurrency: 'wood',
            unlockPrice: 50,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 1,
            y: 1,
            slotIsUnlocked: true,
            slotType: 'monster',
            currentHP: 0,
            maxHP: 0,
            unlockCurrency: 'gold',
            unlockPrice: 0,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 2,
            y: 1,
            slotIsUnlocked: false,
            slotType: 'monster',
            currentHP: 0,
            maxHP: 0,
            unlockCurrency: 'gold',
            unlockPrice: 3000,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 3,
            y: 1,
            slotIsUnlocked: false,
            slotType: 'monster',
            currentHP: 0,
            maxHP: 0,
            unlockCurrency: 'gold',
            unlockPrice: 5000,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 0,
            y: 2,
            slotIsUnlocked: false,
            slotType: 'hero',
            currentHP: 0,
            maxHP: 0,
            unlockCurrency: 'wood',
            unlockPrice: 50,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 1,
            y: 2,
            slotIsUnlocked: true,
            slotType: 'hero',
            currentHP: 0,
            maxHP: 0,
            unlockCurrency: 'gold',
            unlockPrice: 200,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 2,
            y: 2,
            slotIsUnlocked: false,
            slotType: 'hero',
            currentHP: 0,
            maxHP: 0,
            unlockCurrency: 'gold',
            unlockPrice: 3000,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 3,
            y: 2,
            slotIsUnlocked: false,
            slotType: 'hero',
            currentHP: 0,
            maxHP: 0,
            unlockCurrency: 'gold',
            unlockPrice: 5000,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 0,
            y: 3,
            slotIsUnlocked: false,
            slotType: 'boost',
            unlockCurrency: 'gold',
            unlockPrice: 10000,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 1,
            y: 3,
            slotIsUnlocked: false,
            slotType: 'boost',
            unlockCurrency: 'gold',
            unlockPrice: 50,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 2,
            y: 3,
            slotIsUnlocked: false,
            slotType: 'boost',
            unlockCurrency: 'wood',
            unlockPrice: 100,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 3,
            y: 3,
            slotIsUnlocked: false,
            slotType: 'boost',
            unlockCurrency: 'wood',
            unlockPrice: 1000,
            cardInSlot: null,
            statBonuses: {},
            roundsSoFar: 0,
            estimatedLootPerSecond: { gold: 0, wood: 0 },
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        }
    ]
};
