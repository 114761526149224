import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';
import { ErrorMessageComponent } from './error-message.component';
import { UpgradeWindowComponent } from './UpgradeWindow.component';

declare var kongregateAPI;

@Component({
    selector: 'kongregate',
    template: ''
})
export class KongregateComponent implements OnInit {
    public static instance: KongregateComponent;

    private isReady: boolean = false;
    private kongregate: any = null;
    private itemIdentifierBeingPurchased: string = null;

    constructor() {
        KongregateComponent.instance = this;
    }

    ngOnInit() {
        kongregateAPI.loadAPI(function(){
            this.kongregate = kongregateAPI.getAPI();
            this.isReady = true;
            AppComponent.instance.isOnKongregate = true;
            
            // tell Kongregate how many times we've ascended because this was added later and maybe it hasn't been recorded for this player yet
            KongregateComponent.instance.submitStat('realmsunlocked', AppComponent.instance.gameState.highestUnlockedArena - 1);

            this.kongregate.mtx.requestItemList([], this.microtransactionListCallback.bind(this));
        }.bind(this));

    }

    submitStat(statName: string, val: number): void {
        if (!this.isReady) {
            return;
        }

        this.kongregate.stats.submit(statName, val);
    }

    buyMicrotransaction(itemIdentifierStr: string, optionalData: any): boolean {
        if (!this.isReady) {
            return false;
        }

        if (this.itemIdentifierBeingPurchased != null) {
            return false;
        }

        this.itemIdentifierBeingPurchased = itemIdentifierStr;

        this.kongregate.mtx.purchaseItems([itemIdentifierStr], function(result) {
            this.microtransactionPurchaseCallback(result, optionalData)
        }.bind(this));

        return true;
    }

    microtransactionPurchaseCallback(result: any, optionalData: any): void {
        console.log("Kongregate item purchase result:");
        console.log(result);
        if (result.success) {
            switch (this.itemIdentifierBeingPurchased) {
                // TODO: implement the code to give players bonuses based on what they purchased
                case "2xspeed1hour":
                    break;
                case "2xspeed1hours":
                    break;
                case "upgradecard":
                    UpgradeWindowComponent.instance.kredUpgradeCallback(optionalData);
                    break;
            }
        }
        else {
            console.error("Couldn't purchase!");
            console.error(result);
            ErrorMessageComponent.instance.show("Your purchase did not go through successfully.  Please try again.");
        }

        this.itemIdentifierBeingPurchased = null;
    }

    microtransactionListCallback(result: any): void {
        if (result.success) {
            AppComponent.instance.kongregateMicrotransactionList = result.data;
            /*
            for(var i = 0; i < result.data.length; i++) {
            var item = result.data[i];
            console.log((i+1) + ". " + item.identifier + ", " + 
                        item.id + "," + item.name);
            }
            */
        }
    }

    public getUsername(): string {

        if (isDevMode()) {
            return "akolander";
        }

        if (!this.isReady) {
            return null;
        }
        return this.kongregate.services.getUsername();
    }
}
