import { environment } from '../environments/environment';
import { Component, Input } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { KongregateComponent } from './kongregate.component';
import { ErrorMessageComponent } from './error-message.component';
import { SoundEffectManager } from './SoundEffectManager.component';

@Component({
    selector: 'options-panel',
    templateUrl: './options.component.html'
})
export class OptionsComponent {
    
    public static instance: OptionsComponent = null;
    public isVisible: boolean = false;

    public showResetConfirmation1: boolean = false;
    public showResetConfirmation2: boolean = false;

    public kongregateUsername: string = "";

    constructor() {
        OptionsComponent.instance = this;
    }

    public show() {
        this.isVisible = true;
        this.showResetConfirmation1 = false;
        this.showResetConfirmation2 = false;
    }

    public pressedOk(): void {
        this.isVisible = false;
    }

    public exportSave(): void {
        AppComponent.instance.exportSaveData();
    }

    public importSave(e): void {
        let kongUsername: string = "Steam";
        if (!environment.steam) {
            let kongUsername = KongregateComponent.instance.getUsername();
            if (kongUsername == null || kongUsername == "Guest") {
                ErrorMessageComponent.instance.show("Error importing data.  Please ensure you're logged into Kongregate and connected to the internet.  If the problem persists, please try again in a minute.");
                return;
            }
        }
                
        // getting a hold of the file reference
        var file = e.target.files[0]; 

        // setting up the reader
        var reader = new FileReader();
        reader.readAsText(file,'UTF-8');

        // here we tell the reader what to do when it's done reading...
        reader.onload = readerEvent => {
            var content = readerEvent.target['result'].toString();
            AppComponent.instance.importSaveData(content, kongUsername);
            this.isVisible = false;
        }

    }

    public resetGame(): void {
        AppComponent.instance.resetGame();
    }

    public getAppComponent(): AppComponent {
        return AppComponent.instance;
    }

    public musicSettingChanged(): void {
        if (AppComponent.instance.gameState.enableMusic) {
            SoundEffectManager.instance.turnOnMusic();
        }
        else {
            SoundEffectManager.instance.turnOffMusic();
        }
    }

    public isOnSteamFullVersion() {
        return environment.steam && !environment.steamDemo;
    }
    
    public importKongregateSave(e): void {
        let kongUsername: string = this.kongregateUsername;
                
        // getting a hold of the file reference
        var file = e.target.files[0]; 

        // setting up the reader
        var reader = new FileReader();
        reader.readAsText(file,'UTF-8');

        // here we tell the reader what to do when it's done reading...
        reader.onload = readerEvent => {
            var content = readerEvent.target['result'].toString();
            AppComponent.instance.importSaveData(content, kongUsername);
            this.isVisible = false;
        }

    }
}
