import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';
import { KongregateComponent } from './kongregate.component';

@Component({
    selector: 'premium-shop',
    templateUrl: './premium-shop.component.html'
})
export class PremiumShopComponent {
    
    public purchaseMultiplier: number = 1;

    getAppComponent(): AppComponent {
        return AppComponent.instance;
    }

    getPurchasableKongregateItems(): any[] {
        return AppComponent.instance.kongregateMicrotransactionList;
    }

    getIsOnKongregate(): boolean {
        return AppComponent.instance.isOnKongregate;
    }

    purchaseKongregateItem(identifierStr: string): void {
        let success = KongregateComponent.instance.buyMicrotransaction(identifierStr, null);
    }
}
