import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';
import { isFulfilled } from 'q';
import { ErrorMessageComponent } from './error-message.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'shop-arena1',
    templateUrl: './shop-arena1.component.html'
})
export class ShopArena1Component {

    getAppComponent(): AppComponent {
        return AppComponent.instance;
    }

    showTooltipForNextRealm(): void {
        TooltipComponent.instance.showTooltip("Coming in a future update...");
    }

    hideTooltip(): void {
        TooltipComponent.instance.hideTooltip();
    }

    tryToUnlockNextExpansion(): void {

        if (this.isSteamDemo()) {
            ErrorMessageComponent.instance.show("Not available in demo.  Haunted Woods, Underworld, Plains of War, and more are unlockable in the full version of the game.")
            return;
        }

        if (AppComponent.instance.gameState.resources.arena1['gold'] < 100000) {
            return;
        }

        AppComponent.instance.gameState.resources.arena1['gold'] -= 100000;
        AppComponent.instance.unlockArena(2);
    }
    
    purchaseMultiplierUpdated(): void {
        if (this.getAppComponent().buyMaxChecked) {
            this.getAppComponent().purchaseMultiplier = null;
            return;
        }
        
        try {
            this.getAppComponent().purchaseMultiplier = parseInt(this.getAppComponent().purchaseMultiplier + "");
        }
        catch(err) {
            this.getAppComponent().purchaseMultiplier = 1;
        }

        if (this.getAppComponent().purchaseMultiplier < 1) {
            this.getAppComponent().purchaseMultiplier = 1;
        }
    }

    public isSteamDemo(): boolean {
        return environment.steam && environment.steamDemo;
    }
}
