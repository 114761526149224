import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';
import { BoosterPackOpeningComponent } from './booster-pack-opening.component';
import { CardModels, GetCardById } from './CardModels';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NiceNumberPipe } from './NiceNumber.pipe';
import { AchievementIsUnlocked, UnlockAchievement } from './Achievements';

@Component({
    selector: 'booster-pack-purchaser',
    templateUrl: 'booster-pack-purchaser.component.html'
})
export class BoosterPackPurchaserComponent {

    @Input() currency: string;
    @Input() price: number;
    @Input() currency2: string;
    @Input() price2: number;
    @Input() currency3: string;
    @Input() price3: number;
    @Input() name: string;
    @Input() description: string;
    @Input() imageFilename: string;
    @Input() cardCount: number;
    @Input() possibleCardIds: number[];
    @Input() textColor: string = "black";
    @Input() purchaseMultiplier: number = 1;
    @Input() displaySize: number = 3;
    @Input() arenaNumber: number;

    constructor(public sanitizer: DomSanitizer, public niceNumber: NiceNumberPipe) {
    }

    public clicked() {
        if (BoosterPackOpeningComponent.instance.isActive) {
            return; // already opening a pack
        }

        if (this.canAfford()) {
            this.purchase();
        }
    }

    private purchase() {

        var amountToBuy: number = 1;
        if (this.purchaseMultiplier != null && Math.floor(this.purchaseMultiplier) > 1) {
            amountToBuy = Math.floor(this.purchaseMultiplier);
        }
        else if (this.purchaseMultiplier == null) {
            amountToBuy = this.maxAmountAffordable();
        }

        let cardsReceived = [];
        for (var i = 0; i < this.possibleCardIds.length; i++) {
            let cardData = GetCardById(this.possibleCardIds[i]);
            if (cardData == null) {
                console.error("Error: shop has a booster pack (" + this.name + ") containing a card id that doesn't exist.  Card id: " + this.possibleCardIds[i]);
                return;
            }
            cardsReceived[cardData.id] = { data: cardData, quantity: 0 };
        }

        let oddsStackedCardsToChooseFrom: number[] = [];
        if (this.possibleCardIds.length == 1) { // if only one type of card is possible from this pack (example: Ghoul/Lil Lumberjack/Priestess), just create a single-element array here
            oddsStackedCardsToChooseFrom = [this.possibleCardIds[0]];
        }
        else { // else, generate the full odds-stacked array as usual
            for (var i = 0; i < this.possibleCardIds.length; i++) {
                let cardData = GetCardById(this.possibleCardIds[i]);
                if (cardData == null) {
                    console.error("Error: shop has a booster pack (" + this.name + ") containing a card id that doesn't exist.  Card id: " + this.possibleCardIds[i]);
                    return;
                }
                let rarity = cardData.rarity;
                let howManyToInsert = 2;
                if (rarity == 1) {
                    howManyToInsert = 10;
                }
                else if (rarity == 2) {
                    howManyToInsert = 7;
                }
                else if (rarity == 3) {
                    howManyToInsert = 5;
                }
                else if (rarity == 4) {
                    howManyToInsert = 3;
                }
                else if (rarity == 5) {
                    howManyToInsert = 2;
                }
                for (var x = 0; x < howManyToInsert; x++) {
                    oddsStackedCardsToChooseFrom.push(this.possibleCardIds[i]);
                }
            }
        
            oddsStackedCardsToChooseFrom = this.shuffleArray(oddsStackedCardsToChooseFrom);
        }

        for (var packIndex = 0; packIndex < amountToBuy; packIndex++) {
            for (var i = 0; i < this.cardCount; i++) {
                let chosenCardId = null;
                if (oddsStackedCardsToChooseFrom.length == 1) { // if there's only one possibility...
                    chosenCardId = oddsStackedCardsToChooseFrom[0]; // then we know what to choose
                }
                else {
                    chosenCardId = oddsStackedCardsToChooseFrom[Math.floor(Math.random()*oddsStackedCardsToChooseFrom.length)]; // otherwise, randomly choose one
                }
                cardsReceived[chosenCardId].quantity++;
            }

            AppComponent.instance.gameState.resources['arena' + this.arenaNumber][this.currency] -= this.price;
            if (this.price2 > 0) {
                AppComponent.instance.gameState.resources['arena' + this.arenaNumber][this.currency2] -= this.price2;
            }
            if (this.price3 > 0) {
                AppComponent.instance.gameState.resources['arena' + this.arenaNumber][this.currency3] -= this.price3;
            }
        }

        cardsReceived = cardsReceived.filter((x) => {
            return x.quantity > 0;
        });

        if (this.cardCount == 5 && amountToBuy >= 100000) {
            if (!AchievementIsUnlocked(6)) { // Empty The Shelves achievement
                UnlockAchievement(6);
            }
        }

        AppComponent.instance.addCardsToCollection(cardsReceived, AppComponent.instance.gameState.selectedArenaNumber);
        BoosterPackOpeningComponent.instance.start(this.imageFilename, cardsReceived);
    }

    private getCardFromArray(arr: any[], cardId: number): any {
        for (var x = 0; x < arr.length; x++) {
            if (arr[x].data.id == cardId) {
                return arr[x];
            }
        }
        return null;
    }

    private maxAmountAffordable(): number {
        let result: number = Math.floor(AppComponent.instance.gameState.resources['arena' + this.arenaNumber][this.currency] / this.price);
        if (this.price2 > 0) {
            result = Math.min(result, Math.floor(AppComponent.instance.gameState.resources['arena' + this.arenaNumber][this.currency2] / this.price2));
        }
        if (this.price3 > 0) {
            result = Math.min(result, Math.floor(AppComponent.instance.gameState.resources['arena' + this.arenaNumber][this.currency3] / this.price3));
        }
        return result;
    }

    private canAfford(): boolean {
        let amountRequested: number = 1;
        if (this.purchaseMultiplier != null && Math.floor(this.purchaseMultiplier) > 1) {
            amountRequested = Math.floor(this.purchaseMultiplier);
        }

        return this.maxAmountAffordable() >= amountRequested;
    }

    public backgroundImageVal(): string {
        return "url(" + this.imageFilename + ")";
    }

    public mouseOver() {
        TooltipComponent.instance.showTooltip(this.description);
    }

    public mouseOut() {
        TooltipComponent.instance.hideTooltip();
    }

    private shuffleArray(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    public useWhiteText(): boolean {
        return AppComponent.instance.arenaHasDarkBackground();
    }

    public getMargin(): string {
        switch (this.displaySize) {
            case 3: {
                return "10px";
            }
            case 2: {
                return "5px";
            }
            case 1: {
                return "1px";
            }
        }

        return "10px";
    }

    public getPriceDisplay(): SafeHtml {
        let resultStr: string = "<div style='display: flex;'><img src='assets/images/resources/" + this.currency + ".png' class='resource-icon' title='" + this.currency + "' alt='" + this.currency + "'>&nbsp;" + this.niceNumber.transform(parseFloat(this.price +"")) + "</div>";
        if (this.price2 > 0) {
            resultStr += "<div style='display: flex;'><img src='assets/images/resources/" + this.currency2 + ".png' class='resource-icon' title='" + this.currency2 + "' alt='" + this.currency2 + "'>&nbsp;" + this.niceNumber.transform(parseFloat(this.price2 + "")) + "</div>";
        }
        if (this.price3 > 0) {
            resultStr += "<div style='display: flex;'><img src='assets/images/resources/" + this.currency3 + ".png' class='resource-icon' title='" + this.currency3 + "' alt='" + this.currency3 + "'>&nbsp;" + this.niceNumber.transform(parseFloat(this.price3 + "")) + "</div>";
        }
        return this.sanitizer.bypassSecurityTrustHtml(resultStr);
    }

}
