import { Component, Input } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'offline-progress',
    templateUrl: './offline-progress.component.html'
})
export class OfflineProgressComponent {
    
    public static instance: OfflineProgressComponent = null;
    public isVisible: boolean = false;

    public timePassedString: string;
    public hitMaximumTime: boolean;
    public resourcesEarned: any;

    objectKeys = Object.keys;

    constructor() {
        OfflineProgressComponent.instance = this;
    }

    public show(secondsPassed: number, hitMaximumTime: boolean, resourcesEarned: any) {

        this.timePassedString = "";

        let hoursPassed: number = Math.floor(secondsPassed / 3600);
        secondsPassed -= hoursPassed * 3600;
        let minutesPassed: number = Math.floor(secondsPassed / 60);
        secondsPassed -= minutesPassed * 60;
        let realSecondsPassed: number = secondsPassed;

        if (hoursPassed > 0) {
            this.timePassedString += hoursPassed + " hours, ";
        }
        if (minutesPassed > 0 || hoursPassed > 0) {
            this.timePassedString += minutesPassed + " minutes, ";
        }
        this.timePassedString += realSecondsPassed + " seconds";

        this.hitMaximumTime = hitMaximumTime;
        this.resourcesEarned = resourcesEarned;

        this.isVisible = true;
    }

    public pressedOk(): void {
        this.isVisible = false;
    }

}
