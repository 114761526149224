import { environment } from '../environments/environment';

import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';
import { ErrorMessageComponent } from './error-message.component';
import { UpgradeWindowComponent } from './UpgradeWindow.component';
import { OfflineProgressComponent } from './offline-progress.component';

declare var steamAPI;
declare var nw;

const CLOUD_FILENAME: string = "cci_cloud_save";

@Component({
    selector: 'steam',
    template: ''
})
export class SteamComponent implements OnInit {

    public static instance: SteamComponent;

    public isReady: boolean = false;
    public cloudLoadAttemptFinished: boolean = false;
    private steam: any = null;
    private itemIdentifierBeingPurchased: string = null;

    private queuedAchievementUnlocks: number[] = [];

    private greenworks: any = null;

    constructor() {
        SteamComponent.instance = this;
    }

    ngOnInit() {
        if (!environment.steam) {
            console.log("This is the non-Steam build.  Exiting Steam initialization.");
            return;
        }

        if (nw) {
            this.greenworks = nw.require('./greenworks.js');
        }
        else {
            console.log("nw not found, Steam won't be initialized.");
            return;
        }

        if (!this.greenworks.init()) {
            console.error('Error on initializing steam API.');
        }
        else {
            console.log("Steam initialized successfully.");
            this.isReady = true;
            AppComponent.instance.isOnSteam = true;
            this.sendAllQueuedAchievementsToSteam();
            // attempt to load from cloud in case cloud save data is newer
            this.greenworks.enableCloud(true);
            this.loadFromCloud();
        }
    }

    public getUsername(): string {

        if (isDevMode()) {
            return "Fordesoft";
        }

        if (!this.isReady) {
            return null;
        }
        return this.steam.services.getUsername();
    }

    public unlockAchievement(achievementId: number): void {
        if (AppComponent.instance.isOnSteam) {
            this.sendAchievementUnlockRequestToSteam(achievementId);
        }
        else {
            if (this.queuedAchievementUnlocks.indexOf(achievementId) == -1) {
                this.queuedAchievementUnlocks.push(achievementId);
            }
        }
    }

    public sendAllQueuedAchievementsToSteam(): void {
        for (var i = 0; i < this.queuedAchievementUnlocks.length; i++) {
            this.sendAchievementUnlockRequestToSteam(this.queuedAchievementUnlocks[i]);
        }
    }

    private sendAchievementUnlockRequestToSteam(achievementId: number): void {
        this.greenworks.activateAchievement('ACH_' + achievementId,
            function() { console.log('Activating achievement successfully'); },
            function(err) { console.error('Failed on activating achievement.'); console.error(err); });
    }

    public saveToCloud(): void {
        if (!this.greenworks.isCloudEnabled() || !this.greenworks.isCloudEnabledForUser()) {
            console.log("Cloud is not enabled.  Save data won't be saved to cloud.");
            return;
        }

        let gameStateJSON: string = JSON.stringify(AppComponent.instance.gameState);

        this.greenworks.saveTextToFile(CLOUD_FILENAME, gameStateJSON,
            function() {
                console.log("Saved to cloud successfully.");

            },
            function(err) {
                console.error("Error saving to cloud because:");
                console.error(err);
                if (JSON.stringify(err).indexOf("File doesn't exist") != -1) {
                    ErrorMessageComponent.instance.show("Error saving save data to cloud because: " + JSON.stringify(err));
                }
            }
        );
    }

    public loadFromCloud(): void {
        if (!this.greenworks.isCloudEnabled() || !this.greenworks.isCloudEnabledForUser()) {
            console.log("Cloud is not enabled.  Save data won't be loaded from cloud.");
            return;
        }

        this.greenworks.readTextFromFile(CLOUD_FILENAME,
            function(fileContent) {
                console.log("Read data from cloud successfully.");
                let cloudGameState = JSON.parse(fileContent);
                if (cloudGameState.lastSaveTimeInSeconds > AppComponent.instance.gameState.lastSaveTimeInSeconds) {
                    console.log("Cloud save was newer.  Importing.");

                    // hide any existing offline progress window if necessary
                    if (OfflineProgressComponent.instance) {
                        OfflineProgressComponent.instance.pressedOk();
                    }

                    AppComponent.instance.gameState = cloudGameState;
                    AppComponent.instance.migrateDataAndHandleOfflineProgress();
                    this.cloudLoadAttemptFinished = true;
                }
                else {
                    console.log("Cloud save was older than local data.  Not importing.");
                    this.cloudLoadAttemptFinished = true;
                }
            }.bind(this),
            function(err) {
                console.error("Error loading from cloud because:");
                console.error(err);
                this.cloudLoadAttemptFinished = true;
                ErrorMessageComponent.instance.show("Error loading save data from cloud because: " + JSON.stringify(err));
            }.bind(this)
        );
    }

}
