import { AppComponent } from './app.component';

export function RecalculateEffects(arenaNumber: number, gameState: any): void {
    const slots = gameState['arena' + arenaNumber].cardSlots;
    // reset all multipliers to empty slots
    for (var i = 0; i < slots.length; i++) {
        let typesOfResources = Object.keys(slots[i].multipliers);
        for (let resource of typesOfResources) {
            slots[i].multipliers[resource] = [];
        }
    }

    // loop over all cards, and depending on which card it is, apply its effects (to other cards nearby or whatever)
    for (var slotNumber = 0; slotNumber < slots.length; slotNumber++) {
        const cardInSlot: any = slots[slotNumber].cardInSlot;
        if (cardInSlot != null) {
            let currentCardId = cardInSlot.id;
            switch (currentCardId) {
                
                case 3: { // Gold mine.  1.25x gold for cards above and below
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }
                case 5: { // Mace. 2x gold for card to the right
                    let cardToAffectSlot: number = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 1, 0);
                    if (cardToAffectSlot != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToAffectSlot, 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }
                case 6: { // Gem.  1.5x gold for cards above and below
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }
                case 7: { // Beer.  0x gold production for other cards in this row
                    let slotNumbersOfOtherCardsInRow: number[] = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'gold', 0);
                    }
                    break;
                }
                
                case 9: { // Windmill.  1.25x gold for all other cards in play.
                    let slotNumbersOfOtherCardsInPlay: number[] = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInPlay.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInPlay[i], 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }
                case 11: { // Ice Sword.  2x gold for card above this
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }
                case 17: { // Sheep.  2.25x production for card to the left
                    let cardToLeft = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, -1, 0);
                    if (cardToLeft != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToLeft, 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }
                case 20: { // Naga.  disable gold for card below
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'gold', 0);
                    }
                    break;
                }
                case 21: { // Meteor.  disable gold for all cards except sopmething
                    var otherCardSlots = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var i = 0; i < otherCardSlots.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, otherCardSlots[i], 'gold', 0);
                    }
                    break;
                }
                case 22: { // Inn.  1.5x gold for all other cards.
                    var otherCardSlots = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var i = 0; i < otherCardSlots.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, otherCardSlots[i], 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }
                case 23: { // Honey.  2.5x gold for card below
                    let cardToLeft = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardToLeft != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToLeft, 'gold', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                // Haunted Woods cards:

                // Tier 1:

                case 31: { // Crossbow: +50% wood production (1.5x) for all adjacent cards
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                            }
                        }
                    }
                    break;
                }

                case 32: { // Gargoyle: disable wood production for other cards in row
                    let slotNumbersOfOtherCardsInRow = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'wood', 0);
                    }
                    break;
                }

                case 33: { // Hatchet: +300% wood production for all adjacent Lil' Lumberjacks
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                if (AppComponent.instance.GetCardIdAtSlot(arenaNumber, adjacentCard) == 24) {
                                    AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                                }
                            }
                        }
                    }
                    break;
                }

                case 36: // Iron Mine
                case 35: { // Gauntlet: +100% iron production for adjacent cards
                
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                            }
                        }
                    }
                    break;
                }

                case 39: { // Pipe: +75% wood production for adjacent cards
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                            }
                        }
                    }
                    break;
                }

                case 40: { // Armor: +75% wood production for cards in this column
                    let slotNumbersOfOtherCardsInCol = AppComponent.instance.getSlotNumbersOfCardsInColumn(arenaNumber, slots[slotNumber].x, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInCol.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInCol[i], 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 41: { // Watchtower: +75% wood production for cards in this row
                    let slotNumbersOfOtherCardsInRow = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 42: { // Potion: +100% iron production for cards in this row
                    let slotNumbersOfOtherCardsInRow = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 43: { // Starfire: +25% wood production for all cards in play
                    let slotNumbersOfOtherCardsInPlay = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInPlay.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInPlay[i], 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 44: { // Pancakes: +200% iron production for the card below
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 45: { // Warpstone: +200% iron production for the card to the left
                    let cardToTheLeft = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, -1, 0);
                    if (cardToTheLeft != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToTheLeft, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 46: { // Petrify: +200% iron production for the card above
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 47: { // Sushi: +200% iron production for the card to the right
                    let cardToTheRight = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 1, 0);
                    if (cardToTheRight != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToTheRight, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 48: { // Forest Inn: +25% iron production for all cards
                    let slotNumbersOfOtherCardsInPlay = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInPlay.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInPlay[i], 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 51: { // Meat: +250% wood production for card below
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 56: { // Heart: +300% iron production for the card below
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 57: { // Orc: +200% iron for the card to the left
                    let cardToTheLeft = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, -1, 0);
                    if (cardToTheLeft != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToTheLeft, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }

                case 58: { // Safe Refuge: +40% iron, +40% wood for all other cards
                    let slotNumbersOfOtherCardsInPlay = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInPlay.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInPlay[i], 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInPlay[i], 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 60: { // Skull Helm: +300% wood or iron for the card to the right
                    let cardToTheRight = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 1, 0);
                    if (cardToTheRight != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToTheRight, 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToTheRight, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }
                
                case 61: { // Cataclysm: +200% iron production for all cards. Disable all wood production
                    let slotNumbersOfOtherCardsInPlay = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInPlay.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInPlay[i], 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInPlay[i], 'wood', 0);
                    }
                    break;
                }

                case 63: { // Roo: +200% wood for card to the right
                    let cardToTheRight = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 1, 0);
                    if (cardToTheRight != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToTheRight, 'wood', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }

                case 64: { // Noclops: +200% iron for the card above
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 2));
                    }
                    break;
                }

                case 65: { // Spider King: +200% iron for all other cards
                    let slotNumbersOfOtherCardsInPlay = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInPlay.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInPlay[i], 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 2));
                    }
                    break;
                }
                case 67: { // Warrior. +25 wood/sec. Disable wood production for other cards in row
                    let slotNumbersOfOtherCardsInRow: number[] = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'wood', 0);
                    }
                    break;
                }
                case 68: { // Hood.  +300% iron production and disable wood for adjacent cards
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'iron', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'wood', 0);
                            }
                        }
                    }
                    break;
                }
                // Arena 3 cards:

                // Tier 1:
                
                case 70: { // Jar of Death. +{modifier0%}% skulls production for adjacent cards
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                            }
                        }
                    }
                    break;
                }

                case 76: { // Scimitar.  +{modifier0} skulls production for card above
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }
                
                case 83: { // Icy Outpost. +{modifier0%}% feathers production for adjacent cards
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                            }
                        }
                    }
                    break;
                }
                
                case 84: { // Obelisk. +{modifier0%}% ether production for adjacent cards, disable skulls production for them
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'skulls', 0); // disable skulls production
                            }
                        }
                    }
                    break;
                }

                case 85: { // Shield.  +some% feathers production for cards above and below
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 86: { // Shaman.  +some skulls/sec, +% ether production for cards in this row
                    let slotNumbersOfOtherCardsInRow = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }

                case 87: { // Mana Potion.  +some% ether production for cards above and below
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 90: { // Hideaway
                    let slotNumbersOfOtherCardsInCol = AppComponent.instance.getSlotNumbersOfCardsInColumn(arenaNumber, slots[slotNumber].x, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInCol.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInCol[i], 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 91: { // Dead Fish
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 92: { // Ice Fish
                    let cardToLeft = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, -1, 0);
                    if (cardToLeft != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToLeft, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 95: { // Werewolf
                    let cardAffected = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 1, 0);
                    if (cardAffected != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAffected, 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }

                case 96: { // Wagon
                    let slotNumbersOfOtherCardsInRow = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 98: { // Fort
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 99: { // Crawler
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                            }
                        }
                    }
                    break;
                }
                
                case 100: { // Slug
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }

                case 101: { // Sign
                    let slotNumbersOfOtherCardsInRow = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }
                
                case 102: { // Winged Imp
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardBelow, 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }
                
                case 105: { // Knight Capt
                    let cardToRight = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 1, 0);
                    if (cardToRight != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToRight, 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }

                case 107: { // Ruins
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                            }
                        }
                    }
                    break;
                }

                case 112: { // Oasis
                    for (var xOffset = -1; xOffset <= 1; xOffset++) {
                        for (var yOffset = -1; yOffset <= 1; yOffset++) {
                            let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                            if (adjacentCard != null) {
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                                AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, adjacentCard, 'feathers', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                            }
                        }
                    }
                    break;
                }

                case 113: { // Knight Capt
                    let cardToRight = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 1, 0);
                    if (cardToRight != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardToRight, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 116: { // Trampler
                    let slotNumbersOfOtherCardsInRow = AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, slots[slotNumber].y, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInRow.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInRow[i], 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 1));
                    }
                    break;
                }

                case 118: { // Shellhouse
                    let slotNumbersOfOtherCardsInCol = AppComponent.instance.getSlotNumbersOfCardsInColumn(arenaNumber, slots[slotNumber].x, slotNumber);
                    for (var i = 0; i < slotNumbersOfOtherCardsInCol.length; i++) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, slotNumbersOfOtherCardsInCol[i], 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 120: { // Turtle
                    let cardAbove = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1);
                    if (cardAbove != null) {
                        AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, cardAbove, 'ether', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                    }
                    break;
                }

                case 122: { // Fishery
                    let otherCardSlots = AppComponent.instance.getSlotNumbersOfCardsInPlay(arenaNumber, slotNumber);
                    for (var otherCardSlotIndex = 0; otherCardSlotIndex < otherCardSlots.length; otherCardSlotIndex++) {
                        let theOtherSlot = otherCardSlots[otherCardSlotIndex];
                        if (AppComponent.instance.GetCardIdAtSlot(arenaNumber, theOtherSlot) == 121) { // 121 == iron cat
                            AppComponent.instance.pushMultiplier(arenaNumber, slotNumber, theOtherSlot, 'skulls', rankAdjustedModifier(currentCardId, arenaNumber, 0));
                        }       
                    }
                    break;
                }

            }
        }
    }
}

function rankAdjustedModifier(cardId: number, arenaNumber: number, modifierIndex: number): number {
    return AppComponent.instance.rankAdjustedModifier(cardId, arenaNumber, modifierIndex);
}
