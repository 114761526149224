import { AppComponent } from './app.component';
import { GetCardById } from './CardModels';
import { AchievementIsUnlocked, UnlockAchievement } from './Achievements';

export function TickBattle(arenaNumber: number): any {
    let resourcesEarned = {}; // return value.  example: resourcesEarned['gold'] = 0, etc.
    let heroSlots = [];
    let monsterSlots = [];

    // initialize things
    switch (arenaNumber) {
        case 4: {
            heroSlots = [8,9,10,11];
            monsterSlots = [8,9,10,11];
            resourcesEarned['gold'] = 0;
            resourcesEarned['wood'] = 0;
            break;
        }
        default:
            console.warn("TickBattle() not defined for arena " + arenaNumber);
    }

    // have the heroes and monsters attack each other
    for (var heroIndex = 0; heroIndex < heroSlots.length; heroIndex++) {
        let heroSlot = AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[heroSlots[heroIndex]];
        let monsterSlot = GetMonsterSlotForHeroSlot(arenaNumber, heroSlot);

        let heroBoostSlot = GetBoostSlotForHeroSlot(arenaNumber, heroSlot);
        let monsterBoostSlot = GetBoostSlotForMonsterSlot(arenaNumber, monsterSlot);
        let heroBoost = (heroBoostSlot.cardInSlot != null ? GetCardById(heroBoostSlot.cardInSlot.id) : null);
        let monsterBoost = (monsterBoostSlot.cardInSlot != null ? GetCardById(monsterBoostSlot.cardInSlot.id) : null);

        let heroHasPotionEffect: boolean = (heroBoost != null && heroBoost.boostPotionEffect);
        let heroHasDoubleCriticalRate: boolean = (heroBoost != null && heroBoost.doubleCriticalRateEffect);
        let monsterHasDoubleCriticalRate: boolean = (monsterBoost != null && monsterBoost.doubleCriticalRateEffect);

        // reset these values to defaults
        if (heroSlot)
            heroSlot.foughtInLastTick = false;
        if (monsterSlot) {
            monsterSlot.foughtInLastTick = false;
            monsterSlot.droppedLootLastTick = false;
        }

        if (heroSlot.cardInSlot != null && monsterSlot.cardInSlot == null) {
            heroSlot.roundsSoFar = 0;
            heroSlot.estimatedLootPerSecond = { gold: 0, wood: 0 };
        }

        if (heroSlot.cardInSlot != null && monsterSlot.cardInSlot != null) {
            heroSlot.roundsSoFar++;

            if (heroSlot.currentHP <= 0 || monsterSlot.currentHP <= 0) {
                // reset battle this round instead of fighting
                if (monsterSlot.currentHP <= 0 && (heroSlot.currentHP > 0 || heroHasPotionEffect)) {

                    // Mascot Slayer achievement: kill scarecrow in one hit
                    if (!AchievementIsUnlocked(13)) {
                        if (heroSlot.roundsSoFar == 2 && monsterSlot.cardInSlot.id == 131) {
                            UnlockAchievement(13);
                        }
                    }
                    // Harder Than It Looks achievement: kill dead fish in 4+ rounds
                    if (!AchievementIsUnlocked(14)) {
                        if (heroSlot.roundsSoFar >= 5 && monsterSlot.cardInSlot.id == 144) {
                            UnlockAchievement(14);
                        }
                    }

                    // hero won the battle!  award resources
                    let goldWon: number = AppComponent.instance.rankAdjustedModifier(monsterSlot.cardInSlot.id, arenaNumber, 4);
                    let woodWon: number = AppComponent.instance.rankAdjustedModifier(monsterSlot.cardInSlot.id, arenaNumber, 5);

                    // apply ascension boost (universal aura)
                    goldWon *= AppComponent.instance.UniversalAura(arenaNumber);
                    woodWon *= AppComponent.instance.UniversalAura(arenaNumber);

                    if (monsterBoost != null) {
                        // loot boosts
                        goldWon *= 1+(AppComponent.instance.rankAdjustedModifier(monsterBoost.id, arenaNumber, 4));
                        woodWon *= 1+(AppComponent.instance.rankAdjustedModifier(monsterBoost.id, arenaNumber, 5));
                    }

                    resourcesEarned['gold'] += goldWon;
                    resourcesEarned['wood'] += woodWon;

                    heroSlot.estimatedLootPerSecond = { gold: (goldWon / heroSlot.roundsSoFar), wood: (woodWon / heroSlot.roundsSoFar) };

                    heroSlot.roundsSoFar = 0;

                    monsterSlot.droppedLootLastTick = true;

                }
                else if (heroSlot.currentHP <= 0 && !heroHasPotionEffect) {
                    // monster won the battle and no potion, so the hero lost hte battle.
                    heroSlot.estimatedLootPerSecond = { gold: 0, wood: 0 };
                    heroSlot.roundsSoFar = 0;
                }
                // heal hero and monster
                
                // heal hero and monster
                heroSlot.currentHP = AppComponent.instance.rankAdjustedModifier(heroSlot.cardInSlot.id, arenaNumber, 0) * AppComponent.instance.UniversalAura(arenaNumber);
                if (heroBoost) {
                    heroSlot.currentHP += AppComponent.instance.rankAdjustedModifier(heroBoost.id, arenaNumber, 0) * AppComponent.instance.UniversalAura(arenaNumber);
                }
                if (monsterSlot.currentHP <= 0 || !heroHasPotionEffect) {
                    monsterSlot.currentHP = AppComponent.instance.rankAdjustedModifier(monsterSlot.cardInSlot.id, arenaNumber, 0) * AppComponent.instance.UniversalAura(arenaNumber);
                    if (monsterBoost) {
                        monsterSlot.currentHP += AppComponent.instance.rankAdjustedModifier(monsterBoost.id, arenaNumber, 0) * AppComponent.instance.UniversalAura(arenaNumber);
                    }
                }
                
            }
            else if (heroSlot.cardInSlot && monsterSlot && monsterSlot.cardInSlot != null) {
                // have them attack each other
                // attack damage = ATK - DEF (minimum 0), and whoever has the higher MAG deals a bonus equal to their MAG difference.

                let heroAtk = AppComponent.instance.rankAdjustedModifier(heroSlot.cardInSlot.id, arenaNumber, 1) * AppComponent.instance.UniversalAura(arenaNumber);
                let monsterAtk = AppComponent.instance.rankAdjustedModifier(monsterSlot.cardInSlot.id, arenaNumber, 1) * AppComponent.instance.UniversalAura(arenaNumber);
                let heroDef = AppComponent.instance.rankAdjustedModifier(heroSlot.cardInSlot.id, arenaNumber, 2) * AppComponent.instance.UniversalAura(arenaNumber);
                let monsterDef = AppComponent.instance.rankAdjustedModifier(monsterSlot.cardInSlot.id, arenaNumber, 2) * AppComponent.instance.UniversalAura(arenaNumber);
                let heroMag = AppComponent.instance.rankAdjustedModifier(heroSlot.cardInSlot.id, arenaNumber, 3) * AppComponent.instance.UniversalAura(arenaNumber);
                let monsterMag = AppComponent.instance.rankAdjustedModifier(monsterSlot.cardInSlot.id, arenaNumber, 3) * AppComponent.instance.UniversalAura(arenaNumber);

                // apply stat boosts
                if (heroBoost != null) {
                    heroAtk += AppComponent.instance.rankAdjustedModifier(heroBoost.id, arenaNumber, 1) * AppComponent.instance.UniversalAura(arenaNumber);
                    heroDef += AppComponent.instance.rankAdjustedModifier(heroBoost.id, arenaNumber, 2) * AppComponent.instance.UniversalAura(arenaNumber);
                    heroMag += AppComponent.instance.rankAdjustedModifier(heroBoost.id, arenaNumber, 3) * AppComponent.instance.UniversalAura(arenaNumber);
                }
                if (monsterBoost != null) {
                    monsterAtk += AppComponent.instance.rankAdjustedModifier(monsterBoost.id, arenaNumber, 1) * AppComponent.instance.UniversalAura(arenaNumber);
                    monsterDef += AppComponent.instance.rankAdjustedModifier(monsterBoost.id, arenaNumber, 2) * AppComponent.instance.UniversalAura(arenaNumber);
                    monsterMag += AppComponent.instance.rankAdjustedModifier(monsterBoost.id, arenaNumber, 3) * AppComponent.instance.UniversalAura(arenaNumber);
                }

                let heroLandedCritical: boolean = false;
                let monsterLandedCritical: boolean = false;

                // 1 in 10 chance of a critical hit
                if (Math.floor(Math.random() * (heroHasDoubleCriticalRate ? 5 : 10)) == 0) {
                    heroLandedCritical = true;
                }
                if (Math.floor(Math.random() * (monsterHasDoubleCriticalRate ? 5 : 10)) == 0) {
                    monsterLandedCritical = true;
                }
                
                let herosAttackDamage = (heroLandedCritical ? 2 : 1) * Math.max(0, heroAtk - monsterDef);
                let monstersAttackDamage = (monsterLandedCritical ? 2 : 1) * Math.max(0, monsterAtk - heroDef);
                if (heroMag > monsterMag) {
                    herosAttackDamage += heroMag - monsterMag;
                }
                else {
                    monstersAttackDamage += monsterMag - heroMag;
                }

                heroSlot.currentHP -= monstersAttackDamage;
                monsterSlot.currentHP -= herosAttackDamage;

                if (heroSlot.currentHP < 0) {
                    heroSlot.currentHP = 0;
                }
                if (monsterSlot.currentHP < 0) {
                    monsterSlot.currentHP = 0;
                }

                heroSlot.damageTakenThisRound = monstersAttackDamage;
                monsterSlot.damageTakenThisRound = herosAttackDamage;

                heroSlot.foughtInLastTick = true;
                monsterSlot.foughtInLastTick = true;
            }
        }

    }

    return resourcesEarned;

}

export function GetMonsterSlotForHeroSlot(arenaNumber: number, heroSlot: any): any {
    let x = heroSlot.x;
    let y = heroSlot.y - 1;

    let slotNumber = AppComponent.instance.getCardSlotNumberAtXYLocation(arenaNumber, x, y);
    return AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[slotNumber];
}

export function GetHeroSlotForMonsterSlot(arenaNumber: number, monsterSlot: any): any {
    let x = monsterSlot.x;
    let y = monsterSlot.y + 1;

    let slotNumber = AppComponent.instance.getCardSlotNumberAtXYLocation(arenaNumber, x, y);
    return AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[slotNumber];
}

export function GetBoostSlotForHeroSlot(arenaNumber: number, heroSlot: any): any {
    let x = heroSlot.x;
    let y = heroSlot.y + 1;

    let slotNumber = AppComponent.instance.getCardSlotNumberAtXYLocation(arenaNumber, x, y);
    return AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[slotNumber];
}

export function GetBoostSlotForMonsterSlot(arenaNumber: number, monsterSlot: any): any {
    let x = monsterSlot.x;
    let y = monsterSlot.y - 1;

    if (y < 0) {
        return null;
    }

    let slotNumber = AppComponent.instance.getCardSlotNumberAtXYLocation(arenaNumber, x, y);
    return AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[slotNumber];
}

export function GetHeroSlotForBoostSlot(arenaNumber: number, boostSlot: any): any {
    let x = boostSlot.x;
    let y = boostSlot.y + 1;

    let slotNumber = AppComponent.instance.getCardSlotNumberAtXYLocation(arenaNumber, x, y);
    if (slotNumber >= AppComponent.instance.gameState['arena' + arenaNumber].cardSlots.length) {
        return null;
    }
    if (AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[slotNumber]) {
        return AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[slotNumber];
    }
    return null;
}

export function GetMonsterSlotForBoostSlot(arenaNumber: number, boostSlot: any): any {
    let x = boostSlot.x;
    let y = boostSlot.y - 1;

    if (y < 0) {
        return null;
    }

    let slotNumber = AppComponent.instance.getCardSlotNumberAtXYLocation(arenaNumber, x, y);
    if (AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[slotNumber]) {
        return AppComponent.instance.gameState['arena' + arenaNumber].cardSlots[slotNumber];
    }
    return null;
}