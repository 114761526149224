import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';
import { BoosterPackOpeningComponent } from './booster-pack-opening.component';
import { CardModels, GetCardById } from './CardModels';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NiceNumberPipe } from './NiceNumber.pipe';
import { ErrorMessageComponent } from './error-message.component';
import { AchievementIsUnlocked, UnlockAchievement, NumberOfAchievementsUnlocked } from './Achievements';

var gemCosts = {
    arena1: [],
    arena2: [],
    arena3: [],
    arena4: []
}

@Component({
    selector: 'ascend-tab',
    templateUrl: 'AscendTab.component.html',
    styleUrls: ['./AscendTab.component.css']
})
export class AscendTabComponent implements OnInit {
    @Input() arenaNumber: number;

    public appComponent: AppComponent;

    public subtab: string = 'ascend';
    public showConfirmationScreen: boolean = false;

    constructor(private sanitizer: DomSanitizer) {
        gemCosts.arena1[0] = { gold: 1000000 };
        for (var i = 1; i < 50; i++) {
            gemCosts.arena1[i] = { gold: (gemCosts.arena1[0]['gold'] * Math.pow(i+1, 2.5)) };
        }

        gemCosts.arena2[0] = { wood: 1000000, iron: 2000000 };
        for (var i = 1; i < 50; i++) {
            gemCosts.arena2[i] = {
                wood: (gemCosts.arena2[0]['wood'] * Math.pow(i+1, 2.5)),
                iron: (gemCosts.arena2[0]['iron'] * Math.pow(i+1, 2.5))
            };
        }

        gemCosts.arena3[0] = { ether: 3000000, skulls: 3000000, feathers: 3000000 };
        for (var i = 1; i < 50; i++) {
            gemCosts.arena3[i] = {
                ether: (gemCosts.arena3[0]['ether'] * Math.pow(i+1, 2.5)),
                skulls: (gemCosts.arena3[0]['skulls'] * Math.pow(i+1, 2.5)),
                feathers: (gemCosts.arena3[0]['feathers'] * Math.pow(i+1, 2.5))
            };
        }

        gemCosts.arena4[0] = { gold: 1000000, wood: 1000000 };
        for (var i = 1; i < 50; i++) {
            gemCosts.arena4[i] = { gold: (gemCosts.arena4[0]['gold'] * Math.pow(i+1, 2.5)), wood: (gemCosts.arena4[0]['wood'] * Math.pow(i+1, 2.5))  };
        }

    }

    ngOnInit() {
        this.appComponent = AppComponent.instance;
    }

    resourceTypesInArena(): string[] {
        return Object.keys(this.appComponent.gameState.lifetimeResourcesEarned['arena' + this.arenaNumber]);
    }

    percentageToNextGem(): number {
        if (this.hasReceivedAllGemsFromArena()) {
            return 0;
        }

        const amountsNeeded = this.amountsNeededForNextGem();
        if (amountsNeeded == null) {
            return 1;
        }
        var percentage: number = 1;
        for (const [resource, amtNeeded] of Object.entries(amountsNeeded)) {
            let numberOfGemsReceivedSoFarFromThisArena: number = AppComponent.instance.gameState.lifetimeGemsReceived[this.arenaNumber];
            let amountThatWasNeededLastTime = 0;
            if (numberOfGemsReceivedSoFarFromThisArena >= 1) {
                amountThatWasNeededLastTime = gemCosts['arena' + this.arenaNumber][numberOfGemsReceivedSoFarFromThisArena - 1][resource];
            }

            let percentageThisResource: number = (this.lifetimeResourcesEarnedPlusAchievementBoost(resource) - amountThatWasNeededLastTime) / (amtNeeded - amountThatWasNeededLastTime);

            if (percentageThisResource < percentage) {
                percentage = percentageThisResource;
            }
        }

        return percentage;
    }

    amountsNeededForNextGem(): number {
        if (this.hasReceivedAllGemsFromArena()) {
            return 0;
        }

        let numberOfGemsReceivedSoFarFromThisArena: number = AppComponent.instance.gameState.lifetimeGemsReceived[this.arenaNumber];
        if (numberOfGemsReceivedSoFarFromThisArena + this.amountOfGemsThatWouldBeEarnedViaAscension() + 1 >= gemCosts['arena' + this.arenaNumber].length) {
            return null;
        }
        return gemCosts['arena' + this.arenaNumber][numberOfGemsReceivedSoFarFromThisArena + this.amountOfGemsThatWouldBeEarnedViaAscension() + 1];
    }

    hasReceivedAllGemsFromArena(): boolean {
        let numberOfGemsReceivedSoFarFromThisArena: number = AppComponent.instance.gameState.lifetimeGemsReceived[this.arenaNumber];
        return (numberOfGemsReceivedSoFarFromThisArena >= gemCosts.arena1.length);
    }

    baseLifetimeAmountEarned(resourceName: string): number {
        return this.appComponent.gameState.lifetimeResourcesEarned['arena' + this.arenaNumber][resourceName];
    }

    amountOfGemsThatWouldBeEarnedViaAscension(): number {
        let numberOfGemsReceivedSoFarFromThisArena: number = AppComponent.instance.gameState.lifetimeGemsReceived[this.arenaNumber];
        let wouldBeNewTotalReceived: number = 0;
        for (var i = numberOfGemsReceivedSoFarFromThisArena; i < gemCosts['arena' + this.arenaNumber].length; i++) {
            let resourceTypes: string[] = this.resourceTypesInArena();
            let hasEnoughForThisGem: boolean = true;
            for (let resource of resourceTypes) {
                if (gemCosts['arena' + this.arenaNumber][i][resource] > this.lifetimeResourcesEarnedPlusAchievementBoost(resource)) {
                    hasEnoughForThisGem = false;
                }
            }
            if (hasEnoughForThisGem) {
                wouldBeNewTotalReceived++;
            }
            else {
                break;
            }
        }
        return wouldBeNewTotalReceived;
    }

    lifetimeResourcesEarnedPlusAchievementBoost(resource: string): number {
        let baseAmount: number = this.baseLifetimeAmountEarned(resource);
        let achievementMultiplier: number = 1 + (NumberOfAchievementsUnlocked() / 50);
        return baseAmount * achievementMultiplier;
    }

    achievementMultiplierPercentageDisplay(): string {
        return (100*(NumberOfAchievementsUnlocked() / 50)).toFixed(0) + "%";
    }

    progressBarForegroundStyle() {
        return this.sanitizer.bypassSecurityTrustStyle("width: " + Math.floor(this.percentageToNextGem() * 200) + "px");
    }

    resourcesInThisArena(): string[] {
        return Object.keys(gemCosts['arena' + this.arenaNumber][0]);
    }

    ascendConfirmed(): void {
        let gemsEarned = this.amountOfGemsThatWouldBeEarnedViaAscension();
        AppComponent.instance.gameState.gems += gemsEarned;
        AppComponent.instance.gameState.lifetimeGemsReceived[this.arenaNumber] += gemsEarned;
        this.showConfirmationScreen = false;

        // "Ascension!" achievement
        if (!AchievementIsUnlocked(8)) {
            if (gemsEarned >= 1) {
                UnlockAchievement(8);
            }
        }

        AppComponent.instance.stopChoosingEmptySlotForCard();

        AppComponent.instance.removeAllCardsFromGrid();

        AppComponent.instance.tryToRankUpAllCards(this.arenaNumber);
        AppComponent.instance.resetRealm(this.arenaNumber);
    }

    priceForOfflineUpgrade(): number {
        return 1;
    }

    buyOfflineUpgrade(): void {
        if (AppComponent.instance.gameState.gems < this.priceForOfflineUpgrade()) {
            ErrorMessageComponent.instance.show("You don't have enough <img src='assets/images/resources/gem.png' title='Gem' alt='Gem'>");
            return;
        }
        else if (AppComponent.instance.gameState.maxOfflineTime >= 86400) {
            ErrorMessageComponent.instance.show("You've already reached the maximum cap of 24 hours.");
            return;
        }
        else {
            AppComponent.instance.gameState.gems--;
            AppComponent.instance.gameState.maxOfflineTime += 14400; // + 4 hours (14400 seconds)

            let newCapString = this.secondsToTimeString(AppComponent.instance.gameState.maxOfflineTime);

            // TODO at some point: use a generic MessageComponent class instead of ErrorMessageCOmponent probably. oh well; doesn't actually matter at this point.
            ErrorMessageComponent.instance.show("Offline cap increased to " + newCapString);
        }
    }

    currentOfflineCap(): string {
        return this.secondsToTimeString(AppComponent.instance.gameState.maxOfflineTime);
    }

    secondsToTimeString(seconds: number): string {
        let totalTimeSeconds: number = seconds;

        let hoursPassed: number = Math.floor(totalTimeSeconds / 3600);
        totalTimeSeconds -= hoursPassed * 3600;
        let minutesPassed: number = Math.floor(totalTimeSeconds / 60);
        totalTimeSeconds -= minutesPassed * 60;
        let realtotalTimeSeconds: number = totalTimeSeconds;

        let newCapString: string = "";

        if (hoursPassed > 0) {
            newCapString += hoursPassed + " hours, ";
        }
        if (minutesPassed > 0 || hoursPassed > 0) {
            newCapString += minutesPassed + " minutes, ";
        }
        newCapString += realtotalTimeSeconds + " seconds";

        return newCapString;
    }

    lifetimeGemsReceived(): number {
        return AppComponent.instance.gameState.lifetimeGemsReceived[this.arenaNumber];
    }
}
