
export const arena3DefaultValues: any = {
    
    cardSlots: [
        {
            x: 0,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'feathers',
            unlockPrice: 10,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 1,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 50,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 2,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 1000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 3,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'ether',
            unlockPrice: 2500,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 4,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'feathers',
            unlockPrice: 2000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 0,
            y: 1,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 750,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 1,
            y: 1,
            slotIsUnlocked: true,
            unlockCurrency: 'skulls',
            unlockPrice: 0,
            cardInSlot: null,
            //cardInSlot: { id: 69 }, // TODO: remove this back to null
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 2,
            y: 1,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 200,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 3,
            y: 1,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 500,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 4,
            y: 1,
            slotIsUnlocked: false,
            unlockCurrency: 'feathers',
            unlockPrice: 750,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 0,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'ether',
            unlockPrice: 100,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 1,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'feathers',
            unlockPrice: 200,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 2,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'ether',
            unlockPrice: 500,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 3,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'ether',
            unlockPrice: 1250,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 4,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'ether',
            unlockPrice: 2500,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 0,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'ether',
            unlockPrice: 5000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 1,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'feathers',
            unlockPrice: 3500,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 2,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 7500,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 3,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 5000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 4,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 15000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 0,
            y: 4,
            slotIsUnlocked: false,
            unlockCurrency: 'ether',
            unlockPrice: 20000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 1,
            y: 4,
            slotIsUnlocked: false,
            unlockCurrency: 'ether',
            unlockPrice: 25000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 2,
            y: 4,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 12500,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 3,
            y: 4,
            slotIsUnlocked: false,
            unlockCurrency: 'feathers',
            unlockPrice: 30000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        },
        {
            x: 4,
            y: 4,
            slotIsUnlocked: false,
            unlockCurrency: 'skulls',
            unlockPrice: 10000,
            cardInSlot: null,
            multipliers: {
                ether: [],
                skulls: [],
                feathers: []
            }
        }
    ]
};