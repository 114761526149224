import { Component, Input, OnInit } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'sound-effect-manager',
    template: ''
})
export class SoundEffectManager implements OnInit {
    
    public static instance: SoundEffectManager = null;

    public soundEffectNames: string[] = ['cantdothat', 'placecard', 'removecard'];
    public musicNames: string[] = ['arena1', 'arena2', 'arena3', 'arena4'];

    public soundFiles: any = [];
    public musicFiles: any = [];

    constructor() {
        SoundEffectManager.instance = this;
    }

    ngOnInit() {
        this.loadSounds();
        this.loadMusic();
        this.turnOnMusic();
    }

    public loadSounds() {
        for (var i = 0; i < this.soundEffectNames.length; i++) {
            let soundEffectName: string = this.soundEffectNames[i];
            this.soundFiles[soundEffectName] = new Audio();
            this.soundFiles[soundEffectName].src = "assets/sounds/" + soundEffectName + ".wav";
            this.soundFiles[soundEffectName].load();
        }
    }

    public loadMusic() {
        let musicToAutoplay: string = '';
        if (AppComponent.instance.gameState.enableMusic) {
            musicToAutoplay = this.getMusicNameForArenaNumber(AppComponent.instance.gameState.selectedArenaNumber);
        }

        for (var i = 0; i < this.musicNames.length; i++) {
            let musicName: string = this.musicNames[i];
            this.musicFiles[musicName] = new Audio();
            this.musicFiles[musicName].src = "assets/music/" + musicName + ".mp3";
            this.musicFiles[musicName].loop = true;
            this.musicFiles[musicName].volume = 0.2;
            if (musicToAutoplay.length > 0 && musicToAutoplay == musicName) {
                this.musicFiles[musicName].autoplay = true;
            }
            this.musicFiles[musicName].load();
        }
    }

    public playSound(soundName: string): void {
        if (!AppComponent.instance.gameState.enableSoundEffects) {
            return;
        }

        if (this.soundEffectNames.indexOf(soundName) == -1) {
            console.error("Tried to play sound with name '" + soundName + "', but that doesn't exist in SoundEffectManager.");
            return;
        }

        this.soundFiles[soundName].play();

    }

    private playMusic(musicName: string): void {

        if (this.musicNames.indexOf(musicName) == -1) {
            console.error("Tried to play music with name '" + musicName + "', but that doesn't exist in SoundEffectManager.");
            return;
        }

        this.musicFiles[musicName].play();

    }

    public turnOnMusic(): void {
        this.turnOffMusic(); // turn off any other music that may already be playing

        if (!AppComponent.instance.gameState.enableMusic) {
            return;
        }

        let musicName: string = this.getMusicNameForArenaNumber(AppComponent.instance.gameState.selectedArenaNumber);
        this.playMusic(musicName);

    }

    public turnOffMusic(): void {
        for (var i = 0; i < this.musicNames.length; i++) {
            let musicName: string = this.musicNames[i];
            this.musicFiles[musicName].pause();
        }
    }

    public getMusicNameForArenaNumber(arenaNumber: number): string { 
        arenaNumber = parseInt("" + arenaNumber);
        switch (arenaNumber) {
            case 1:
                return 'arena1';
            case 2:
                return 'arena2';
            case 3:
                return 'arena3';
            case 4:
                return 'arena4';
        }
        console.error("No music found for arena number " + arenaNumber + " in SoundEffectManager");
        return 'unknown';
    }

    public arenaChanged(): void {
        // switch arena music as necessary

        if (!AppComponent.instance.gameState.enableMusic) {
            return;
        }

        this.turnOnMusic();
    }
}
