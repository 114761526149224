import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';

@Component({
    selector: 'shop-arena4',
    templateUrl: './shop-arena4.component.html'
})
export class ShopArena4Component {

    getAppComponent(): AppComponent {
        return AppComponent.instance;
    }

    showTooltipForNextRealm(): void {
        TooltipComponent.instance.showTooltip("Coming in a future update...");
    }

    hideTooltip(): void {
        TooltipComponent.instance.hideTooltip();
    }

    shouldDisableNextExpansionUnlockButton() {
        console.log("Wood: " + this.getAppComponent().gameState.resources.arena2.wood);
        console.log("Iron: " + this.getAppComponent().gameState.resources.arena2.iron);
        return this.getAppComponent().gameState.resources.arena2.wood < 25000000 || this.getAppComponent().gameState.resources.arena2.iron < 100000000;
    }

    tryToUnlockNextExpansion(): void {
        return; // coming soon...

        if (this.getAppComponent().gameState.resources.arena2.wood < 25000000 || this.getAppComponent().gameState.resources.arena2.iron < 100000000) {
            return;
        }

        AppComponent.instance.gameState.resources.arena2['iron'] -= 100000000;
        AppComponent.instance.gameState.resources.arena2['wood'] -= 25000000;
        AppComponent.instance.unlockArena(5);
    }

    purchaseMultiplierUpdated(): void {
        if (this.getAppComponent().buyMaxChecked) {
            this.getAppComponent().purchaseMultiplier = null;
            return;
        }
        
        try {
            this.getAppComponent().purchaseMultiplier = parseInt(this.getAppComponent().purchaseMultiplier + "");
        }
        catch(err) {
            this.getAppComponent().purchaseMultiplier = 1;
        }

        if (this.getAppComponent().purchaseMultiplier < 1) {
            this.getAppComponent().purchaseMultiplier = 1;
        }
    }
}
