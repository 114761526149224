import { environment } from '../environments/environment';

import { Component, OnInit, isDevMode } from '@angular/core';
import { CardModels, GetCardById } from './CardModels';
import { KongregateComponent } from './kongregate.component';

import { RecalculateEffects } from './RecalculateEffects';

import { arena1DefaultValues} from './arena1-default-values';
import { arena2DefaultValues} from './arena2-default-values';
import { arena3DefaultValues} from './arena3-default-values';
import { arena4DefaultValues} from './arena4-default-values';
import { AmountOfGoldPerSecond } from './AmountOfGoldPerSecond';
import { AmountOfWoodPerSecond } from './AmountOfWoodPerSecond';
import { AmountOfIronPerSecond } from './AmountOfIronPerSecond';
import { AmountOfEtherPerSecond } from './AmountOfEtherPerSecond';
import { AmountOfSkullsPerSecond } from './AmountOfSkullsPerSecond';
import { AmountOfFeathersPerSecond } from './AmountOfFeathersPerSecond';
import { TooltipComponent } from './tooltip.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { OfflineProgressComponent } from './offline-progress.component';
import { SaveLayoutDialogComponent } from './save-layout-dialog.component';
import { NiceNumberPipe } from './NiceNumber.pipe';

import { saveAs } from 'file-saver';
import * as crypto from "crypto-js";

import { ErrorMessageComponent } from './error-message.component';
import { OptionsComponent } from './options.component';
import { UpgradeWindowComponent } from './UpgradeWindow.component';
import { CardData } from './CardData.model';
import { AchievementIsUnlocked, UnlockAchievement } from './Achievements';
import { AchievementShowerComponent } from './AchievementShower.component';
import { SoundEffectManager } from './SoundEffectManager.component';
import { TickBattle, GetMonsterSlotForHeroSlot, GetHeroSlotForMonsterSlot } from './TickBattle';
import { SteamComponent } from './steam.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    public showPremiumShop: boolean = false; // TODO: set this to true when it's ready to go live.
    public showDebugTools: boolean = false;
    private thisSessionStartedAtInSeconds: number = 0;

    objectKeys = Object.keys;
    GetCardById = GetCardById;

    public static instance: AppComponent = null;
    public cardModels: any[] = CardModels;
    public currentTab: string = "arena";
    public keyIsPressed: any = {}; // example: if (keyIsPressed[17]) { ctrl is pressed }
    public hoveredCardSlot: number = null;

    public isSelectingEmptySlot: boolean = false;
    public cardIdToPutInEmptySlot: number = null;
    public selectedLayoutSlotToLoad: number = null;
    public purchaseMultiplier: number = 1;
    public buyMaxChecked: boolean = false;

    unlockedArenas: any[] = [];

    private LatestSaveDataVersion: number = 32; // change this to something higher if/when an update comes out with savedata-breaking changes

    public AmountOfGoldPerSecond = AmountOfGoldPerSecond;
    public AmountOfWoodPerSecond = AmountOfWoodPerSecond;
    public AmountOfIronPerSecond = AmountOfIronPerSecond;
    public AmountOfEtherPerSecond = AmountOfEtherPerSecond;
    public AmountOfSkullsPerSecond = AmountOfSkullsPerSecond;
    public AmountOfFeathersPerSecond = AmountOfFeathersPerSecond;

    public collectionSortOptions = ['Tier (ascending)', 'Tier (descending)', 'Producers first', 'Effects first', 'Rarity (ascending)', 'Rarity (descending)', 'Quantity', 'Name', 'Type', 'Level (descending)', 'Resource (ascending)', 'Resource (descending)'];

    public isOnKongregate: boolean = false;
    public isOnSteam: boolean = false;
    public kongregateMicrotransactionList: any[] = [];
    public steamMicrotransactionList: any[] = [];

    public recentResourcesEarned = {}; // example: recentResourcesEarned[4]['gold'] is an array of up to 10 gold values in arena4

    public lastTickTimeInMilliseconds = 0;

    public closeUpgradeWindow() {
        UpgradeWindowComponent.instance.hide();
    }
    public openUpgradeWindow() {
        UpgradeWindowComponent.instance.show(this.gameState.selectedArenaNumber, this.cardIdToPutInEmptySlot);
    }

    public gameState: any = {
        secondsPlayed: 0,
        gems: 0,
        enableSoundEffects: true,
        enableBattleSoundEffects: true,
        enableMusic: true,
        enableAnimations: true,
        highestUnlockedArena: 1,
        hasPurchasedCardsBefore: false,
        saveDataVersion: this.LatestSaveDataVersion,
        lastSaveTimeInSeconds: 0,
        maxOfflineTime: 14400, // 14400 seconds (4 hours)
        selectedArenaNumber: 1,
        achievementsUnlocked: {},
        savedLayouts: {
            'arena1': [],
            'arena2': [],
            'arena3': [],
            'arena4': [],
            'arena5': []
        },
        cardRanks: {
            'arena1': {},
            'arena2': {},
            'arena3': {},
            'arena4': {},
            'arena5': {},
        },
        selectedSortOption: 'Producers first',
        filters: {
            showTier: {
                'tier1': true,
                'tier2': true,
                'tier3': true,
                'tier4': true,
                'tier5': true
            }
        },
    
        resources: {
            'arena1': {
                gold: 0
            },
            'arena2': {
                iron: 0,
                wood: 0,
            },
            'arena3': {
                ether: 0,
                skulls: 0,
                feathers: 0
            },
            'arena4': {
                gold: 0,
                wood: 0
            }
        },

        lifetimeResourcesEarned: {
            'arena1': {
                gold: 0
            },
            'arena2': {
                iron: 0,
                wood: 0,
            },
            'arena3': {
                ether: 0,
                skulls: 0,
                feathers: 0
            },
            'arena4': {
                gold: 0,
                wood: 0
            }
        },

        lifetimeGemsReceived: {
            '1': 0, // this means arena1.  no need for the "arena" text anymore; removed for a slight optimization.
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },

        arena1: arena1DefaultValues,
        arena2: arena2DefaultValues,
        arena3: arena3DefaultValues,
        arena4: arena4DefaultValues,

        cardCollection: {
            "arena1": {
                "1": 1 // start with one ghoul
            },
            "arena2": {
                "24": 1 // start with one of the starting Haunted Forest cards (Lil Lumberjack)
            },
            "arena3": {
                "69": 1 // start with one of the starting Underworld cards
            },
            "arena4": {
                "123": 1,
                "124": 1
            }
        },

        cardCollectionOrders: {
            "arena1": [1],
            "arena2": [24],
            "arena3": [69],
            "arena4": [123, 124]
        }
    };

    public ARENA1_MAX_SLOTS: number = 10; // up to 10 cards eventually played in arena 1

    constructor(private sanitizer: DomSanitizer, private niceNumber: NiceNumberPipe) {
        this.thisSessionStartedAtInSeconds = Math.floor(new Date().getTime()/1000);
        this.showDebugTools = isDevMode();
        AppComponent.instance = this;
    }
    
    cardExistsInArena(arenaNumber: number, cardId: number): boolean {
        var cardSlots = this.gameState['arena' + arenaNumber].cardSlots;
        for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
            if (cardSlots[slotNumber].cardInSlot != null) {
                if (cardSlots[slotNumber].cardInSlot.id == cardId) {
                    return true;
                }
            }
        }

        return false;
    }
    
    cardCountInArena(arenaNumber: number, cardId: number): number {
        let result: number = 0;
        var cardSlots = this.gameState['arena' + arenaNumber].cardSlots;
        for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
            if (cardSlots[slotNumber].cardInSlot != null) {
                if (cardId == null || cardSlots[slotNumber].cardInSlot.id == cardId) {
                    result++;
                }
            }
        }

        return result;
    }

    indexOfNextCardSlotToUnlock(arenaNumber): number {
        return 1;
    }

    numberOfSlotsUnlocked(arenaNumber: number): number {
        let result = 0;
        var cardSlots = this.gameState['arena' + arenaNumber].cardSlots;
        for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
            if (cardSlots[slotNumber].slotIsUnlocked) {
                result++;
            }
        }
        return result;
    }

    cardCollectionIsEmpty(): boolean {
        for (let cardId of Object.keys(this.gameState.cardCollection['arena' + this.gameState.selectedArenaNumber])) {
            if (this.gameState.cardCollection['arena' + this.gameState.selectedArenaNumber][cardId] > 0) {
                return false;
            }
        }
        return true;
    }

    everyTick(secondsPassed = 1) {
        this.gameState.secondsPlayed++;
        this.lastTickTimeInMilliseconds = new Date().getTime();

        RecalculateEffects(1, this.gameState);

        let goldEarned: number = AmountOfGoldPerSecond(1) * secondsPassed;
        this.gameState.resources.arena1.gold += goldEarned;
        if (goldEarned > 0) {
            this.gameState.lifetimeResourcesEarned.arena1.gold += goldEarned;
        }

        // if Haunted Woods is unlocked
        if (this.gameState.highestUnlockedArena >= 2) {
            RecalculateEffects(2, this.gameState);

            let woodEarned: number = AmountOfWoodPerSecond(2) * secondsPassed;
            let ironEarned: number = AmountOfIronPerSecond(2) * secondsPassed;

            this.gameState.resources.arena2.wood += woodEarned;
            this.gameState.resources.arena2.iron += ironEarned;
            
            if (woodEarned > 0) {
                this.gameState.lifetimeResourcesEarned.arena2.wood += woodEarned;
            }
            if (ironEarned > 0) {
                this.gameState.lifetimeResourcesEarned.arena2.iron += ironEarned;
            }
        }
    
        // if Underworld is unlocked
        if (this.gameState.highestUnlockedArena >= 3) {
            RecalculateEffects(3, this.gameState);

            let etherEarned: number = AmountOfEtherPerSecond(3) * secondsPassed;
            let skullsEarned: number = AmountOfSkullsPerSecond(3) * secondsPassed;
            let feathersEarned: number = AmountOfFeathersPerSecond(3) * secondsPassed;

            this.gameState.resources.arena3.ether += etherEarned;
            this.gameState.resources.arena3.skulls += skullsEarned;
            this.gameState.resources.arena3.feathers += feathersEarned;
            
            if (etherEarned > 0) {
                this.gameState.lifetimeResourcesEarned.arena3.ether += etherEarned;
            }
            if (skullsEarned > 0) {
                this.gameState.lifetimeResourcesEarned.arena3.skulls += skullsEarned;
            }
            if (feathersEarned > 0) {
                this.gameState.lifetimeResourcesEarned.arena3.feathers += feathersEarned;
            }
        }

        // if Plains of War is unlocked
        if (this.gameState.highestUnlockedArena >= 4) {
            RecalculateEffects(4, this.gameState);

            let resourcesEarned: any = TickBattle(4);

            this.gameState.resources['gold'] += resourcesEarned['gold'];
            this.gameState.resources['wood'] += resourcesEarned['wood'];

            if (resourcesEarned['gold']) {
                this.gameState.lifetimeResourcesEarned.arena4.gold += resourcesEarned['gold'];
            }
            if (resourcesEarned['wood']) {
                this.gameState.lifetimeResourcesEarned.arena4.wood += resourcesEarned['wood'];
            }
            this.gameState.resources.arena4.gold += resourcesEarned['gold'];
            this.gameState.resources.arena4.wood += resourcesEarned['wood'];
            this.recordRecentResourcesEarned(4, resourcesEarned);
        }
    
        // every 10 seconds, save to cookie, and to kongregate
        if (this.gameState.secondsPlayed % 10 == 0) {
            this.saveGame();
            if (KongregateComponent.instance) {
                KongregateComponent.instance.submitStat('gold', this.gameState.lifetimeResourcesEarned.arena1.gold);
                KongregateComponent.instance.submitStat('wood', this.gameState.lifetimeResourcesEarned.arena2.wood);
                KongregateComponent.instance.submitStat('iron', this.gameState.lifetimeResourcesEarned.arena2.iron);
                KongregateComponent.instance.submitStat('ether', this.gameState.lifetimeResourcesEarned.arena3.ether);
                KongregateComponent.instance.submitStat('skulls', this.gameState.lifetimeResourcesEarned.arena3.skulls);
                KongregateComponent.instance.submitStat('feathers', this.gameState.lifetimeResourcesEarned.arena3.feathers);
            }
        }

        // check for achievements
        this.CheckForAchievements();
    }

    saveGame() {
        // if this was built for Steam, and we're still waiting for the cloud load attempt, exit without saving.
        if (environment.steam && (!SteamComponent.instance || !SteamComponent.instance.cloudLoadAttemptFinished)) {
            let secondsActivelyPlayingThisSession = Math.floor(new Date().getTime()/1000) - this.thisSessionStartedAtInSeconds;
            if (secondsActivelyPlayingThisSession < 60) { // unless over 60 seconds have passed waiting already
                return;
            }
        }

        this.gameState.lastSaveTimeInSeconds = Math.floor(new Date().getTime()/1000);
		var gameDataStr = JSON.stringify(this.gameState);
		var myStorage = window.localStorage;
        myStorage.setItem('gameData', gameDataStr);
        
        if (this.isOnSteam) {
            SteamComponent.instance.saveToCloud();
        }
	}

	loadGame(): void {
		var myStorage = window.localStorage;
		var gameData = myStorage.getItem('gameData');
		if (gameData != null && gameData.length > 0) {
            this.gameState = JSON.parse(gameData);
            this.migrateDataAndHandleOfflineProgress();
        }
	}

	deleteSaveData(): void {
		window.localStorage.setItem('gameData', "");
		window.location.reload();
	}
    
    private gameTickInterval = null;
    init() {
        this.loadGame();
        
        this.unlockedArenas = this.getUnlockedArenas();
        
        this.gameTickInterval = setInterval(this.everyTick.bind(this), 1000);

        window.onkeyup = function(e) { this.keyIsPressed[e.keyCode] = false; }.bind(this);
        window.onkeydown = function(e) { this.keyIsPressed[e.keyCode] = true; }.bind(this);
        window.addEventListener("focus", function(event) 
        { 
            this.keyIsPressed[17] = false;
        }.bind(this), false);
    }

    public ngOnInit() {
        this.init();

    }

    public addCardsToCollection(cardsReceived: any, arenaNumber: number): void {

        for (var i = 0; i < cardsReceived.length; i++) {
            let cardId = cardsReceived[i].data.id;
            let qty = cardsReceived[i].quantity;
            
            if (this.gameState.cardCollection['arena' + arenaNumber][cardId]) {
                this.gameState.cardCollection['arena' + arenaNumber][cardId] += qty;
            }
            else {
                this.gameState.cardCollection['arena' + arenaNumber][cardId] = qty;
            }
            
        }
        this.sortCardCollection(arenaNumber);
    }

    public removeCardFromCollection(cardId: number, arenaNumber: number): void {
        if (this.gameState.cardCollection['arena' + arenaNumber][cardId]) {
            this.gameState.cardCollection['arena' + arenaNumber][cardId]--;
            if (this.gameState.cardCollection['arena' + arenaNumber][cardId] <= 0) {
                delete this.gameState.cardCollection['arena' + arenaNumber][cardId];
            }
        }
        this.sortCardCollection(arenaNumber);
    }

    public startChoosingEmptySlotForCard(cardId: number): void {
        this.isSelectingEmptySlot = true;
        this.cardIdToPutInEmptySlot = cardId;
    }

    public stopChoosingEmptySlotForCard(): void {
        this.isSelectingEmptySlot = false;
        this.cardIdToPutInEmptySlot = null;
    }

    public tryToPlaceCardInEmptySlot(arenaNumber: number, slotNumber: number, cardData: any, playPlacementSound: boolean): void {
        
        // ensure the player has at least one of those cards in their collection
        let qtyOwned: number = this.gameState.cardCollection['arena' + this.gameState.selectedArenaNumber][cardData.id];
        if (qtyOwned < 1) {
            return;
        }

        // ensure this type of card can be placed in this type of grid slot
        let slotType: string = this.gameState['arena' + arenaNumber].cardSlots[slotNumber].slotType || 'normal';
        let typeOfCard: string = cardData.cardType || 'normal';
        if (slotType !== typeOfCard) {
            SoundEffectManager.instance.playSound('cantdothat');
            return;
        }

        this.removeCardFromCollection(cardData.id, this.gameState.selectedArenaNumber);

        // actually place the card
        this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot = GetCardById(cardData.id);
        // if the card is a hero or monster, set the currentHP
        if (cardData.cardType == 'hero' || cardData.cardType == 'monster') {
            this.gameState['arena' + arenaNumber].cardSlots[slotNumber].currentHP = this.rankAdjustedModifier(cardData.id, arenaNumber, 0); // modifier0 == HP on those cards.

            // reset the resource estimates if it's a hero card
            if (cardData.cardType == 'hero') {
                this.gameState['arena' + arenaNumber].cardSlots[slotNumber].roundsSoFar = 0;
                this.gameState['arena' + arenaNumber].cardSlots[slotNumber].estimatedLootPerSecond = { gold: 0, wood: 0 };
            }
        }

        if (playPlacementSound) {
            SoundEffectManager.instance.playSound('placecard');
        }

        // if that was the last card of this kind in the collection, OR if ctrl isn't held, then unselect the card.
        qtyOwned = this.gameState.cardCollection['arena' + this.gameState.selectedArenaNumber][cardData.id];
        
        if (qtyOwned == undefined || qtyOwned <= 0 || !this.keyIsPressed[17]) {
            if (this.isSelectingEmptySlot && cardData.id == this.cardIdToPutInEmptySlot) {
                this.isSelectingEmptySlot = false;
                this.cardIdToPutInEmptySlot = null;
            }
        }

        RecalculateEffects(arenaNumber, this.gameState);
    }

    public tryToBuyEmptySlot(arenaNumber: number, slotNumber: number): void {
        var cardSlot = this.gameState['arena' + arenaNumber].cardSlots[slotNumber];
        if (this.gameState.resources['arena' + arenaNumber][cardSlot.unlockCurrency] >= cardSlot.unlockPrice) {
            this.gameState.resources['arena' + arenaNumber][cardSlot.unlockCurrency] -= cardSlot.unlockPrice;
            cardSlot.slotIsUnlocked = true;
        }
    }

    private unlockSlot(arenaNumber: number, slotNumber: number): void {
        var cardSlots = this.gameState['arena' + arenaNumber].cardSlots;
        for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
            if (!cardSlots[slotNumber].slotIsUnlocked) {
                cardSlots[slotNumber].slotIsUnlocked = true;
                return;
            }
        }
    }

    public removeCardFromArena(arenaNumber: number, slotNumber: number, cardId: number): void {
        if (arenaNumber == null || slotNumber == null || cardId == null) {
            return;
        }

        if (this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot == null) {
            return;
        }

        // if the card being removed is a hero card, reset the HP of the monster it was battling.
        if (this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot.cardType == 'hero') {
            let monsterSlot = GetMonsterSlotForHeroSlot(arenaNumber, this.gameState['arena' + arenaNumber].cardSlots[slotNumber]);
            if (monsterSlot.cardInSlot != null)
                monsterSlot.currentHP = this.rankAdjustedModifier(monsterSlot.cardInSlot.id, arenaNumber, 0);
        }
        // if the card being removed is a monster card, reset the HP of the hero it was battling.
        if (this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot.cardType == 'monster') {
            let heroSlot = GetHeroSlotForMonsterSlot(arenaNumber, this.gameState['arena' + arenaNumber].cardSlots[slotNumber]);
            if (heroSlot.cardInSlot != null)
                heroSlot.currentHP = this.rankAdjustedModifier(heroSlot.cardInSlot.id, arenaNumber, 0);
        }

        this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot = null;
        this.addCardsToCollection([ { data: GetCardById(cardId), quantity: 1 } ], arenaNumber);
        
        RecalculateEffects(arenaNumber, this.gameState);
    }

    public getSlotNumbersOfCardsInRow(arenaNumber: number, yValue: number, exceptSlotNumber: number): number[] {
        let result = [];

        const slots = this.gameState['arena' + arenaNumber].cardSlots;

        for (var i = 0; i < slots.length; i++) {
            let cardInSlot = slots[i].cardInSlot;
            if (cardInSlot != null && slots[i].y == yValue && i != exceptSlotNumber) {
                result.push(i);
            }
        }

        return result;
    }

    public getSlotNumbersOfCardsInColumn(arenaNumber: number, xValue: number, exceptSlotNumber: number): number[] {
        let result = [];

        const slots = this.gameState['arena' + arenaNumber].cardSlots;

        for (var i = 0; i < slots.length; i++) {
            let cardInSlot = slots[i].cardInSlot;
            if (cardInSlot != null && slots[i].x == xValue && i != exceptSlotNumber) {
                result.push(i);
            }
        }

        return result;
    }

    public getSlotNumbersOfCardsInPlay(arenaNumber: number, exceptSlotNumber: number): number[] {
        let result = [];

        const slots = this.gameState['arena' + arenaNumber].cardSlots;

        for (var i = 0; i < slots.length; i++) {
            let cardInSlot = slots[i].cardInSlot;
            if (cardInSlot != null && i != exceptSlotNumber) {
                result.push(i);
            }
        }

        return result;
    }

    public getCardSlotNumberAtXYOffset(arenaNumber: number, slotNumber: number, xOffset: number, yOffset: number): any {

        const slots = this.gameState['arena' + arenaNumber].cardSlots;

        var slotBeingChecked = this.gameState['arena' + arenaNumber].cardSlots[slotNumber];
        for (var i = 0; i < slots.length; i++) {
            let cardInSlot = slots[i].cardInSlot;
            if (cardInSlot != null && slots[i].x == slotBeingChecked.x + xOffset && slots[i].y == slotBeingChecked.y + yOffset) {
                return i;
            }
        }

        return null;
    }

    public getCardSlotNumberAtXYLocation(arenaNumber: number, x: number, y: number): any {

        const slots = this.gameState['arena' + arenaNumber].cardSlots;

        for (var i = 0; i < slots.length; i++) {
            if (slots[i].x == x && slots[i].y == y) {
                return i;
            }
        }

        return null;
    }

    public getTotalMultiplierForSlot(arenaNumber: number, slotNumber: number, currency: string, skipPositiveBenefits: boolean = false): number {
        var totalMultiplier: number = 1;
        var allMultipliers: number[] = this.gameState['arena' + arenaNumber].cardSlots[slotNumber].multipliers[currency];
        for (let i = 0; i < allMultipliers.length; i++) {
            if (allMultipliers[i]['amount'] == 0) {
                return 0;
            }
            
            if (!skipPositiveBenefits) {
                totalMultiplier += allMultipliers[i]['amount'];
            }
        }
        return totalMultiplier;
    }

    public pushMultiplier(arenaNumber: number, actingSlotNumber: number, slotNumber: number, currency: string, multiplierValue: number): void {
        this.gameState['arena' + arenaNumber].cardSlots[slotNumber].multipliers[currency].push({ amount: multiplierValue, fromSlot: actingSlotNumber });
    }

    public pushStatBonus(arenaNumber: number, actingSlotNumber: number, slotNumber: number, statName: string, bonusValue: number): void {
        let bonusDataForTheStat: any = this.gameState['arena' + arenaNumber].cardSlots[slotNumber].statBonuses[statName] || {};
        bonusDataForTheStat = { amount: bonusValue, fromSlot: actingSlotNumber };
        this.gameState['arena' + arenaNumber].cardSlots[slotNumber].statBonuses[statName] = bonusDataForTheStat;
    }
    
    public getNumberOfCardsInPlay(arenaNumber: number): number {
        var result = 0;
        const slots = this.gameState['arena' + arenaNumber].cardSlots;
        for (var i = 0; i < slots.length; i++) {
            if (slots[i].cardInSlot != null) {
                result++;
            }
        }
        return result;
    }

    private migrateSaveData(newVersion: number): void {
        switch (newVersion) {
            case 2:
                // from version 1 to 2.
                this.gameState.selectedArenaNumber = 1;
                this.gameState.arena2 = arena2DefaultValues;
                let oldCollection = this.cloneJSObject(this.gameState.cardCollection);
                this.gameState.cardCollection = {};
                this.gameState.cardCollection['arena1'] = oldCollection;
                this.gameState.cardCollection['arena2'] = { "24": 1 };
                break;
            case 3:
                // from version 2 to 3.
                this.gameState.lastSaveTimeInSeconds = 0;
                break;
            case 4:
                // from version 3 to 4
                this.gameState.savedLayouts = {};
                this.gameState.cardRanks = {};
                break;
            case 5:
                let newResourcesObject = { 'arena1': { gold: this.gameState.resources.gold }, 'arena2': { wood: 0, iron: 0 }, 'arena3': { blood: 0, ether: 0} };
                this.gameState.resources = newResourcesObject;
                break;
            case 6:
                this.gameState.selectedSortOption = 'Producers first';
                break;
            case 7:
                this.gameState.cardCollectionOrders =  { "arena1": [], "arena2": [] };
                break;
            case 8:
                this.gameState.filters = {
                    showTier: {
                        'tier1': true,
                        'tier2': true,
                        'tier3': true,
                        'tier4': true,
                        'tier5': true
                    }
                };
                this.sortCardCollection(1);
                this.sortCardCollection(2);
                break;
            case 9:
                this.gameState.savedLayouts = {
                    'arena1': [],
                    'arena2': [],
                    'arena3': [],
                    'arena4': [],
                    'arena5': []
                };
                break;
            case 10:
                this.gameState.cardRanks = {
                    'arena1': {},
                    'arena2': {},
                    'arena3': {},
                    'arena4': {},
                    'arena5': {},
                };
                break;
            case 11:
                if (this.gameState.resources.arena2.wood < -10) { // fix for where sometimes wood would go negative with investor imp/dog
                    this.gameState.resources.arena2.wood = 0;
                }
                break;
            case 12:
                this.gameState.cardCollection.arena3 = {
                    "69": 1
                };
                break;
            case 13:
                this.gameState.arena3 = arena3DefaultValues;
                break;
            case 14:
                this.gameState.resources.arena3 = {
                    ether: 0,
                    skulls: 0,
                    feathers: 0
                };
                break;
            case 15:
                this.gameState.arena3 = arena3DefaultValues;
                break;
            case 16:
                if (typeof(this.gameState.cardCollectionOrders.arena3) == 'undefined') {
                    this.gameState.cardCollectionOrders.arena3 = [69];
                }
                break;
            case 17: {
                this.gameState.lifetimeResourcesEarned = {
                    'arena1': {
                        gold: this.gameState.resources.arena1.gold
                    },
                    'arena2': {
                        iron: this.gameState.resources.arena2.iron,
                        wood: this.gameState.resources.arena2.wood
                    },
                    'arena3': {
                        ether: this.gameState.resources.arena3.ether,
                        skulls: this.gameState.resources.arena3.skulls,
                        feathers: this.gameState.resources.arena3.feathers
                    }
                };
                break;
            }
            case 18: {
                /*
                // this format is no longer used, so it's commented out.  case 19 adds the new format.
                this.gameState.lifetimeResourcesRedeemed = {
                    'arena1': {
                        gold: 0
                    },
                    'arena2': {
                        iron: 0,
                        wood: 0,
                    },
                    'arena3': {
                        ether: 0,
                        skulls: 0,
                        feathers: 0
                    }
                };*/
                break;
            }
            case 19: {
                this.gameState.lifetimeGemsReceived = {
                    '1': 0, // this means arena1.  no need for the "arena" text anymore; removed for a slight optimization.
                    '2': 0,
                    '3': 0
                };
                break;
            }
            case 20: {
                this.gameState.gems = 0;
                break;
            }
            case 21: {
                this.gameState.maxOfflineTime = 3600;
                break;
            }
            case 22: {
                this.gameState.maxOfflineTime *= 4;
                break;
            }
            case 23: {
                if (this.gameState.maxOfflineTime > 86400) {
                    let gemsToGiveBack = (this.gameState.maxOfflineTime - 86400) / 14400;
                    this.gameState.gems += gemsToGiveBack;
                    this.gameState.maxOfflineTime = 86400;
                }
                break;
            }
            case 24: {
                this.gameState.achievementsUnlocked = {};
                break;
            }
            case 25: {
                // does nothing.
                break;
            }
            case 26: {
                this.gameState.enableSoundEffects = true;
                this.gameState.enableMusic = false;
                break;
            }
            case 27: {
                this.gameState.resources.arena4 = {
                    gold: 0,
                    wood: 0
                };
                this.gameState.lifetimeResourcesEarned.arena4 = {
                    gold: 0,
                    wood: 0
                };
                this.gameState.lifetimeGemsReceived['4'] = 0;
                this.gameState.arena4 = arena4DefaultValues;
                break;
            }
            case 28: {
                this.gameState.cardCollection.arena4 = {
                    "123": 1,
                    "124": 1
                };
                this.gameState.cardCollectionOrders.arena4 = [123, 124];
                break;
            }
            case 29: {
                this.gameState.enableAnimations = true;
                break;
            }
            case 30: {
                this.gameState.lifetimeGemsReceived[4] = 0;
                this.gameState.lifetimeGemsReceived[5] = 0;
                this.gameState.lifetimeGemsReceived[6] = 0;
                this.gameState.lifetimeGemsReceived[7] = 0;
                this.gameState.lifetimeGemsReceived[8] = 0;
                this.gameState.lifetimeGemsReceived[9] = 0;
                this.gameState.lifetimeGemsReceived[10] = 0;
                break;
            }
            case 31: {
                this.gameState.savedLayouts.arena4 = [];
                this.gameState.savedLayouts.arena5 = [];
                this.gameState.arena4 = arena4DefaultValues;
                this.gameState.enableBattleSoundEffects = true;
                break;
            }
            case 32: {
                this.gameState.arena4 = arena4DefaultValues;
                break;
            }
        }

        this.gameState.saveDataVersion = newVersion;
    }

    private cloneJSObject(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }

    private deepCloneJSObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    public unlockArena(newArenaNumber: number): void {
        newArenaNumber = parseInt("" + newArenaNumber);

        if (this.gameState.highestUnlockedArena > newArenaNumber) {
            console.error("Trying to unlockArena() with an arena number (" + newArenaNumber + ") lower than what's already the highest unlocked (" + this.gameState.highestUnlockedArena + ")");
            return;
        }

        // Haunted Woods achievement
        if (newArenaNumber == 2) {
            if (!AchievementIsUnlocked(9)) {
                UnlockAchievement(9);
            }
        }
        // Underworld achievement
        if (newArenaNumber == 3) {
            if (!AchievementIsUnlocked(10)) {
                UnlockAchievement(10);
            }
        }

        // Plains of War achievement
        if (newArenaNumber == 4) {
            if (!AchievementIsUnlocked(12)) {
                UnlockAchievement(12);
            }
        }

        // initalize arena-specific things
        switch (newArenaNumber) {
            case 2:
                this.gameState.resources.iron = 0;
                this.gameState.resources.wood = 0;
                break;
        }
        
        this.isSelectingEmptySlot = false;
        this.cardIdToPutInEmptySlot = null;
        
        // move to that new arena
        this.gameState.highestUnlockedArena = newArenaNumber;
        this.currentTab = 'arena';
        this.gameState.selectedArenaNumber = newArenaNumber;

        for (var tier = 1; tier <= 5; tier++) {
            this.gameState.filters.showTier['tier'+ tier] = true;
        }

        this.unlockedArenas = this.getUnlockedArenas();
        
        // tell Kongregate how many times we've ascended
        KongregateComponent.instance.submitStat('realmsunlocked', newArenaNumber - 1);
    }

    public getUnlockedArenas(): any[] {
        let result = [{
            'num': 1,
            'name': 'Intro Realm'
        }];

        if (this.gameState.highestUnlockedArena >= 2) {
            result.push({
                'num': 2,
                'name': 'Haunted Woods'
            });
        }

        if (this.gameState.highestUnlockedArena >= 3) {
            result.push({
                'num': 3,
                'name': 'Underworld'
            });
        }

        if (this.gameState.highestUnlockedArena >= 4) {
            result.push({
                'num': 4,
                'name': 'Plains of War'
            });
        }

        return result;
    }

    public arenaHasDarkBackground(): boolean {
        return this.gameState.selectedArenaNumber == 2 || this.gameState.selectedArenaNumber == 3;
    }

    public showTooltipForRankUp() {
        if (!this.isSelectingEmptySlot || this.cardIdToPutInEmptySlot == null) {
            return;
        }

        let qtyOfCardsToConsume: number = this.getCardUpgradeCost(this.gameState.selectedArenaNumber, this.cardIdToPutInEmptySlot);
        let qtyOwned: number = this.gameState.cardCollection['arena' + this.gameState.selectedArenaNumber][this.cardIdToPutInEmptySlot];

        let tooltipMsg: string = "Combine " + this.niceNumber.transform(qtyOfCardsToConsume) + " cards to increase power";
        
        TooltipComponent.instance.showTooltip(tooltipMsg);
    }

    public hideTooltip() {
        TooltipComponent.instance.hideTooltip();
    }

    public tryToRankUpCard(arenaNumber: number, cardId: number, showErrorMessages: boolean = true, tryAgainIfSuccessful: boolean = false): void {
        let currentRank: number = this.getCurrentCardRank(arenaNumber, cardId);
        let qtyOfCardsToConsume: number = this.getCardUpgradeCost(arenaNumber, cardId);
        let qtyOwned: number = this.gameState.cardCollection['arena' + arenaNumber][cardId];

        if (qtyOwned < qtyOfCardsToConsume) {
            if (showErrorMessages) {
                ErrorMessageComponent.instance.show("You don't have enough of that card to combine.  You need " + qtyOfCardsToConsume + ", but you only have " + qtyOwned);
            }
        }
        else {
            // subtract the cards from quantity
            let newQuantity: number = qtyOwned - qtyOfCardsToConsume + 1;
            this.gameState.cardCollection['arena' + arenaNumber][cardId] = newQuantity;
            if (newQuantity <= 0) {
                delete this.gameState.cardCollection['arena' + arenaNumber][cardId];
                this.sortCardCollection(arenaNumber);
                this.isSelectingEmptySlot = false;
                this.cardIdToPutInEmptySlot = null;
            }

            // set the new rank
            this.gameState.cardRanks['arena' + arenaNumber][cardId] = currentRank + 1;

            if (tryAgainIfSuccessful) {
                this.tryToRankUpCard(arenaNumber, cardId, showErrorMessages, tryAgainIfSuccessful);
            }
        }

        RecalculateEffects(arenaNumber, this.gameState);
    }

    public rankDownCard(arenaNumber: number, cardId: number): void {
        let currentRank = this.gameState.cardRanks['arena' + arenaNumber][cardId];
        if (currentRank < 2) {
            return;
        }

        // reduce level
        this.gameState.cardRanks['arena' + arenaNumber][cardId] = currentRank - 1;
        // refund cards
        let qtyOwned: number = this.gameState.cardCollection['arena' + arenaNumber][cardId];
        let qtyOfCardsToGiveBack: number = this.getCardUpgradeCost(arenaNumber, cardId);
        let newQuantity: number = qtyOwned + qtyOfCardsToGiveBack - 1;
        this.gameState.cardCollection['arena' + arenaNumber][cardId] = newQuantity;

        RecalculateEffects(arenaNumber, this.gameState);
    }

    public tryToRankUpAllCards(arenaNumber: number): void {
        let cardIds = Object.keys(this.gameState.cardCollection['arena' + arenaNumber]);
        for (var i = 0; i < cardIds.length; i++) {
            this.tryToRankUpCard(arenaNumber, parseInt("" + cardIds[i]), false, true);
        }
    }

    public tryToRankUpCardWithGems(arenaNumber: number, cardId: number): void {

        let currentRank: number = this.getCurrentCardRank(arenaNumber, cardId);
        
        let gemCost = 1;

        if (this.gameState.gems < gemCost) {
            ErrorMessageComponent.instance.show("You don't have enough gems.  You need " + gemCost + ", but you only have " + this.gameState.gems);
        }
        else {
            // remove gems
            AppComponent.instance.gameState.gems--;

            // set the new rank
            this.gameState.cardRanks['arena' + arenaNumber][cardId] = currentRank + 1;
        }
    }

    public forceToRankUpCard(arenaNumber: number, cardId: number): void {
        let currentRank: number = this.getCurrentCardRank(arenaNumber, cardId);
        this.gameState.cardRanks['arena' + arenaNumber][cardId] = currentRank + 1;
    }

    public shouldShowCardCollectionControls(): boolean {
        return this.currentTab == 'arena' && (this.isSelectingEmptySlot && this.cardIdToPutInEmptySlot != null);
    }

    public getUnlockSlotText(cardSlot: any): string {
        return 'Unlock slot: <br><br><div style="display: flex;"><img src="assets/images/resources/' + cardSlot.unlockCurrency + '.png" alt="' + cardSlot.unlockCurrency + '" title="' + cardSlot.unlockCurrency + '" class="resource-icon">&nbsp;&nbsp;' + this.niceNumber.transform(cardSlot.unlockPrice) +"</div>";
    }

    public GetCardIdAtSlot(arenaNumber: number, slotNumber: number): number {
        if (slotNumber == null) {
            return null;
        }
        if (this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot != null) {
            return this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot.id;
        }
        return null;
    }

    public cardIsImmuneFromConsumingResource(arenaNumber: number, slotNumber: number, resource: string): boolean {
        switch (resource) {
            case 'wood': {
                // return true only if an Iron Mine card is adjacent to the card
                for (var xOffset = -1; xOffset <= 1; xOffset++) {
                    for (var yOffset = -1; yOffset <= 1; yOffset++) {
                        let adjacentCard = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, xOffset, yOffset);
                        if (adjacentCard != null) {
                            if (this.GetCardIdAtSlot(arenaNumber, adjacentCard) == 36) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            }
            case 'skulls':
                return false;
            default:
                console.error("Unknown resource passed to cardIsImmuneFromConsumingResource()" + resource);
                return false;
        }

    }

    private getResourceDifference(resourcesBefore: any, resourcesAfter: any): any {

        let result: any = {};

        for (var arenaNumber = 1; arenaNumber <= this.gameState.highestUnlockedArena; arenaNumber++) {
            result['arena' + arenaNumber] = {};
            for (let currency of Object.keys(resourcesBefore['arena' + arenaNumber])) {
                result['arena' + arenaNumber][currency] = resourcesAfter['arena' + arenaNumber][currency] - resourcesBefore['arena' + arenaNumber][currency];
            }
        }

        return result;
    }

    private sortCardCollection(arenaNumber: number): void {

        this.gameState.cardCollectionOrders['arena' + arenaNumber] = Object.keys(this.gameState.cardCollection['arena' + arenaNumber]);

        // filter out cards from various tiers if necessary
        for (var tier = 1; tier <= 5; tier++) {
            if (!this.gameState.filters.showTier['tier' + tier]) {
                for (var i = 0; i < this.gameState.cardCollectionOrders['arena' + arenaNumber].length; i++) {
                    if (this.GetCardById(this.gameState.cardCollectionOrders['arena' + arenaNumber][i]).tier == tier) {
                        this.gameState.cardCollectionOrders['arena' + arenaNumber].splice(i, 1);
                        i--;
                    }
                }
            }
        }

        switch (this.gameState.selectedSortOption) {
            case 'Producers first':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        let cardA = this.GetCardById(a);
                        let cardB = this.GetCardById(b);
                        return (cardA.isProducer == cardB.isProducer) ? 0 : cardA.isProducer ? -1 : 1;
                    }.bind(this));
                    
                    break;
            case 'Effects first':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        let cardA = this.GetCardById(a);
                        let cardB = this.GetCardById(b);
                        return (cardA.isEffect == cardB.isEffect) ? 0 : cardA.isEffect ? -1 : 1;
                    }.bind(this));
                    break;
            case 'Rarity (ascending)':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return (this.GetCardById(a).rarity - this.GetCardById(b).rarity);
                    }.bind(this));
                    break;
            case 'Rarity (descending)':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return (this.GetCardById(b).rarity - this.GetCardById(a).rarity);
                    }.bind(this));
                    break;
            case 'Resource (ascending)':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return (this.GetCardById(a).relevantResource.localeCompare(this.GetCardById(b).relevantResource));
                    }.bind(this));
                    break;
            case 'Resource (descending)':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return (this.GetCardById(b).relevantResource.localeCompare(this.GetCardById(a).relevantResource));
                    }.bind(this));
                    break;
            case 'Tier (descending)':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return (this.GetCardById(b).tier - this.GetCardById(a).tier);
                    }.bind(this));
                    break;
            case 'Tier (ascending)':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return (this.GetCardById(a).tier - this.GetCardById(b).tier);
                    }.bind(this));
                    break;
            case 'Level (descending)':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return this.getCurrentCardRank(arenaNumber, b) - this.getCurrentCardRank(arenaNumber, a);
                    }.bind(this));
                    break;
            case 'Quantity':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return this.gameState.cardCollection['arena' + arenaNumber][b] - this.gameState.cardCollection['arena' + arenaNumber][a];
                    }.bind(this));
                    break;
            case 'Name':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        return (this.GetCardById(a).name.localeCompare(this.GetCardById(b).name));
                    }.bind(this));
                    break;
            case 'Type':
                    this.gameState.cardCollectionOrders['arena' + arenaNumber].sort(function(a, b) {
                        if (!this.GetCardById(a).cardType || !this.GetCardById(b).cardType) {
                            return 0;
                        }
                        return (this.GetCardById(a).cardType.localeCompare(this.GetCardById(b).cardType));
                    }.bind(this));
                    break;
            default:
                console.error("Unknown sort order requested in sortCardCollection(): " + this.gameState.selectedSortOption);
        }
    }

    public openSaveLayoutDialog() {
        SaveLayoutDialogComponent.instance.show(this.gameState.savedLayouts['arena' + this.gameState.selectedArenaNumber], this.gameState.selectedArenaNumber);
    }

    public closeSaveLayoutDialog() {
        SaveLayoutDialogComponent.instance.hide();
    }

    public saveLayout(arenaNumber: number, layoutSlotNumber: number, name: string): void {
        let idsOfCardsInGrid: number[] = [];
        let cardSlots = this.gameState['arena' + arenaNumber].cardSlots;
        for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
            if (cardSlots[slotNumber].cardInSlot != null) {
                idsOfCardsInGrid.push(cardSlots[slotNumber].cardInSlot.id);
            }
            else {
                idsOfCardsInGrid.push(null);
            }
        }

        let newLayout: any = {
            name: name,
            cards: idsOfCardsInGrid
        };

        if (layoutSlotNumber == null) {
            this.gameState.savedLayouts['arena' + arenaNumber].push(newLayout);    
        }
        else {
            this.gameState.savedLayouts['arena' + arenaNumber][layoutSlotNumber] = newLayout;
        }
    }

    public loadLayout(): void {
        if (this.selectedLayoutSlotToLoad == null || this.selectedLayoutSlotToLoad == undefined || this.selectedLayoutSlotToLoad < 0) {
            return;
        }

        let arenaNumber = this.gameState.selectedArenaNumber;

        this.removeAllCardsFromGrid();

        var cardsToPlace: number[] = this.gameState.savedLayouts['arena' + arenaNumber][this.selectedLayoutSlotToLoad].cards;
        
        if (!cardsToPlace) {
            return;
        }

        for (var slotNumber = 0; slotNumber < cardsToPlace.length; slotNumber++) {
            var cardSlots = this.gameState['arena' + arenaNumber].cardSlots;
            if (cardSlots[slotNumber].slotIsUnlocked) {
                let cardId: number = cardsToPlace[slotNumber];
                if (cardId != null && this.cardExistsInCollection(arenaNumber, cardId)) {
                    this.removeCardFromCollection(cardId, this.gameState.selectedArenaNumber);
                    this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot = GetCardById(cardId);
                }
            }
        }

    }

    public deleteSavedLayout(arenaNumber: number, selectedLayoutIndex: number) {
        if (selectedLayoutIndex == null) {
            return;
        }

        if (selectedLayoutIndex < 0 || selectedLayoutIndex > this.gameState.savedLayouts['arena' + arenaNumber].length) {
            return;
        }

        this.gameState.savedLayouts['arena' + arenaNumber].splice(selectedLayoutIndex, 1);
    }

    public removeAllCardsFromGrid(): void {
        let arenaNumber = this.gameState.selectedArenaNumber;
        let cardSlots = this.gameState['arena' + arenaNumber].cardSlots;
        for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
            if (cardSlots[slotNumber].cardInSlot != null) {
                this.removeCardFromArena(arenaNumber, slotNumber, cardSlots[slotNumber].cardInSlot.id);
            }
        }
    }

    public cardExistsInCollection(arenaNumber: number, cardId: number): boolean {
        if (!this.gameState.cardCollection['arena' + arenaNumber][cardId]) {
            return false;
        }
        return this.gameState.cardCollection['arena' + arenaNumber][cardId] >= 1;
    }

    public getCurrentCardRank(arenaNumber: number, cardId: number): number {
        if (!this.gameState.cardRanks['arena' + arenaNumber][cardId]) {
            return 1;
        }
        else {
            return this.gameState.cardRanks['arena' + arenaNumber][cardId];
        }
    }

    public getCardUpgradeCost(arenaNumber: number, cardId: number): number {
        return Math.pow(10, this.getCurrentCardRank(arenaNumber, cardId));
    }
    
    public rankAdjustedModifier(cardId: number, arenaNumber: number, modifierIndex: number): number {
        let cardData: any = AppComponent.instance.GetCardById(cardId);
        let rank: number = AppComponent.instance.getCurrentCardRank(arenaNumber, cardId);
        
        let rankMultiplier = rank;
        if (rank > 1) {
            rankMultiplier = 1 + (rank - 1)*0.5;
        }
        return cardData.modifiers[modifierIndex] * rankMultiplier;
    }

    public debugCommand(command: string): void {
        if (!this.showDebugTools) {
            return;
        }

        switch (command) {
            case 'forward1minute': {
                for (var i = 0; i < 60; i++) {
                    this.everyTick(1);
                }
                break;
            }
            case 'forward2minutes': {
                for (var i = 0; i < 120; i++) {
                    this.everyTick(1);
                }
                break;
            }
            case 'forward5minutes': {
                for (var i = 0; i < 5*60; i++) {
                    this.everyTick(1);
                }
                break;
            }
            case 'forward10minutes': {
                for (var i = 0; i < 10*60; i++) {
                    this.everyTick(1);
                }
                break;
            }
            case 'forward30minutes': {
                for (var i = 0; i < 30*60; i++) {
                    this.everyTick(1);
                }
                break;
            }
            case 'forward60minutes': {
                for (var i = 0; i < 60*60; i++) {
                    this.everyTick(1);
                }
                break;
            }
            case 'forward5days': {
                this.everyTick(432000);
                break;
            }
            case 'resetgame':
                this.resetGame();
                break;
            case 'unlocknextrealm':
                this.unlockArena(parseInt("" + this.gameState.selectedArenaNumber) + 1);
                break;

        }
    }

    public exportSaveData(): void {
        let kongUsername: string = "Steam";
        if (!environment.steam) {
            let kongUsername: string = KongregateComponent.instance.getUsername();
            if (kongUsername == null || kongUsername == "Guest") {
                ErrorMessageComponent.instance.show("Error exporting data.  Please ensure you're logged into Kongregate and connected to the internet.  If the problem persists, please try again in a minute.");
                return;
            }
        }
        let encryptedData: string = this.encrypt(JSON.stringify(this.gameState), kongUsername);
        let unencryptedData: string = this.decrypt(encryptedData, kongUsername);
        if (JSON.stringify(this.gameState) != unencryptedData) {
            ErrorMessageComponent.instance.show("Exporter encountered an unexpected error");
            return;
        }
        
        const blob = new Blob([encryptedData], { type: 'text/plain' });
        saveAs(blob, "creaturecardidlesave.txt");
    }

    public importSaveData(encryptedData: string, kongUsername: string): void {
        let unencryptedData: string = this.decrypt(encryptedData, kongUsername);
        try {
            let parsedData = JSON.parse(unencryptedData);
            this.gameState = parsedData;
            this.migrateDataAndHandleOfflineProgress();
            this.unlockedArenas = this.getUnlockedArenas();
        }
        catch(e) {
            console.error(e);
            ErrorMessageComponent.instance.show("The file seems to be corrupt.  Please try again.");
        }
    }

    public showOptionsPanel(): void {
        OptionsComponent.instance.show();
    }

    public encrypt(str: string, secret: string): string {
        var encodedString = crypto.AES.encrypt(str, secret).toString();
        return encodedString;
    }

    public decrypt(encodedString: string, secret: string): string {
        var decodedString = crypto.AES.decrypt(encodedString, secret).toString(crypto.enc.Utf8);
        return decodedString;
    }

    public migrateDataAndHandleOfflineProgress() {
        // migrate save data if necessary.
        while (this.gameState.saveDataVersion < this.LatestSaveDataVersion) {
            console.log("Migrating save data from version " + this.gameState.saveDataVersion);
            this.migrateSaveData(this.gameState.saveDataVersion + 1);
        }

        // handle offline progression
        if (this.gameState.lastSaveTimeInSeconds > 0) {
            let hitMaximumOfflineTime = false;

            let resourcesBefore: any = JSON.parse(JSON.stringify(this.gameState.resources));

            var secondsPassed: number = (Math.floor(new Date().getTime()/1000)) - this.gameState.lastSaveTimeInSeconds;
            if (secondsPassed >= this.gameState.maxOfflineTime) { // max offline progression at a time
                secondsPassed = this.gameState.maxOfflineTime;
                hitMaximumOfflineTime = true;
            }
            if (secondsPassed > 1) {
                for (var i = 0; i < secondsPassed; i++) {
                    this.everyTick(1);
                }
            }
            
            if (secondsPassed > 30) {
                let earnedResources: any = this.getResourceDifference(resourcesBefore, this.gameState.resources);

                setTimeout(() => {
                    OfflineProgressComponent.instance.show(secondsPassed, hitMaximumOfflineTime, earnedResources);
                }, 500);
            }
        }

        this.unlockedArenas = this.getUnlockedArenas();
    }

    public resetRealm(arenaNumber: number): void {
        arenaNumber = parseInt("" + arenaNumber);
        switch (arenaNumber) {
            case 1: { // Intro Realm
                this.gameState.arena1 = this.deepCloneJSObject(arena1DefaultValues);
                console.log(arena1DefaultValues);
                this.gameState.resources.arena1 = {
                    gold: 0
                };
                this.gameState.cardCollection.arena1 = {
                    "1": 1
                };
                this.gameState.cardCollectionOrders.arena1 = [
                    1
                ];
            }
            break;
            case 2: { // Haunted Woods
                this.gameState.arena2 = this.deepCloneJSObject(arena2DefaultValues);
                this.gameState.resources.arena2 = {
                    wood: 0,
                    iron: 0
                };
                this.gameState.cardCollection.arena2 = {
                    "24": 1
                };
                this.gameState.cardCollectionOrders.arena2 = [
                    24
                ];
            }
            break;
            case 3: { // Underworld
                this.gameState.arena3 = this.deepCloneJSObject(arena3DefaultValues);
                this.gameState.resources.arena3 = {
                    ether: 0,
                    skulls: 0,
                    feathers: 0
                };
                this.gameState.cardCollection.arena3 = {
                    "69": 1
                };
                this.gameState.cardCollectionOrders.arena3 = [
                    69
                ];
            }
            break;
            case 4: { // Plains of War
                this.gameState.arena4 = this.deepCloneJSObject(arena4DefaultValues);
                this.gameState.resources.arena4 = {
                    gold: 0,
                    wood: 0
                };
                this.gameState.cardCollection.arena4 = {
                    "123": 1,
                    "124": 1
                };
                this.gameState.cardCollectionOrders.arena4 = [
                    123, 124
                ];
            }
            break;
        }
        
        for (var tier = 1; tier <= 5; tier++) {
            this.gameState.filters.showTier['tier'+ tier] = true;
        }

        this.currentTab = 'arena';
    }

    public resetGame(): void {
        var myStorage = window.localStorage;
        myStorage.setItem('gameData', '');
        window.location.reload();
    }

    public UniversalAura(arenaNumber: number): number {
        let result = 1;

        result += 0.05 * this.gameState.lifetimeGemsReceived[arenaNumber];

        return result;
    }

    droppedCardOntoCollection(ev) {
        let cardDroppedIsCollectionCard: boolean = ev.dragData.isCollectionCard;
        let otherSlotNumber: number = ev.dragData.slotNumber;
        let otherArenaNumber: number = ev.dragData.arenaNumber;
        let otherCardData: any = ev.dragData.cardData;
        
        if (otherArenaNumber != null && otherSlotNumber != null) {
            // if the card being dragged onto the collection is a grid card...

            // just remove that card from the grid
            AppComponent.instance.removeCardFromArena(otherArenaNumber, otherSlotNumber, otherCardData.id);
            SoundEffectManager.instance.playSound('removecard');
        }
    }

    private CheckForAchievements()
    {
        if (AchievementShowerComponent.instance == null) {
            return;
        }
        
        // Road to Riches
        if (!AchievementIsUnlocked(1)) {
            if (this.gameState.lifetimeResourcesEarned.arena1.gold >= 1000) {
                UnlockAchievement(1);
            }
        }
        // Mr. Moneybags
        if (!AchievementIsUnlocked(2)) {
            if (this.gameState.lifetimeResourcesEarned.arena1.gold >= 100000) {
                UnlockAchievement(2);
            }
        }
        // Elon
        if (!AchievementIsUnlocked(3)) {
            if (this.gameState.lifetimeResourcesEarned.arena1.gold >= 10000000) {
                UnlockAchievement(3);
            }
        }
        // Feeding Time
        if (!AchievementIsUnlocked(4)) {
            if (this.cardExistsInArena(1, 2) && this.cardExistsInArena(1, 4)) {
                UnlockAchievement(4);
            }
        }
        // Abahcolypse Now
        if (!AchievementIsUnlocked(5)) {
            if (this.GetCardIdAtSlot(1, this.getCardSlotNumberAtXYLocation(1, 1, 1)) == 21) { // meteor at the middle
                if (this.GetCardIdAtSlot(1, this.getCardSlotNumberAtXYLocation(1, 2, 1)) == 17) { // sheep at the middle right
                    UnlockAchievement(5);
                }
            }
        }
        // You Always Doubted Me (only Ghouls on board, and 25+ Gold/sec)
        if (!AchievementIsUnlocked(7)) {
            if (AmountOfGoldPerSecond(1) >= 25) {
                let isOnlyGhouls: boolean = true;
                for (var i = 0; i < 9; i++) {
                    if (this.GetCardIdAtSlot(1, i) != 1 && this.GetCardIdAtSlot(1, i) != null) {
                        isOnlyGhouls = false;
                        break;
                    }
                }
                if (isOnlyGhouls) {
                    UnlockAchievement(7);
                }
            }
        }
        // Lil Jack, Big Axe
        if (!AchievementIsUnlocked(11)) {
            if (this.gameState.highestUnlockedArena >= 2) {
                if (AmountOfWoodPerSecond(2) >= 500) {
                    let isOnlyLumberjacksAndHatchets: boolean = true;
                    for (var i = 0; i < 9; i++) {
                        if (this.GetCardIdAtSlot(2, i) != 24 && this.GetCardIdAtSlot(2, i) != 33 && this.GetCardIdAtSlot(2, i) != null) {
                            isOnlyLumberjacksAndHatchets = false;
                            break;
                        }
                    }
                    if (isOnlyLumberjacksAndHatchets) {
                        UnlockAchievement(11);
                    }
                }
            }
        }

        // retroactively add Haunted Woods achievement if earned previously
        if (this.gameState.highestUnlockedArena >= 2) {
            if (!AchievementIsUnlocked(9)) {
                UnlockAchievement(9);
            }
        }
        // retroactively add Underworld achievement if earned previously
        if (this.gameState.highestUnlockedArena >= 3) {
            if (!AchievementIsUnlocked(10)) {
                UnlockAchievement(10);
            }
        }
    }

    public arenaSelectionChanged(): void {
        this.closeSaveLayoutDialog();
        this.closeUpgradeWindow();
        this.sortCardCollection(this.gameState.selectedArenaNumber);
        this.isSelectingEmptySlot = false;
        SoundEffectManager.instance.arenaChanged();
    }

    public getCurrentCardHP(arenaNumber: number, slotNumber: number): number {
        let cardId = this.GetCardIdAtSlot(arenaNumber, slotNumber);
        if (cardId == null) {
            return 0;
        }
        let cardData = GetCardById(cardId);
        if (cardData == null) {
            return 0;
        }
        if (cardData.cardType != 'hero' && cardData.cardType != 'monster') {
            return 0; // only hero and monster cards can have HP!
        }

        return this.gameState['arena' + arenaNumber].cardSlots[slotNumber].currentHP;
    }

    private recordRecentResourcesEarned(arenaNumber: number, resourcesEarned: any): void {
        if (!this.recentResourcesEarned[arenaNumber]) {
            this.recentResourcesEarned[arenaNumber] = {};
        }
        for (let currentResource of Object.keys(resourcesEarned)) {
            if (!this.recentResourcesEarned[arenaNumber][currentResource]) {
                this.recentResourcesEarned[arenaNumber][currentResource] = [];
            }
            let currentResourceArray: number[] = this.recentResourcesEarned[arenaNumber][currentResource];
            currentResourceArray.unshift(resourcesEarned[currentResource]);
            while (currentResourceArray.length > 30) {
                currentResourceArray.pop();
            }
        }
    }

    public getEstimatedResourcePerSecondForBattleArena(arenaNumber: number, resourceType: string): string {
        let result: number = 0;

        let slotNumbers: number[] = this.getSlotNumbersOfCardsInPlay(arenaNumber, null);
        for (var i = 0; i < slotNumbers.length; i++) {
            let slotNumber = slotNumbers[i];
            
            if (this.gameState['arena' + arenaNumber].cardSlots[slotNumber].slotType == 'hero') {
                if (this.gameState['arena' + arenaNumber].cardSlots[slotNumber].cardInSlot != null) {
                    result += this.gameState['arena' + arenaNumber].cardSlots[slotNumber].estimatedLootPerSecond[resourceType];
                }
            }
        }

        return "(est. " + (result >= 0 ? "+" : "") + this.niceNumber.transform(result) + "/sec)";
    }

    public getAppContainerCssStyle(): SafeStyle {
        let result: string = "background-image: url(assets/images/backgrounds/" + this.gameState.selectedArenaNumber + ".png);";
        
        let secondsActivelyPlayingThisSession = Math.floor(new Date().getTime()/1000) - this.thisSessionStartedAtInSeconds;
        let desiredAspectRatio = 840 / 580;

        let windowWidth: number = window.innerWidth - 20; // subtract 20 to account for window bar and discrepencies just in case
        let windowHeight: number = window.innerHeight - 20;

        let deviceAspectRatio = windowWidth / windowHeight;

        let transformAmountX: number = 0;
        let transformAmountY: number = 0;
        
        if (deviceAspectRatio >= desiredAspectRatio) {
            // todo: change this somehow
            transformAmountX = windowWidth / 840;
            transformAmountY = windowHeight / 580;
        }
        else {
            // todo: change this somehow
            transformAmountX = windowWidth / 840;
            transformAmountY = windowHeight / 580;
        }

        result += " transform: scaleX(" + transformAmountX + ") scaleY(" + transformAmountY + ");";


        return this.sanitizer.bypassSecurityTrustStyle(result);
    }
}
