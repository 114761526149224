import { AppComponent } from './app.component';

export function AmountOfWoodPerSecond(arenaNumber: number) {
    var woodPerSecond = 0;

    var cardSlots = AppComponent.instance.gameState['arena' + arenaNumber].cardSlots;
    for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
        if (cardSlots[slotNumber].cardInSlot != null) {
            var currentCardId = cardSlots[slotNumber].cardInSlot.id;

            var woodForThisSlot: number = 0;

            switch (currentCardId) {

                // Arena 2 Tier 1 cards:

                case 24: // Lil Lumberjack
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                case 25: // Miner. +0.5 iron/sec, -2 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                case 26:// Sickle.   +1 iron/sec, -4 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                case 27: { // Knight.  0.5 wood/sec, + an extra 0.5 wood/sec for every other Knight in play
                    let knightCount: number = AppComponent.instance.cardCountInArena(arenaNumber, 27);
                    woodForThisSlot = (rankAdjustedModifier(currentCardId, arenaNumber, 0) + ((knightCount-1) * rankAdjustedModifier(currentCardId, arenaNumber, 1))) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }
                case 28: // Scarecrow.  +2 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                case 29: // Bubba.  +3 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                case 30: // Hades.  +5 iron/sec, -20 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                case 32: // Gargoyle.  +4 wood/sec, disable wood for other cards in row
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                case 34: // Iron Golem.   +2.5 iron/sec, -10 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                case 37: // The Risen.  +4 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                case 49: // Iron Robot.  -5 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                case 52: { // Viking.  +5 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }
                case 53: { // Investor Imp.  +1 wood/sec for every 3,000 wood held
                    let woodHeld: number = AppComponent.instance.gameState.resources['arena' + arenaNumber].wood;
                    let investment = rankAdjustedModifier(currentCardId, arenaNumber, 0) * (Math.floor(woodHeld / 3000));
                    if (investment > rankAdjustedModifier(currentCardId, arenaNumber, 2)) {
                        investment = rankAdjustedModifier(currentCardId, arenaNumber, 2);
                    }
                    if (investment <= 0) {
                        investment = 1;
                    }
                    woodForThisSlot = investment * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }

                // Arena 2 Tier 2 cards:

                case 50: { // Investor Dog.  +1 wood/sec for every 1,000 wood held
                    let woodHeld: number = AppComponent.instance.gameState.resources['arena' + arenaNumber].wood;
                    let investment = rankAdjustedModifier(currentCardId, arenaNumber, 0) * (Math.floor(woodHeld / 1000));
                    if (investment > rankAdjustedModifier(currentCardId, arenaNumber, 2)) {
                        investment = rankAdjustedModifier(currentCardId, arenaNumber, 2);
                    }
                    if (investment <= 0) {
                        investment = 1;
                    }
                    woodForThisSlot = investment * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }

                case 54: { // Treant.  +12 wood/sec.
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }

                case 55: { // Big crab.  +8 wood/sec.  Extra +7 wood/sec if a Forest Shroom is in play.
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    if (AppComponent.instance.cardExistsInArena(arenaNumber, 59)) { // if there's a Forest Shroom in play, increase wood production further
                        woodForThisSlot += rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    }
                    woodForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }

                case 57: { // Orc. +10 wood/sec. +200% iron production for the card to the left
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }

                case 59: { // Forest Shroom. +11 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }

                case 62: { // Mining Beast consumes 80 per sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                }

                case 63: { // Roo.  +10 wood/sec, +200% wood production for card to the right
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }

                case 64: { // Noclops consumes 40 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                }

                case 65: { // Spider King consumes 30 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                }

                case 66: { // Death Knight consumes 120 wood/sec
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                }

                case 67: { // Warrior.  +25 wood/sec, disable wood production for other cards in row
                    woodForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'wood');
                    break;
                }

            }

            if (woodForThisSlot < 0) {
                if (isImmuneFromConsumingWood(arenaNumber, slotNumber)) {
                    woodForThisSlot = 0;
                }
            }

            woodForThisSlot *= AppComponent.instance.UniversalAura(arenaNumber);
            woodPerSecond += woodForThisSlot;
        }
    }

    return woodPerSecond;
}

function isImmuneFromConsumingWood(arenaNumber: number, slotNumber: number): boolean {
    return AppComponent.instance.cardIsImmuneFromConsumingResource(arenaNumber, slotNumber, 'wood');
}

function rankAdjustedModifier(cardId: number, arenaNumber: number, modifierIndex: number): number {
    return AppComponent.instance.rankAdjustedModifier(cardId, arenaNumber, modifierIndex);
}
