import { AppComponent } from "./app.component";

export function AmountOfIronPerSecond(arenaNumber: number) {
    var ironPerSecond = 0;

    var cardSlots = AppComponent.instance.gameState['arena' + arenaNumber].cardSlots;
    for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
        if (cardSlots[slotNumber].cardInSlot != null) {
            var currentCardId = cardSlots[slotNumber].cardInSlot.id;

            var ironForThisSlot: number = 0;

            // NOTE: wood values specified in comments below are outdated.  see CardModels for latest info.


            switch (currentCardId) {
                case 25: // Miner: +0.5 iron/sec, -2 wood/sec
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
                case 26: // Sickle: +1 iron/sec, -4 wood/sec
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
                case 30: // Hades.  +5 iron/sec, -20 wood/sec
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
                case 34: // Iron Golem.  +2.5 iron/sec, -10 wood/sec
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
                case 38: // Valkyrie.  +0.5 iron/sec, no wood consumption
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    break;
                case 49: // Iron Robot.  +2 iron/sec, -5 wood/sec, not affected by bonuses
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
                case 62: // Mining Beast.  +20 iron, -80 wood
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
                case 64: // Noclops. +10 iron, -40 wood
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
                case 65: // Spider King. +8 iron/sec, -30 wood/sec. +200% iron production for all other cards
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
                case 66: // Death Knight.  +30 iron, -120 wood
                    ironForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'iron');
                    if (!hasEnoughWood(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        ironForThisSlot = 0;
                    }
                    break;
            }

            ironForThisSlot *= AppComponent.instance.UniversalAura(arenaNumber);
            ironPerSecond += ironForThisSlot;
        }
    }

    return ironPerSecond;
}

function hasEnoughWood(arenaNumber: number, slotNumber, amountNeeded: number): boolean {
    if (isImuneFromConsumingWood(arenaNumber, slotNumber)) {
        return true;
    }
    let result = AppComponent.instance.gameState.resources['arena' + arenaNumber].wood >= amountNeeded;
    return result;
}

function popCardOutOfSlot(arenaNumber: number, slotNumber: number, cardId: number): void {
    AppComponent.instance.removeCardFromArena(arenaNumber, slotNumber, cardId);
}

function isImuneFromConsumingWood(arenaNumber: number, slotNumber: number): boolean {
    return AppComponent.instance.cardIsImmuneFromConsumingResource(arenaNumber, slotNumber, 'wood');
}

function rankAdjustedModifier(cardId: number, arenaNumber: number, modifierIndex: number): number {
    return AppComponent.instance.rankAdjustedModifier(cardId, arenaNumber, modifierIndex);
}
