import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgDragDropModule } from 'ng-drag-drop';


import { AppComponent } from './app.component';
import { CardComponent } from './card.component';
import { TooltipComponent } from './tooltip.component';
import { EmptyCardSlotComponent } from './empty-card-slot.component';
import { BoosterPackPurchaserComponent } from './booster-pack-purchaser.component';
import { ShopArena1Component } from './shop-arena1.component';
import { BoosterPackOpeningComponent } from './booster-pack-opening.component';
import { KongregateComponent } from './kongregate.component';
import { ShopArena2Component } from './shop-arena2.component';
import { ShopArena3Component } from './shop-arena3.component';
import { NiceNumberPipe } from './NiceNumber.pipe';
import { OfflineProgressComponent } from './offline-progress.component';
import { SaveLayoutDialogComponent } from './save-layout-dialog.component';
import { PremiumShopComponent } from './premium-shop.component';
import { ErrorMessageComponent } from './error-message.component';
import { OptionsComponent } from './options.component';
import { AscendTabComponent } from './AscendTab.component';
import { UpgradeWindowComponent } from './UpgradeWindow.component';
import { OtherTabComponent } from './other-tab.component';
import { AchievementIconComponent } from './AchievementIcon.component';
import { AchievementShowerComponent } from './AchievementShower.component';
import { SoundEffectManager } from './SoundEffectManager.component';
import { ShopArena4Component } from './shop-arena4.component';
import { LootDropAnimationComponent } from './loot-drop-animation.component';
import { SteamComponent } from './steam.component';

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    TooltipComponent,
    EmptyCardSlotComponent,
    BoosterPackPurchaserComponent,
    ShopArena1Component,
    ShopArena2Component,
    ShopArena3Component,
    ShopArena4Component,
    BoosterPackOpeningComponent,
    KongregateComponent,
    SteamComponent,
    OfflineProgressComponent,
    NiceNumberPipe,
    SaveLayoutDialogComponent,
    PremiumShopComponent,
    ErrorMessageComponent,
    OptionsComponent,
    AscendTabComponent,
    UpgradeWindowComponent,
    OtherTabComponent,
    AchievementIconComponent,
    AchievementShowerComponent,
    LootDropAnimationComponent,
    SoundEffectManager
  ],
  imports: [
    BrowserModule,
    AngularFontAwesomeModule,
    FormsModule,
    NgDragDropModule.forRoot()
  ],
  providers: [NiceNumberPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
