import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';

@Component({
    selector: 'shop-arena3',
    templateUrl: './shop-arena3.component.html'
})
export class ShopArena3Component {

    getAppComponent(): AppComponent {
        return AppComponent.instance;
    }

    showTooltipForNextRealm(): void {
        TooltipComponent.instance.showTooltip("Coming in a future update...");
    }

    hideTooltip(): void {
        TooltipComponent.instance.hideTooltip();
    }

    shouldDisableNextExpansionUnlockButton() {
        return false;
    }

    tryToUnlockNextExpansion(): void {
        
        if (this.getAppComponent().gameState.resources.arena3.ether < 1000000000 || this.getAppComponent().gameState.resources.arena3.skulls < 1000000000 || this.getAppComponent().gameState.resources.arena3.feathers < 1000000000) {
            return;
        }

        AppComponent.instance.gameState.resources.arena3['ether'] -= 1000000000;
        AppComponent.instance.gameState.resources.arena3['feathers'] -= 1000000000;
        AppComponent.instance.gameState.resources.arena3['skulls'] -= 1000000000;
        AppComponent.instance.unlockArena(4);
    }

    purchaseMultiplierUpdated(): void {
        if (this.getAppComponent().buyMaxChecked) {
            this.getAppComponent().purchaseMultiplier = null;
            return;
        }
        
        try {
            this.getAppComponent().purchaseMultiplier = parseInt(this.getAppComponent().purchaseMultiplier + "");
        }
        catch(err) {
            this.getAppComponent().purchaseMultiplier = 1;
        }

        if (this.getAppComponent().purchaseMultiplier < 1) {
            this.getAppComponent().purchaseMultiplier = 1;
        }
    }
}
