import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BoosterPackPurchaserComponent } from './booster-pack-purchaser.component';
import { TooltipComponent } from './tooltip.component';

@Component({
    selector: 'achievement-icon',
    templateUrl: 'AchievementIcon.component.html'
})
export class AchievementIconComponent {
    @Input() isUnlocked: boolean;
    @Input() achievementData: any;

    mouseOver() {
        TooltipComponent.instance.showTooltip((this.isUnlocked ? "[X] " : "[ ] ") + this.achievementData.title + ": " + this.achievementData.description);
    }

    mouseOut() {
        TooltipComponent.instance.hideTooltip();
    }
}
