export const CardModels = [
    // TIER 1:
    {
        'id': 1,
        'name': 'Ghoul',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec',
        'imageFilename': 'assets/images/cards/ghoul.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1 ]
    },
    {
        'id': 2,
        'name': 'Crab',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec. Extra +{modifier1} gold/sec if a Mushroom is in play',
        'imageFilename': 'assets/images/cards/crab.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1, 2 ]
    },
    {
        'id': 3,
        'name': 'Gold Mine',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec.  +{modifier1%}% gold production for cards above and below this',
        'imageFilename': 'assets/images/cards/gold_mine.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1.5, 0.25 ]
    },
    {
        'id': 4,
        'name': 'Mushroom',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec',
        'imageFilename': 'assets/images/cards/mushroom.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1.5 ]
    },
    {
        'id': 5,
        'name': 'Mace',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0%}% gold production for the card to the right',
        'imageFilename': 'assets/images/cards/mace.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1 ]
    },
    {
        'id': 6,
        'name': 'Gem',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0%}% gold production for the cards above and below this',
        'imageFilename': 'assets/images/cards/gem.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 0.5 ]
    },
    {
        'id': 7,
        'name': 'Beer',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec. Disable gold production for other cards in this row',
        'imageFilename': 'assets/images/cards/beer.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 3 ]
    },
    {
        'id': 8,
        'name': 'Greedy Bird',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec.  Extra +{modifier1} gold/sec if this is the only card in play',
        'imageFilename': 'assets/images/cards/bird.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1, 5 ]
    },
    {
        'id': 9,
        'name': 'Windmill',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0%}% gold production for all other cards',
        'imageFilename': 'assets/images/cards/mill.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 0.25 ]
    },
    {
        'id': 10,
        'name': 'Scaredy Cat',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec, but only if this is the only card in its row',
        'imageFilename': 'assets/images/cards/girl.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 3.5 ]
    },
    {
        'id': 11,
        'name': 'Ice Sword',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0%}% gold production for the card above this',
        'imageFilename': 'assets/images/cards/icesword.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1 ]
    },
    {
        'id': 12,
        'name': 'Coin',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec',
        'imageFilename': 'assets/images/cards/coin.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1.5 ]
    },
    {
        'id': 13,
        'name': 'Ghost',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec',
        'imageFilename': 'assets/images/cards/ghost.png',
        'rarity': 4,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 3 ]
    },
    {
        'id': 14,
        'name': 'Adventuring Fox',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} gold/sec',
        'imageFilename': 'assets/images/cards/adventurer.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 2 ]
    },
    // TIER 2:
    {
        'id': 15,
        'name': 'Cultist',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0} gold/sec',
        'imageFilename': 'assets/images/cards/cultist.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 8 ]
    },
    {
        'id': 16,
        'name': 'Skeleton',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0} gold/sec',
        'imageFilename': 'assets/images/cards/skeleton.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 6 ]
    },
    {
        'id': 17,
        'name': 'Sheep',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0} gold/sec.  +{modifier1%}% gold production for the card left of this',
        'imageFilename': 'assets/images/cards/sheep.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 4, 1.25 ]
    },
    {
        'id': 18,
        'name': 'Carrot',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0} gold/sec, but only if no cards are above or below it',
        'imageFilename': 'assets/images/cards/carrot.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 12 ]
    },
    {
        'id': 19,
        'name': 'Golem',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0} gold/sec. Not affected by any bonuses, nor by any disables/penalties',
        'imageFilename': 'assets/images/cards/golem.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [ 13 ]
    },
    {
        'id': 20,
        'name': 'Naga',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0} gold/sec. Disable gold production for the card below this',
        'imageFilename': 'assets/images/cards/naga.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 8 ]
    },
    {
        'id': 21,
        'name': 'Meteor',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0} gold/sec. Disable gold production for all other cards.',
        'imageFilename': 'assets/images/cards/meteor.png',
        'rarity': 4,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 22 ]
    },
    {
        'id': 22,
        'name': 'Inn',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0%}% gold production for all other cards',
        'imageFilename': 'assets/images/cards/lodge.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 0.5 ]
    },
    {
        'id': 23,
        'name': 'Honey',
        'tier': 2,
        'arena': 1,
        'description': '+{modifier0%}% gold production for the card below this',
        'imageFilename': 'assets/images/cards/honey.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [ 1.5 ]
    },
    // Haunted Forest tier 1
    {
        'id': 24,
        'name': 'Lil\' Lumberjack',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} wood/sec',
        'imageFilename': 'assets/images/cards/lil_lumberjack.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 1 ]
    },
    {
        'id': 25,
        'name': 'Miner',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec',
        'imageFilename': 'assets/images/cards/miner.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 0.5, -2 ]
    },
    {
        'id': 26,
        'name': 'Sickle',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec',
        'imageFilename': 'assets/images/cards/sickle.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 1, -4 ]
    },
    {
        'id': 27,
        'name': 'Knight',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} wood/sec.  Extra +{modifier1} wood/sec for every other Knight in play',
        'imageFilename': 'assets/images/cards/knight.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 0.5, 0.5 ]
    },
    {
        'id': 28,
        'name': 'Scarecrow',
        'tier': 1,
        'arena': 1,
        'description': '+{modifier0} wood/sec',
        'imageFilename': 'assets/images/cards/scarecrow.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 2 ]
    },
    {
        'id': 29,
        'name': 'Bubba',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} wood/sec',
        'imageFilename': 'assets/images/cards/bubba.png',
        'rarity': 4,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 3 ]
    },
    {
        'id': 30,
        'name': 'Hades',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec',
        'imageFilename': 'assets/images/cards/hades.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 5, -20 ]
    },
    {
        'id': 31,
        'name': 'Crossbow',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% wood production for adjacent cards',
        'imageFilename': 'assets/images/cards/crossbow.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 0.5 ]
    },
    {
        'id': 32,
        'name': 'Gargoyle',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} wood/sec.  Disable wood production for other cards in row',
        'imageFilename': 'assets/images/cards/gargoyle.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 4 ]
    },
    {
        'id': 33,
        'name': 'Hatchet',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% wood production for adjacent Lil\' Lumberjacks',
        'imageFilename': 'assets/images/cards/hatchet.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 3 ]
    },
    {
        'id': 34,
        'name': 'Iron Golem',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec',
        'imageFilename': 'assets/images/cards/iron_golem.png',
        'rarity': 4,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 2.5, -10 ]
    },
    {
        'id': 35,
        'name': 'Gauntlet',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% iron production for adjacent cards',
        'imageFilename': 'assets/images/cards/gauntlet.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 1 ]
    },
    { // NOTE: this one was moved to Tier 2
        'id': 36,
        'name': 'Iron Mine',
        'tier': 2,
        'arena': 2,
        'description': 'Adjacent cards consume no wood, and have +{modifier0%}% iron production',
        'imageFilename': 'assets/images/cards/cavern.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 0.1 ]
    },
    {
        'id': 37,
        'name': 'The Risen',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} wood/sec',
        'imageFilename': 'assets/images/cards/therisen.png',
        'rarity': 4,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 4 ]
    },
    {
        'id': 38,
        'name': 'Valkyrie',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} iron/sec.  Doesn\'t consume wood.',
        'imageFilename': 'assets/images/cards/valkyrie.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 0.5 ]
    },
    {
        'id': 39,
        'name': 'Pipe',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% wood production for adjacent cards',
        'imageFilename': 'assets/images/cards/pipe.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 0.75 ]
    },
    {
        'id': 40,
        'name': 'Armor',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% wood production for cards in this column',
        'imageFilename': 'assets/images/cards/armor.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 0.75 ]
    },
    {
        'id': 41,
        'name': 'Watchtower',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% wood production for cards in this row',
        'imageFilename': 'assets/images/cards/tower.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 0.75 ]
    },
    {
        'id': 42,
        'name': 'Potion',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% iron production for cards in this row',
        'imageFilename': 'assets/images/cards/potion.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 1 ]
    },
    {
        'id': 43,
        'name': 'Starfire',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% wood production for all cards',
        'imageFilename': 'assets/images/cards/starfire.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 0.25 ]
    },
    {
        'id': 44,
        'name': 'Pancakes',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% iron production for the card below',
        'imageFilename': 'assets/images/cards/pancakes.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 2 ]
    },
    {
        'id': 45,
        'name': 'Warpstone',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% iron production for the card to the left',
        'imageFilename': 'assets/images/cards/warpstone.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 2 ]
    },
    {
        'id': 46,
        'name': 'Petrify',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% iron production for the card above',
        'imageFilename': 'assets/images/cards/petrify.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 2 ]
    },
    {
        'id': 47,
        'name': 'Sushi',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% iron production for the card to the right',
        'imageFilename': 'assets/images/cards/sushi.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 2 ]
    },
    {
        'id': 48,
        'name': 'Forest Inn',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% iron production for all cards',
        'imageFilename': 'assets/images/cards/forestinn.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 0.25 ]
    },
    {
        'id': 49,
        'name': 'Iron Robot',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec. Not affected by bonuses/penalties',
        'imageFilename': 'assets/images/cards/iron_robot.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'iron|wood',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [ 2, -5 ]
    },
    {
        'id': 50,
        'name': 'Investor Dog',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} wood/sec for every 1k wood held (min +1/sec, max +{modifier2}/sec)',
        'imageFilename': 'assets/images/cards/investor_dog.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 1, 1000, 30 ]
    },
    {
        'id': 51,
        'name': 'Meat',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0%}% wood production for card below',
        'imageFilename': 'assets/images/cards/meat.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 2.5 ]
    },
    {
        'id': 52,
        'name': 'Viking',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} wood/sec',
        'imageFilename': 'assets/images/cards/viking.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 5 ]
    },
    {
        'id': 53,
        'name': 'Investor Imp',
        'tier': 1,
        'arena': 2,
        'description': '+{modifier0} wood/sec for every 3k wood held (min +1/sec, max +{modifier2}/sec)',
        'imageFilename': 'assets/images/cards/imp.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 1, 3000, 20 ]
    },
    {
        'id': 54,
        'name': 'Treant',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} wood/sec',
        'imageFilename': 'assets/images/cards/treant.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 12 ]
    },
    {
        'id': 55,
        'name': 'Big Crab',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} wood/sec.  Extra +{modifier1} wood/sec if a Forest Shroom is in play',
        'imageFilename': 'assets/images/cards/big_crab.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 8, 7 ]
    },
    {
        'id': 56,
        'name': 'Heart',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0%}% iron production for the card below',
        'imageFilename': 'assets/images/cards/heart.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 3 ]
    },
    {
        'id': 57,
        'name': 'Orc',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} wood/sec. +{modifier1%}% iron production for the card to the left',
        'imageFilename': 'assets/images/cards/orc.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 10, 2 ]
    },
    {
        'id': 58,
        'name': 'Safe Refuge',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0%}% iron production and +{modifier1%}% wood production for all cards',
        'imageFilename': 'assets/images/cards/safe_refuge.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 0.4, 0.4 ]
    },
    {
        'id': 59,
        'name': 'Forest Shroom',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} wood/sec',
        'imageFilename': 'assets/images/cards/forestshroom.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 11 ]
    },
    {
        'id': 60,
        'name': 'Skull Helm',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0%}% wood or iron production for card to the right',
        'imageFilename': 'assets/images/cards/skullhelm.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 3 ]
    },
    {
        'id': 61,
        'name': 'Cataclysm',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0%}% iron production for all cards. Disable all wood production',
        'imageFilename': 'assets/images/cards/cataclysm.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron|wood',
        'resourcesAffectedByMultipliers': 'iron|wood',
        'modifiers': [ 1 ]
    },
    {
        'id': 62,
        'name': 'Mining Beast',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec',
        'imageFilename': 'assets/images/cards/mining_beast.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 20, -80 ]
    },
    {
        'id': 63,
        'name': 'Roo',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} wood/sec. +{modifier1%}% wood production for card to the right',
        'imageFilename': 'assets/images/cards/roo.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 10, 2 ]
    },
    {
        'id': 64,
        'name': 'Noclops',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec. +{modifier2%}% iron production for card above',
        'imageFilename': 'assets/images/cards/noclops.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 10, -40, 2 ]
    },
    {
        'id': 65,
        'name': 'Spider King',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec. +{modifier2%}% iron production for all other cards',
        'imageFilename': 'assets/images/cards/spider_king.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 8, -30, 1 ]
    },
    {
        'id': 66,
        'name': 'Death Knight',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} iron/sec, {modifier1} wood/sec',
        'imageFilename': 'assets/images/cards/deathknight.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'iron',
        'resourcesAffectedByMultipliers': 'iron',
        'modifiers': [ 30, -120 ]
    },
    {
        'id': 67,
        'name': 'Warrior',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0} wood/sec. Disable wood production for other cards in row',
        'imageFilename': 'assets/images/cards/warrior.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [ 25 ]
    },
    {
        'id': 68,
        'name': 'Hood',
        'tier': 2,
        'arena': 2,
        'description': '+{modifier0%}% iron production and disable wood production for adjacent cards',
        'imageFilename': 'assets/images/cards/hood.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'iron|wood',
        'resourcesAffectedByMultipliers': 'iron|wood',
        'modifiers': [ 3 ]
    },
    // Arena 3 cards:
    {
        'id': 69,
        'name': 'Priestess',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} ether/sec, +{modifier1} skulls/sec, +{modifier2} feathers/sec',
        'imageFilename': 'assets/images/cards/priestess.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether|skulls|feathers',
        'resourcesAffectedByMultipliers': 'ether|skulls|feathers',
        'modifiers': [ 0.25, 0.25, 0.25 ]
    },
    {
        'id': 70,
        'name': 'Jar of Death',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0%}% skulls production for adjacent cards',
        'imageFilename': 'assets/images/cards/jarofdeath.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 1.25 ]
    },
    {
        'id': 71,
        'name': 'Hellspider',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} feathers/sec',
        'imageFilename': 'assets/images/cards/hellspider.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'feathers',
        'resourcesAffectedByMultipliers': 'feathers',
        'modifiers': [ 1 ]
    },
    {
        'id': 72,
        'name': 'Necromancer',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} ether/sec, {modifier1} skulls/sec',
        'imageFilename': 'assets/images/cards/necromancer.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether|skulls',
        'resourcesAffectedByMultipliers': 'ether|skulls',
        'modifiers': [ 2.25, -1 ]
    },
    {
        'id': 73,
        'name': 'Possessed',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} ether/sec',
        'imageFilename': 'assets/images/cards/possessed.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 1 ]
    },
    {
        'id': 74,
        'name': 'Devourer',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} skulls/sec',
        'imageFilename': 'assets/images/cards/devourer.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 1 ]
    },
    {
        'id': 75,
        'name': 'Timid Giant',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} feathers/sec',
        'imageFilename': 'assets/images/cards/timid_giant.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'feathers',
        'resourcesAffectedByMultipliers': 'feathers',
        'modifiers': [ 1.25 ]
    },
    {
        'id': 76,
        'name': 'Scimitar',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0%}% skulls production for card above',
        'imageFilename': 'assets/images/cards/scimitar.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 2.5 ]
    },
    {
        'id': 77,
        'name': 'Lizard',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} feathers/sec',
        'imageFilename': 'assets/images/cards/lizard.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'feathers',
        'resourcesAffectedByMultipliers': 'feathers',
        'modifiers': [ 1.5 ]
    },
    {
        'id': 78,
        'name': 'Undertaker',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} skulls/sec',
        'imageFilename': 'assets/images/cards/undertaker.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 1.25 ]
    },
    {
        'id': 79,
        'name': 'Rabbid',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} skulls/sec',
        'imageFilename': 'assets/images/cards/rabbid.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 1.5 ]
    },
    {
        'id': 80,
        'name': 'Death Imp',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} ether/sec, +{modifier1} skulls/sec, +{modifier2} feathers/sec',
        'imageFilename': 'assets/images/cards/deathimp.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether|skulls|feathers',
        'resourcesAffectedByMultipliers': 'ether|skulls|feathers',
        'modifiers': [ 0.75, 0.75, 0.75 ]
    },
    {
        'id': 81,
        'name': 'Frog',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} ether/sec',
        'imageFilename': 'assets/images/cards/frog.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 1.25 ]
    },
    {
        'id': 82,
        'name': 'Sailor',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} skulls/sec, +{modifier1} feathers/sec',
        'imageFilename': 'assets/images/cards/sailor.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls|feathers',
        'resourcesAffectedByMultipliers': 'skulls|feathers',
        'modifiers': [ 0.75, 0.75 ]
    },
    {
        'id': 83,
        'name': 'Icy Outpost',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0%}% feathers production for adjacent cards',
        'imageFilename': 'assets/images/cards/icy_outpost.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 1.5 ]
    },
    {
        'id': 84,
        'name': 'Obelisk',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0%}% ether production and disable skulls production for adjacent cards',
        'imageFilename': 'assets/images/cards/obelisk.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether|skulls',
        'resourcesAffectedByMultipliers': 'ether|skulls',
        'modifiers': [ 1.5 ]
    },
    {
        'id': 85,
        'name': 'Shield',
        'tier': 1,
        'arena': 3,
        'description': '{modifier0%}% feathers production for cards above and below',
        'imageFilename': 'assets/images/cards/shield.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'feathers',
        'resourcesAffectedByMultipliers': 'feathers',
        'modifiers': [ 2.5 ]
    },
    {
        'id': 86,
        'name': 'Shaman',
        'tier': 1,
        'arena': 3,
        'description': '+{modifier0} skulls/sec, +{modifier1%}% ether production cards in this row',
        'imageFilename': 'assets/images/cards/shaman.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'skulls|ether',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 0.5, 3 ]
    },
    {
        'id': 87,
        'name': 'Mana Potion',
        'tier': 1,
        'arena': 3,
        'description': '{modifier0%}% ether production for cards above and below',
        'imageFilename': 'assets/images/cards/mana.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 2 ]
    },
    // Tier 2 cards: ids 88 through 107
    {
        'id': 88,
        'name': 'Sneaky Barrel',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} ether/sec',
        'imageFilename': 'assets/images/cards/barrel.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 5 ]
    },
    {
        'id': 89,
        'name': 'Demon',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} skulls/sec',
        'imageFilename': 'assets/images/cards/demon.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 5 ]
    },
    {
        'id': 90,
        'name': 'Hideaway',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0%}% feathers production for cards in this column',
        'imageFilename': 'assets/images/cards/hideaway.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'feathers',
        'resourcesAffectedByMultipliers': 'feathers',
        'modifiers': [ 4 ]
    },
    {
        'id': 91,
        'name': 'Dead Fish',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0%}% feathers production for card above',
        'imageFilename': 'assets/images/cards/fish.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'feathers',
        'resourcesAffectedByMultipliers': 'feathers',
        'modifiers': [ 4 ]
    },
    {
        'id': 92,
        'name': 'Ice Fish',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0%}% ether/sec for card to the left',
        'imageFilename': 'assets/images/cards/ice_fish.png',
        'rarity': 4, // probably a high-ish rarity due to match with Fisherman
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 4.5 ]
    },
    {
        'id': 93,
        'name': 'Fisherman',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} ether/sec.  +{modifier1} more ether/sec if an Ice Fish is below',
        'imageFilename': 'assets/images/cards/fisher.png',
        'rarity': 4, // probably a high-ish rarity due to match with Ice Fish
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 4, 6 ]
    },
    {
        'id': 94,
        'name': 'Bigmouth',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} feathers/sec',
        'imageFilename': 'assets/images/cards/bigmouth.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'feathers',
        'resourcesAffectedByMultipliers': 'feathers',
        'modifiers': [ 6 ]
    },
    {
        'id': 95,
        'name': 'Werewolf',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} feathers/sec.  +{modifier1%}% skulls for card to the right',
        'imageFilename': 'assets/images/cards/werewolf.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'feathers|skulls',
        'resourcesAffectedByMultipliers': 'feathers|skulls',
        'modifiers': [ 2, 3.5 ]
    },
    {
        'id': 96,
        'name': 'Wagon',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0%}% ether/sec for cards in this row',
        'imageFilename': 'assets/images/cards/wagon.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 4 ]
    },
    {
        'id': 97,
        'name': 'Warlock',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} feathers/sec',
        'imageFilename': 'assets/images/cards/warlock.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'feathers',
        'resourcesAffectedByMultipliers': 'feathers',
        'modifiers': [ 5 ]
    },
    {
        'id': 98,
        'name': 'Fort',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0%}% ether for cards above and below',
        'imageFilename': 'assets/images/cards/fort.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 3.5 ]
    },
    {
        'id': 99,
        'name': 'Crawler',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} ether/sec. +{modifier1%}% skulls for adjacent cards',
        'imageFilename': 'assets/images/cards/crawler.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'ether|skulls',
        'resourcesAffectedByMultipliers': 'ether|skulls',
        'modifiers': [ 4, 2.5 ]
    },
    {
        'id': 100,
        'name': 'Slug',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} ether/sec.  +{modifier1%}% feathers for card above',
        'imageFilename': 'assets/images/cards/slug.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'ether|feathers',
        'resourcesAffectedByMultipliers': 'ether|feathers',
        'modifiers': [ 3, 4 ]
    },
    {
        'id': 101,
        'name': 'Sign',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0%}% skulls for cards in this row',
        'imageFilename': 'assets/images/cards/sign.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 3.5 ]
    },
    {
        'id': 102,
        'name': 'Winged Imp',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} ether/sec. +{modifier1%}% skulls for card below',
        'imageFilename': 'assets/images/cards/winged_imp.png',
        'rarity': 4,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'ether|skulls',
        'resourcesAffectedByMultipliers': 'ether|skulls',
        'modifiers': [ 3, 4 ]
    },
    {
        'id': 103,
        'name': 'Kobold',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} skulls/sec',
        'imageFilename': 'assets/images/cards/kobold.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 6 ]
    },
    {
        'id': 104,
        'name': 'Baby Dragon',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} ether/sec',
        'imageFilename': 'assets/images/cards/baby_dragon.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 6 ]
    },
    {
        'id': 105,
        'name': 'Knight Capt',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} skulls/sec, +{modifier1%}% feathers for card to the right',
        'imageFilename': 'assets/images/cards/knight_capt.png',
        'rarity': 4,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls|feathers',
        'resourcesAffectedByMultipliers': 'skulls|feathers',
        'modifiers': [ 3, 4 ]
    },
    {
        'id': 106,
        'name': 'Horror',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0} skulls/sec',
        'imageFilename': 'assets/images/cards/horror.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 9 ]
    },
    {
        'id': 107,
        'name': 'Ruins',
        'tier': 2,
        'arena': 3,
        'description': '+{modifier0%}% ether, skulls, and feathers for adjacent cards',
        'imageFilename': 'assets/images/cards/ruins.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether|skulls|feathers',
        'resourcesAffectedByMultipliers': 'ether|skulls|feathers',
        'modifiers': [ 2.5 ]
    },
    // Arena 3 Tier 3 cards:
    {
        'id': 108,
        'name': 'Lucy',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} ether/sec',
        'imageFilename': 'assets/images/cards/lucy.png',
        'rarity': 5,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 40 ]
    },
    {
        'id': 109,
        'name': 'Missionary',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} feathers/sec, {modifier1} skulls/sec',
        'imageFilename': 'assets/images/cards/missionary.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls|feathers',
        'resourcesAffectedByMultipliers': 'skulls|feathers',
        'modifiers': [ 30, -6 ]
    },
    {
        'id': 110,
        'name': 'Nightwatch',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} skulls/sec, +{modifier1} feathers/sec',
        'imageFilename': 'assets/images/cards/nightwatch.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls|feathers',
        'resourcesAffectedByMultipliers': 'skulls|feathers',
        'modifiers': [ 24, 24 ]
    },
    {
        'id': 111,
        'name': 'Yeti',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} skulls/sec',
        'imageFilename': 'assets/images/cards/yeti.png',
        'rarity': 1,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 30 ]
    },
    {
        'id': 112,
        'name': 'Oasis',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0%}% ether, {modifier1%}% feathers for adjacent cards',
        'imageFilename': 'assets/images/cards/oasis.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether|feathers',
        'resourcesAffectedByMultipliers': 'ether|feathers',
        'modifiers': [ 4, 3 ]
    },
    {
        'id': 113,
        'name': 'Igloo',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0%}% ether for card to the right',
        'imageFilename': 'assets/images/cards/igloo.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 10 ]
    },
    {
        'id': 114,
        'name': 'Sharky',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} skulls/sec, +{modifier1} ether/sec',
        'imageFilename': 'assets/images/cards/sharky.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls|ether',
        'resourcesAffectedByMultipliers': 'skulls|ether',
        'modifiers': [ 24, 24 ]
    },
    {
        'id': 115,
        'name': 'Gwen',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} ether/sec, +{modifier1} feathers/sec',
        'imageFilename': 'assets/images/cards/gwen.png',
        'rarity': 2,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether|feathers',
        'resourcesAffectedByMultipliers': 'ether|feathers',
        'modifiers': [ 24, 24 ]
    },
    {
        'id': 116,
        'name': 'Trampler',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} skulls/sec, +{modifier1%}% ether for cards in this row',
        'imageFilename': 'assets/images/cards/trampler.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': true,
        'relevantResource': 'skulls|ether',
        'resourcesAffectedByMultipliers': 'skulls|ether',
        'modifiers': [ 20, 5 ]
    },
    {
        'id': 117,
        'name': 'Spearfisher',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} skulls/sec.  +{modifier1} more skulls/sec if a Dead Fish is in play',
        'imageFilename': 'assets/images/cards/spearfisher.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 24, 24 ]
    },
    {
        'id': 118,
        'name': 'Shellhouse',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0%}% skulls for all cards in this column',
        'imageFilename': 'assets/images/cards/shellhouse.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 5 ]
    },
    {
        'id': 119,
        'name': 'Icestalker',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} ether/sec',
        'imageFilename': 'assets/images/cards/icestalker.png',
        'rarity': 3,
        'isProducer': true,
        'isEffect': false,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 34 ]
    },
    {
        'id': 120,
        'name': 'Turtle',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0%}% ether/sec for card above',
        'imageFilename': 'assets/images/cards/turtle.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'ether',
        'resourcesAffectedByMultipliers': 'ether',
        'modifiers': [ 6 ]
    },
    {
        'id': 121,
        'name': 'Iron Cat',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0} skulls/sec, but only if an Ice Fish or Dead Fish is in play',
        'imageFilename': 'assets/images/cards/ironcat.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 32 ]
    },
    {
        'id': 122,
        'name': 'Fishery',
        'tier': 3,
        'arena': 3,
        'description': '+{modifier0%}% skulls for all Iron Cats',
        'imageFilename': 'assets/images/cards/fishery.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': true,
        'relevantResource': 'skulls',
        'resourcesAffectedByMultipliers': 'skulls',
        'modifiers': [ 2 ]
    },
    // Arena 4 tier 1
    {
        'id': 123,
        'name': 'Swordsman',
        'tier': 1,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/swordsman.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            10, // HP
            4, // ATK,
            1, // DEF
            2 // MAG
        ]
    },
    {
        'id': 124,
        'name': 'Goblin',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/goblin.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            8, // HP
            4, // ATK,
            1, // DEF
            2, // MAG
            10, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 125,
        'name': 'Toad',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/toad.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            10, // HP
            6, // ATK,
            1, // DEF
            2, // MAG
            15, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 126,
        'name': 'Parrot',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/parrot.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            12, // HP
            6, // ATK,
            2, // DEF
            2, // MAG
            20, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 127,
        'name': 'Roo',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/roo.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            14, // HP
            8, // ATK,
            3, // DEF
            4, // MAG
            25, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 128,
        'name': 'Greedy Bird',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/bird.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            40, // HP
            4, // ATK,
            4, // DEF
            4, // MAG
            35, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 129,
        'name': 'Arachnid',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/arachnid.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            20, // HP
            10, // ATK,
            3, // DEF
            4, // MAG
            30, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 130,
        'name': 'Dingo',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/dingo.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            20, // HP
            10, // ATK,
            4, // DEF
            8, // MAG
            0, // Gold dropped
            10 // Wood dropped
        ]
    },
    {
        'id': 131,
        'name': 'Scarecrow',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/scarecrow.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            24, // HP
            14, // ATK,
            2, // DEF
            8, // MAG
            0, // Gold dropped
            15 // Wood dropped
        ]
    },
    {
        'id': 132,
        'name': 'Valkyrie',
        'tier': 1,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/valkyrie.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            12, // HP
            4, // ATK,
            3, // DEF
            4, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 133,
        'name': 'Rogue',
        'tier': 1,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/rogue.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            8, // HP
            14, // ATK,
            2, // DEF
            6, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 134,
        'name': 'Viking',
        'tier': 1,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/viking.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            24, // HP
            8, // ATK,
            4, // DEF
            3, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 135,
        'name': 'Dwarf',
        'tier': 1,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/dwarf.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            16, // HP
            6, // ATK,
            10, // DEF
            1, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 136,
        'name': 'Potion',
        'tier': 1,
        'arena': 4,
        'description': '[Boost] +{modifier0} HEALTH. The boosted Hero resurrects if it dies.',
        'imageFilename': 'assets/images/cards/potion.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            1, // HP bonus
            0, // ATK bonus
            0, // DEF bonus
            0, // MAG bonus
            0, // Gold dropped bonus%
            0 // Wood dropped bonus%
        ],
        // special boost effects
        'boostPotionEffect': true,
    },
    {
        'id': 137,
        'name': 'Heart',
        'tier': 1,
        'arena': 4,
        'description': '[Boost] +{modifier0} HEALTH.  If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/heart.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            7, // HP bonus
            0, // ATK bonus
            0, // DEF bonus
            0, // MAG bonus
            0.25, // Gold dropped bonus%
            0.25 // Wood dropped bonus%
        ]
    },
    {
        'id': 138,
        'name': 'Well',
        'tier': 1,
        'arena': 4,
        'description': '[Boost] +{modifier3} MAG.  If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/well.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            0, // ATK bonus
            0, // DEF bonus
            4, // MAG bonus
            0.15, // Gold dropped bonus%
            0.15 // Wood dropped bonus%
        ]
    },
    {
        'id': 139,
        'name': 'Mana Potion',
        'tier': 1,
        'arena': 4,
        'description': '[Boost] +{modifier3} MAG.  If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/mana.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            0, // ATK bonus
            0, // DEF bonus
            5, // MAG bonus
            0.2, // Gold dropped bonus%
            0.2 // Wood dropped bonus%
        ]
    },
    {
        'id': 140,
        'name': 'Shield',
        'tier': 1,
        'arena': 4,
        'description': '[Boost] +{modifier2} DEF.  If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/shield.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            0, // ATK bonus
            4, // DEF bonus
            0, // MAG bonus
            0.25, // Gold dropped bonus%
            0.25 // Wood dropped bonus%
        ]
    },
    {
        'id': 141,
        'name': 'Beer',
        'tier': 1,
        'arena': 4,
        'description': '[Boost] +{modifier1} ATK, {modifier2} DEF, {modifier3} MAG.',
        'imageFilename': 'assets/images/cards/beer.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            5, // ATK bonus
            -2, // DEF bonus
            -2, // MAG bonus
            0, // Gold dropped bonus%
            0 // Wood dropped bonus%
        ]
    },
    {
        'id': 142,
        'name': 'Chest',
        'tier': 1,
        'arena': 4,
        'description': '[Boost] +{modifier1} ATK. If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/chest.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            2, // ATK bonus
            0, // DEF bonus
            0, // MAG bonus
            0.3, // Gold dropped bonus%
            0.3 // Wood dropped bonus%
        ]
    },
    {
        'id': 143,
        'name': 'Jar of Death',
        'tier': 1,
        'arena': 4,
        'description': '[Boost] +{modifier1} ATK, critical hit chance x2.  If used on monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/jarofdeath.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            1, // ATK bonus
            0, // DEF bonus
            0, // MAG bonus
            0.3, // Gold dropped bonus%
            0.3 // Wood dropped bonus%
        ],
        'doubleCriticalRateEffect': true,
    },
    {
        'id': 144,
        'name': 'Dead Fish',
        'tier': 1,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/fish.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            5, // HP
            1, // ATK,
            1, // DEF
            1, // MAG
            1, // Gold dropped
            0 // Wood dropped
        ]
    },

    // Arena 4 Tier 2 cards:
    
    {
        'id': 145,
        'name': 'Assassin',
        'tier': 2,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/assassin.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            15, // HP
            22, // ATK,
            6, // DEF
            8, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 146,
        'name': 'Girl',
        'tier': 2,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/girl2.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            24, // HP
            15, // ATK,
            12, // DEF
            9, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 147,
        'name': 'Necromancer',
        'tier': 2,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/necromancer.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            19, // HP
            6, // ATK,
            11, // DEF
            21, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 148,
        'name': 'Pikeman',
        'tier': 2,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/pikeman.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            26, // HP
            19, // ATK,
            10, // DEF
            7, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 149,
        'name': 'Telepath',
        'tier': 2,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/telepath.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            20, // HP
            10, // ATK,
            10, // DEF
            14, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 150,
        'name': 'Nightwatch',
        'tier': 2,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/nightwatch.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            22, // HP
            17, // ATK,
            11, // DEF
            8, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 151,
        'name': 'Windmill',
        'tier': 2,
        'arena': 4,
        'description': '[Boost] +{modifier0} HEALTH, +{modifier1} ATK, +{modifier2} DEF.  If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/mill.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            7, // HP bonus
            5, // ATK bonus
            5, // DEF bonus
            0, // MAG bonus
            0.35, // Gold dropped bonus%
            0.35 // Wood dropped bonus%
        ]
    },
    {
        'id': 152,
        'name': 'Helmet',
        'tier': 2,
        'arena': 4,
        'description': '[Boost] +{modifier2} DEF, {modifier3} MAG.  If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/helmet.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            0, // ATK bonus
            8, // DEF bonus
            -2, // MAG bonus
            0.35, // Gold dropped bonus%
            0.35 // Wood dropped bonus%
        ]
    },
    {
        'id': 153,
        'name': 'Gem',
        'tier': 2,
        'arena': 4,
        'description': '[Boost] {modifier0} HEALTH, +{modifier3} MAG.  If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/gem.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            -5, // HP bonus
            0, // ATK bonus
            0, // DEF bonus
            6, // MAG bonus
            0.35, // Gold dropped bonus%
            0.35 // Wood dropped bonus%
        ]
    },
    {
        'id': 154,
        'name': 'Meteor',
        'tier': 2,
        'arena': 4,
        'description': '[Boost] +{modifier1} ATK, {modifier2} DEF',
        'imageFilename': 'assets/images/cards/meteor.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            8, // ATK bonus
            -6, // DEF bonus
            0, // MAG bonus
            0, // Gold dropped bonus%
            0 // Wood dropped bonus%
        ]
    },
    {
        'id': 155,
        'name': 'Tavern',
        'tier': 2,
        'arena': 4,
        'description': '[Boost] +{modifier0} HEALTH, +{modifier1} ATK, +{modifier2} DEF, +{modifier3} MAG.  Monster: +{modifier4%}% loot.',
        'imageFilename': 'assets/images/cards/tavern.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            5, // HP bonus
            2, // ATK bonus
            2, // DEF bonus
            2, // MAG bonus
            0.35, // Gold dropped bonus%
            0.35 // Wood dropped bonus%
        ]
    },
    {
        'id': 156,
        'name': 'Egg',
        'tier': 2,
        'arena': 4,
        'description': '[Boost] {modifier0} HEALTH. If used on a monster, +{modifier4%}% loot drops.',
        'imageFilename': 'assets/images/cards/egg.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            30, // HP bonus
            0, // ATK bonus
            0, // DEF bonus
            0, // MAG bonus
            1.25, // Gold dropped bonus%
            1.25 // Wood dropped bonus%
        ]
    },
    {
        'id': 157,
        'name': 'Swamper',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/swamper.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            18, // HP
            16, // ATK,
            13, // DEF
            8, // MAG
            100, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 158,
        'name': 'Marauder',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/marauder.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            22, // HP
            8, // ATK,
            8, // DEF
            22, // MAG
            0, // Gold dropped
            100 // Wood dropped
        ]
    },
    {
        'id': 159,
        'name': 'Flying Pig',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/boar.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            18, // HP
            12, // ATK,
            10, // DEF
            6, // MAG
            200, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 160,
        'name': 'Reptile',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/reptile.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            30, // HP
            11, // ATK,
            11, // DEF
            12, // MAG
            400, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 161,
        'name': 'Skeleton',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold, {modifier5} wood',
        'imageFilename': 'assets/images/cards/skeleton.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            26, // HP
            13, // ATK,
            13, // DEF
            12, // MAG
            100, // Gold dropped
            100 // Wood dropped
        ]
    },
    {
        'id': 162,
        'name': 'Kobold',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/kobold.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            30, // HP
            22, // ATK,
            10, // DEF
            10, // MAG
            0, // Gold dropped
            150 // Wood dropped
        ]
    },
    {
        'id': 163,
        'name': 'Spider King',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/spider_king.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            27, // HP
            24, // ATK,
            9, // DEF
            16, // MAG
            600, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 164,
        'name': 'Werewolf',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/werewolf.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            32, // HP
            23, // ATK,
            11, // DEF
            17, // MAG
            0, // Gold dropped
            250 // Wood dropped
        ]
    },
    {
        'id': 165,
        'name': 'Heathen',
        'tier': 2,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/heathen.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            44, // HP
            33, // ATK,
            16, // DEF
            14, // MAG
            0, // Gold dropped
            600 // Wood dropped
        ]
    },

    // Arena 4 tier 3 cards:
    
    {
        'id': 166,
        'name': 'Sailor',
        'tier': 3,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/sailor.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            30, // HP
            21, // ATK,
            17, // DEF
            14, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 167,
        'name': 'Phalanx',
        'tier': 3,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/phalanx.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            20, // HP
            29, // ATK,
            11, // DEF
            13, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 168,
        'name': 'Knight',
        'tier': 3,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/knight.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            27, // HP
            23, // ATK,
            15, // DEF
            11, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 169,
        'name': 'Shaman',
        'tier': 3,
        'arena': 4,
        'description': '[Hero] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG',
        'imageFilename': 'assets/images/cards/shaman.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'hero',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            19, // HP
            6, // ATK,
            7, // DEF
            30, // MAG
            0, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 170,
        'name': 'Mushroom',
        'tier': 3,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/mushroom.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            31, // HP
            17, // ATK,
            15, // DEF
            15, // MAG
            600, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 171,
        'name': 'Golem',
        'tier': 3,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold, {modifier5} wood',
        'imageFilename': 'assets/images/cards/golem.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'gold|wood',
        'resourcesAffectedByMultipliers': 'gold',
        'modifiers': [
            38, // HP
            25, // ATK,
            12, // DEF
            13, // MAG
            400, // Gold dropped
            400 // Wood dropped
        ]
    },
    {
        'id': 172,
        'name': 'Sneaky Barrel',
        'tier': 3,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/marauder.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            36, // HP
            27, // ATK,
            11, // DEF
            19, // MAG
            0, // Gold dropped
            600 // Wood dropped
        ]
    },
    {
        'id': 173,
        'name': 'Beast',
        'tier': 3,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier4} gold',
        'imageFilename': 'assets/images/cards/beast.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            40, // HP
            27, // ATK,
            14, // DEF
            20, // MAG
            2400, // Gold dropped
            0 // Wood dropped
        ]
    },
    {
        'id': 174,
        'name': 'Demon',
        'tier': 3,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/demon.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            55, // HP
            36, // ATK,
            19, // DEF
            17, // MAG
            0, // Gold dropped
            1000 // Wood dropped
        ]
    },
    {
        'id': 175,
        'name': 'Ghoul',
        'tier': 3,
        'arena': 4,
        'description': '[Monster] {modifier0} HEALTH {modifier1} ATK {modifier2} DEF {modifier3} MAG&nbsp;&nbsp; Drops {modifier5} wood',
        'imageFilename': 'assets/images/cards/ghoul.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'monster',
        'relevantResource': 'wood',
        'resourcesAffectedByMultipliers': 'wood',
        'modifiers': [
            60, // HP
            37, // ATK,
            24, // DEF
            19, // MAG
            0, // Gold dropped
            2400 // Wood dropped
        ]
    },
    {
        'id': 176,
        'name': 'Hatchet',
        'tier': 3,
        'arena': 4,
        'description': '[Boost] +{modifier1} ATK.  Monster: +{modifier4%}% loot.',
        'imageFilename': 'assets/images/cards/hatchet.png',
        'rarity': 2,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            10, // ATK bonus
            0, // DEF bonus
            0, // MAG bonus
            0.5, // Gold dropped bonus%
            0.5 // Wood dropped bonus%
        ]
    },
    {
        'id': 177,
        'name': 'Gauntlet',
        'tier': 3,
        'arena': 4,
        'description': '[Boost] +{modifier2} DEF.  Monster: +{modifier4%}% loot.',
        'imageFilename': 'assets/images/cards/gauntlet.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            0, // ATK bonus
            9, // DEF bonus
            0, // MAG bonus
            0.5, // Gold dropped bonus%
            0.5 // Wood dropped bonus%
        ]
    },
    {
        'id': 178,
        'name': 'Hood',
        'tier': 3,
        'arena': 4,
        'description': '[Boost] +{modifier1} ATK, +{modifier3} MAG.  Monster: +{modifier4%}% loot.',
        'imageFilename': 'assets/images/cards/hood.png',
        'rarity': 1,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            4, // ATK bonus
            0, // DEF bonus
            5, // MAG bonus
            0.5, // Gold dropped bonus%
            0.5 // Wood dropped bonus%
        ]
    },
    {
        'id': 179,
        'name': 'Starfire',
        'tier': 3,
        'arena': 4,
        'description': '[Boost] {modifier2} DEF, +{modifier3} MAG.  Monster: +{modifier4%}% loot.',
        'imageFilename': 'assets/images/cards/starfire.png',
        'rarity': 5,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            0, // HP bonus
            0, // ATK bonus
            -7, // DEF bonus
            10, // MAG bonus
            0.5, // Gold dropped bonus%
            0.5 // Wood dropped bonus%
        ]
    },
    {
        'id': 180,
        'name': 'Desert',
        'tier': 3,
        'arena': 4,
        'description': '[Boost] +{modifier0} HEALTH, +{modifier1} ATK, +{modifier2} DEF, +{modifier3} MAG.  Monster: +{modifier4%}% loot.',
        'imageFilename': 'assets/images/cards/desert.png',
        'rarity': 3,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            10, // HP bonus
            5, // ATK bonus
            5, // DEF bonus
            5, // MAG bonus
            0.5, // Gold dropped bonus%
            0.5 // Wood dropped bonus%
        ]
    },
    {
        'id': 181,
        'name': 'Barracks',
        'tier': 3,
        'arena': 4,
        'description': '[Boost] +{modifier0} HEALTH, +{modifier1} ATK, +{modifier2} DEF.  Monster: +{modifier4%}% loot.',
        'imageFilename': 'assets/images/cards/barracks.png',
        'rarity': 4,
        'isProducer': false,
        'isEffect': false,
        'cardType': 'boost',
        'relevantResource': '',
        'resourcesAffectedByMultipliers': '',
        'modifiers': [
            8, // HP bonus
            7, // ATK bonus
            7, // DEF bonus
            0, // MAG bonus
            0.5, // Gold dropped bonus%
            0.5 // Wood dropped bonus%
        ]
    },
];

export function GetCardById(id: number): any {
    for (var i = 0; i < CardModels.length; i++) {
        if (CardModels[i].id == id) {
            return CardModels[i];
        }
    }
    return null;
}
