import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nicenumber'
})
export class NiceNumberPipe implements PipeTransform {
    transform(value: number, args?: any): any {
        if (value >= 1000000000000 || value <= -1000000000000) {
            return (value/1000000000000).toFixed(2).replace(/\.0+$/,'') + "T";
        }
        if (value >= 1000000000 || value <= -1000000000) {
            return (value/1000000000).toFixed(2).replace(/\.0+$/,'') + "B";
        }
        if (value >= 1000000 || value <= -1000000) {
            return (value/1000000).toFixed(2).replace(/\.0+$/,'') + "M";
        }
        if (value >= 1000 || value <= -1000) {
            return (value/1000).toFixed(2).replace(/\.0+$/,'') + "K";
        }
        return value.toFixed(2).replace(/\.0+$/,'');
    }
}
