import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { NiceNumberPipe } from './NiceNumber.pipe';
import { GetCardById } from './CardModels';
import { SoundEffectManager } from './SoundEffectManager.component';
import { GetHeroSlotForBoostSlot, GetMonsterSlotForBoostSlot, GetBoostSlotForHeroSlot, GetBoostSlotForMonsterSlot } from './TickBattle';

@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit, OnDestroy {
    @Input() clickable: boolean = true;
    @Input() isCollectionCard: boolean; // if true, this represents a card in the collection, not a card in play.
    @Input() isBoosterResultCard: boolean = false;
    @Input() cardData: any;
    @Input() slotNumber: number;
    @Input() arenaNumber: number; // only applicable if isCollectionCard is false
    @Input() quantity: number; // only applicable if isCollectionCard is true or if is in booster-pack-opening
    @Input() x: number; // only applicable if isCollectionCard is false
    @Input() y: number; // only applicable if isCollectionCard is false
    @Input() cardsPerRow: number = 3; // for display purposes. can vary depending on which arena is active
    @Input() isBeingPlaced: boolean; // only applicable if isCollectionCard is true.  means user is choosing a slot to place this in.
    @Input() displaySize: number = 3;
    @Input() rank: number = 1;
    // only relevant if cardData.cardType == 'hero' || 'monster':
    @Input() currentHP: number = 0;
    @Input() maxHP: number = 0;
    @Input() damageTakenThisRound: number = 0;
    @Input() foughtInLastTick: boolean = false;
    @Input() droppedLootLastTick: boolean = false;

    damageOpacityVal = 1;
    updateDamageOpacityInterval = null;

    constructor(private sanitizer: DomSanitizer, private niceNumber: NiceNumberPipe) {
    }

    ngOnInit() {
        this.updateDamageOpacityInterval = setInterval(this.updateDamageOpacityVal.bind(this), 16);
    }

    ngOnDestroy() {
        if (this.updateDamageOpacityInterval != null) {
            clearInterval(this.updateDamageOpacityInterval);
            this.updateDamageOpacityInterval = null;
        }
    }

    isAbleToDrop = (dragData: any) => {
        return !this.isCollectionCard && !this.isBoosterResultCard
    }

    public showTooltip() {
        TooltipComponent.instance.showTooltip(this.prepareDescription());
    }

    public hideTooltip() {
        TooltipComponent.instance.hideTooltip();
    }

    public backgroundImageVal() {
        let foregroundUrl = "assets/images/card_foreground.png";
        if (this.cardData.cardType == 'hero') {
            foregroundUrl = "assets/images/card_foreground_hero.png";
        }
        else if (this.cardData.cardType == 'monster') {
            foregroundUrl = "assets/images/card_foreground_monster.png";
        }
        else if (this.cardData.cardType == 'boost') {
            foregroundUrl = "assets/images/card_foreground_boost.png";
        }

        return this.sanitizer.bypassSecurityTrustStyle("url(" + foregroundUrl + "), url(" + this.cardData.imageFilename + ")");   
    }

    public clicked() {
        if (!this.clickable) {
            return;
        }

        if (this.isCollectionCard) {
            if (AppComponent.instance.isSelectingEmptySlot && AppComponent.instance.cardIdToPutInEmptySlot == this.cardData.id) {
                AppComponent.instance.stopChoosingEmptySlotForCard();
            }
            else {
                AppComponent.instance.startChoosingEmptySlotForCard(this.cardData.id);
            }
        }
        else {
            AppComponent.instance.removeCardFromArena(this.arenaNumber, this.slotNumber, this.cardData.id);
            if (AppComponent.instance.isSelectingEmptySlot) {
                AppComponent.instance.tryToPlaceCardInEmptySlot(this.arenaNumber, this.slotNumber, GetCardById(AppComponent.instance.cardIdToPutInEmptySlot), true);
            }
            else {
                SoundEffectManager.instance.playSound('removecard');
            }
        }
    }

    private prepareDescription(): string {
        let result = this.cardData.description;
        if (result.indexOf("{modifier0%}") != -1) {
            result = result.replace("{modifier0%}", this.niceNumber.transform(AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 0) * 100));
        }
        if (result.indexOf("{modifier1%}") != -1) {
            result = result.replace("{modifier1%}", this.niceNumber.transform(AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 1) * 100));
        }
        if (result.indexOf("{modifier2%}") != -1) {
            result = result.replace("{modifier2%}", this.niceNumber.transform(AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 2) * 100));
        }
        if (result.indexOf("{modifier3%}") != -1) {
            result = result.replace("{modifier3%}", this.niceNumber.transform(AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 3) * 100));
        }
        if (result.indexOf("{modifier4%}") != -1) {
            result = result.replace("{modifier4%}", this.niceNumber.transform(AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 4) * 100));
        }
        if (result.indexOf("{modifier5%}") != -1) {
            result = result.replace("{modifier5%}", this.niceNumber.transform(AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 5) * 100));
        }
        if (result.indexOf("{modifier0}") != -1) {
            result = result.replace("{modifier0}", this.niceNumber.transform(AppComponent.instance.UniversalAura(AppComponent.instance.gameState.selectedArenaNumber) * 
            AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 0)));
        }
        if (result.indexOf("{modifier1}") != -1) {
            result = result.replace("{modifier1}", this.niceNumber.transform(AppComponent.instance.UniversalAura(AppComponent.instance.gameState.selectedArenaNumber) * 
            AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 1)));
        }
        if (result.indexOf("{modifier2}") != -1) {
            result = result.replace("{modifier2}", this.niceNumber.transform(AppComponent.instance.UniversalAura(AppComponent.instance.gameState.selectedArenaNumber) * 
            AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 2)));
        }
        if (result.indexOf("{modifier3}") != -1) {
            result = result.replace("{modifier3}", this.niceNumber.transform(AppComponent.instance.UniversalAura(AppComponent.instance.gameState.selectedArenaNumber) * 
            AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 3)));
        }
        if (result.indexOf("{modifier4}") != -1) {
            result = result.replace("{modifier4}", this.niceNumber.transform(AppComponent.instance.UniversalAura(AppComponent.instance.gameState.selectedArenaNumber) * 
            AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 4)));
        }
        if (result.indexOf("{modifier5}") != -1) {
            result = result.replace("{modifier5}", this.niceNumber.transform(AppComponent.instance.UniversalAura(AppComponent.instance.gameState.selectedArenaNumber) * 
            AppComponent.instance.rankAdjustedModifier(this.cardData.id, AppComponent.instance.gameState.selectedArenaNumber, 5)));
        }

        // if hero...
        if (!this.isCollectionCard && !this.isBoosterResultCard && this.cardData.cardType == 'hero' && this.slotNumber != null) {
            let estimatedLootPerSecond = AppComponent.instance.gameState['arena' + this.arenaNumber].cardSlots[this.slotNumber]['estimatedLootPerSecond'];
            if (estimatedLootPerSecond['gold'] > 0 || estimatedLootPerSecond['wood'] > 0) {
                result += "&nbsp;&nbsp;(est. +" + this.niceNumber.transform(estimatedLootPerSecond['gold']) + " gold, +" + this.niceNumber.transform(estimatedLootPerSecond['wood']) + " wood/sec)";
            }
        }

        return result;
    }

    public mouseOver() {
        if (!this.isCollectionCard && !this.isBoosterResultCard) {
            AppComponent.instance.hoveredCardSlot = this.slotNumber;
        }

        this.showTooltip();
    }

    public mouseOut() {
        if (AppComponent.instance.hoveredCardSlot == this.slotNumber) {
            AppComponent.instance.hoveredCardSlot = null;
        }

        this.hideTooltip();
    }

    public shouldShowYellowEffectBorder(): boolean {
        return this.shouldShowGreenEffectBorder() && this.shouldShowRedEffectBorder();
    }

    public shouldShowGreenEffectBorder(): boolean {
        if (this.isCollectionCard || this.isBoosterResultCard || (!this.cardData.isProducer && this.cardData.cardType != 'hero' && this.cardData.cardType != 'monster')) {
            return false;
        }

        // special case: in battle realms, 'boost'-type cards affect either one monster or one hero.  so check to see whether a hero or a monster slot is affected by this card.
        if (this.cardData.cardType == 'hero') {
            if (AppComponent.instance.hoveredCardSlot == null) {
                return false;
            }
            
            let boostSlot = GetBoostSlotForHeroSlot(this.arenaNumber, AppComponent.instance.gameState['arena' + this.arenaNumber].cardSlots[this.slotNumber]);
            if (boostSlot) {
                return AppComponent.instance.gameState['arena' + this.arenaNumber].cardSlots[AppComponent.instance.hoveredCardSlot] == boostSlot;
            }
        }
        else if (this.cardData.cardType == 'monster') {
            if (AppComponent.instance.hoveredCardSlot == null) {
                return false;
            }
            
            let boostSlot = GetBoostSlotForMonsterSlot(this.arenaNumber, AppComponent.instance.gameState['arena' + this.arenaNumber].cardSlots[this.slotNumber]);
            if (boostSlot) {
                return AppComponent.instance.gameState['arena' + this.arenaNumber].cardSlots[AppComponent.instance.hoveredCardSlot] == boostSlot;
            }
        }

        // other realms: determined based on whether the hovered card is giving multipliers to this card.
        let resourcesAffectedByMultipliers = this.cardData.resourcesAffectedByMultipliers.split("|");
        if (resourcesAffectedByMultipliers.length > 0) {
            for (var resource of resourcesAffectedByMultipliers) {
                if (resource.length > 0) {
                    let multipliersThisResource = AppComponent.instance.gameState['arena' + this.arenaNumber].cardSlots[this.slotNumber].multipliers[resource];
                    let multipliersThisResourceFromHoveredCardThatArePositive = multipliersThisResource.filter((mult) => {
                        return mult.fromSlot == AppComponent.instance.hoveredCardSlot && mult.amount > 0;
                    });

                    if (multipliersThisResourceFromHoveredCardThatArePositive.length > 0) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    public shouldShowRedEffectBorder(): boolean {
        if (this.isCollectionCard || this.isBoosterResultCard || !this.cardData.isProducer) {
            return false;
        }

        // other realms: determined based on whether the hovered card is giving penalties to this card.
        let resourcesAffectedByMultipliers = this.cardData.resourcesAffectedByMultipliers.split("|");
        if (resourcesAffectedByMultipliers.length > 0) {
            for (var resource of resourcesAffectedByMultipliers) {
                if (resource.length > 0) {
                    let multipliersThisResource = AppComponent.instance.gameState['arena' + this.arenaNumber].cardSlots[this.slotNumber].multipliers[resource];
                    let multipliersThisResourceFromHoveredCardThatAreNegative = multipliersThisResource.filter((mult) => {
                        return mult.fromSlot == AppComponent.instance.hoveredCardSlot && mult.amount <= 0;
                    });

                    if (multipliersThisResourceFromHoveredCardThatAreNegative.length > 0) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    public getDragData() {
        return {
            isCollectionCard: this.isCollectionCard,
            arenaNumber: this.arenaNumber,
            slotNumber: this.slotNumber,
            cardData: this.cardData
        }
    }
    
    public droppedAnotherCardOntoHere(ev) {
        let cardDroppedIsCollectionCard: boolean = ev.dragData.isCollectionCard;
        let otherSlotNumber: number = ev.dragData.slotNumber;
        let otherArenaNumber: number = ev.dragData.arenaNumber;
        let otherCardData: any = ev.dragData.cardData;

        if (this.isCollectionCard && otherArenaNumber != null && otherSlotNumber != null) {
            // if this is a collection card, and the card being dragged onto it is a grid card...

            // just remove that card from the grid
            AppComponent.instance.removeCardFromArena(otherArenaNumber, otherSlotNumber, otherCardData.id);
            SoundEffectManager.instance.playSound('removecard');
        }
        else if (!this.isCollectionCard && this.slotNumber != null && this.arenaNumber != null) {
            // else if this is a grid card...

            if (!cardDroppedIsCollectionCard) {
                // the card being dragged over here was one already in the grid. so remove it from the grid first...
                
                // unless it was itself being dropped on itself
                if (otherSlotNumber == this.slotNumber && otherArenaNumber == this.arenaNumber) {
                    return;
                }
                
                AppComponent.instance.removeCardFromArena(otherArenaNumber, otherSlotNumber, otherCardData.id);

            }
            // remove this card from the grid
            let targetSlotNumber = this.slotNumber;
            let targetArenaNumber = this.arenaNumber;
            let myCardData = this.cardData;

            AppComponent.instance.removeCardFromArena(targetArenaNumber, targetSlotNumber, this.cardData.id);
            // place the dropped card in our spot
            AppComponent.instance.tryToPlaceCardInEmptySlot(targetArenaNumber, targetSlotNumber, otherCardData, true); 

            // if the other card was a grid card, try to put this card where the other card was (so that their positions essentially just swapped)
            if (!cardDroppedIsCollectionCard) {
                AppComponent.instance.tryToPlaceCardInEmptySlot(otherArenaNumber, otherSlotNumber, myCardData, false);
            }
        }
    }

    public getMaxHP() {
        return AppComponent.instance.rankAdjustedModifier(this.cardData.cardId, this.arenaNumber, 0);
    }

    public updateDamageOpacityVal() {
        if (AppComponent.instance.lastTickTimeInMilliseconds > 0) {
            let millisecondsPassed = (new Date().getTime()) - AppComponent.instance.lastTickTimeInMilliseconds;
            this.damageOpacityVal = 1 - (millisecondsPassed / 1000);
        }
    }

    public damageTakenStyle() {
        let style = "opacity: " + (this.foughtInLastTick? this.damageOpacityVal : 0);
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }

    public getMonsterBoostCard(): any {
        let boostSlot = GetBoostSlotForMonsterSlot(this.arenaNumber, AppComponent.instance.gameState['arena' + this.arenaNumber].cardSlots[this.slotNumber]);
        if (boostSlot && boostSlot.cardInSlot) {
            return GetCardById(boostSlot.cardInSlot.id);
        }
        return null;
    }
    
}
