import { AppComponent } from './app.component';

export function AmountOfSkullsPerSecond(arenaNumber: number) {
    var skullsPerSecond = 0;

    var cardSlots = AppComponent.instance.gameState['arena' + arenaNumber].cardSlots;
    for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
        if (cardSlots[slotNumber].cardInSlot != null) {
            var currentCardId = cardSlots[slotNumber].cardInSlot.id;

            var skullsForThisSlot: number = 0;

            switch (currentCardId) {
                case 69: // Priestess
                    skullsForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'skulls');
                    break;
                case 72: // Necromancer, consumes 1 skull per second
                    skullsForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                case 74: // Devourer, +some skulls/sec
                case 78: // Undertaker, +some skulls/sec
                case 79: // Rabbid, +some skulls/sec
                case 82: // Sailor
                case 86: // Shaman
                case 89: // Demon
                case 103: // Kobold
                case 105: // Knight Capt
                case 106: // Horror
                case 110: // Nightwatch
                case 111: // Yeti
                case 114: // Sharky
                case 116: // Trampler
                    skullsForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    skullsForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'skulls');
                    break;
                case 80: // Death Imp, +some skulls/sec (and +ether and +feathers)
                    skullsForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    skullsForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'skulls');
                    break;
                case 109: // Missionary, consumes skulls/sec
                    skullsForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    break;
                case 117: // Spearfisher.  +skulls/sec, +more skulls/sec if a Dead Fish is in play
                    skullsForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    if (AppComponent.instance.cardExistsInArena(arenaNumber, 91)) {
                        skullsForThisSlot += rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    }
                    skullsForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'skulls');
                    break;
                
                case 121: // Iron Cat.  + some skulls/sec, but only if an Ice Fish or Dead Fish is in play
                    skullsForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    if (!AppComponent.instance.cardExistsInArena(arenaNumber, 91) && !AppComponent.instance.cardExistsInArena(arenaNumber, 92)) {
                        skullsForThisSlot = 0;
                        skullsForThisSlot = 0;
                    }
                    skullsForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'skulls');
                    break;

            }

            skullsForThisSlot *= AppComponent.instance.UniversalAura(arenaNumber);
            skullsPerSecond += skullsForThisSlot;
        }
    }

    return skullsPerSecond;
}

function rankAdjustedModifier(cardId: number, arenaNumber: number, modifierIndex: number): number {
    return AppComponent.instance.rankAdjustedModifier(cardId, arenaNumber, modifierIndex);
}
