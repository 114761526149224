import { AppComponent } from './app.component';
import { AchievementShowerComponent } from './AchievementShower.component';
import { SteamComponent } from './steam.component';

export const Achievements: any[] = [
    {
        id: 1,
        title: "Road to riches",
        description: "Earn 1K gold in Intro Realm",
        imageFilename: "1.png"
    },
    {
        id: 2,
        title: "Mr. Moneybags",
        description: "Earn 100K gold in Intro Realm",
        imageFilename: "2.png"
    },
    {
        id: 3,
        title: "Elon",
        description: "Earn 10M gold in Intro Realm",
        imageFilename: "3.png"
    },
    {
        id: 4,
        title: "Feeding Time",
        description: "Empower a Crab in Intro Realm",
        imageFilename: "4.png"
    },
    {
        id: 5,
        title: "Abahcolypse Now",
        description: "Have a sheep call a Meteor to the center of the earth",
        imageFilename: "5.png"
    },
    {
        id: 6,
        title: "Raiding The Hobby Shop",
        description: "Buy 100,000 5-card packs in one go",
        imageFilename: "6.png"
    },
    {
        id: 7,
        title: "You Always Doubted Me",
        description: "Earn 25+ gold/sec with only Ghouls",
        imageFilename: "7.png"
    },
    {
        id: 8,
        title: "Ascension!",
        description: "Ascend, earning at least one gem, for the first time",
        imageFilename: "8.png"
    },
    {
        id: 11,
        title: "Lil Jack, Big Axe",
        description: "Earn +500 wood/sec with only Lil' Lumberjacks and Hatchets",
        imageFilename: "11.png"
    },
    {
        id: 9,
        title: "Haunted Woods",
        description: "Unlock the Haunted Woods expansion",
        imageFilename: "9.png"
    },
    {
        id: 10,
        title: "Underworld",
        description: "Unlock the Underworld expansion",
        imageFilename: "10.png"
    },
    {
        id: 12,
        title: "Plains of War",
        description: "Unlock the Plains of War expansion",
        imageFilename: "12.png"
    },
    {
        id: 13,
        title: "Mascot Slayer",
        description: "In Plains of War, kill the Scarecrow in one hit",
        imageFilename: "13.png"
    },
    {
        id: 14,
        title: "Harder Than It Looks",
        description: "Kill a Dead Fish in 4 or more rounds",
        imageFilename: "14.png"
    }
    
];

export function GetAchievementById(achievementId: number): any {
    for (var i = 0; i < Achievements.length; i++) {
        if (Achievements[i].id == achievementId) {
            return Achievements[i];
        }
    }

    return null;
}

export function UnlockAchievement(achievementId: number): void {
    AppComponent.instance.gameState.achievementsUnlocked[achievementId] = true;
    if (AchievementShowerComponent.instance)
        AchievementShowerComponent.instance.show(GetAchievementById(achievementId));

    if (SteamComponent.instance) {
        SteamComponent.instance.unlockAchievement(achievementId);
    }
}

export function AchievementIsUnlocked(achievementId: number): boolean {
    return (typeof(AppComponent.instance.gameState.achievementsUnlocked[achievementId]) != 'undefined' && AppComponent.instance.gameState.achievementsUnlocked[achievementId]);
}

export function NumberOfAchievementsUnlocked() {
    let result = 0;

    for (var i = 1; i <= Achievements.length; i++) {
        if (AchievementIsUnlocked(i)) {
            result++;
        }
    }

    return result;
}
