import { AppComponent } from './app.component';

export function AmountOfFeathersPerSecond(arenaNumber: number) {
    var feathersPerSecond = 0;

    var cardSlots = AppComponent.instance.gameState['arena' + arenaNumber].cardSlots;
    for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
        if (cardSlots[slotNumber].cardInSlot != null) {
            var currentCardId = cardSlots[slotNumber].cardInSlot.id;

            var feathersForThisSlot: number = 0;

            switch (currentCardId) {
                case 69: // Priestess
                    feathersForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 2) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'feathers');
                    break;
                case 71: // Hellspider
                case 75: // Timid Giant
                case 77: // Lizard
                case 94: // Bigmouth
                case 95: // Werewolf
                case 97: // Warlock
                    feathersForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'feathers');
                    break;
                case 80: // Death Imp
                    feathersForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 2) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'feathers');
                    break;
                case 82: // Sailor
                    feathersForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'feathers');
                    break;
                case 109: // Missionary = +feathers, -skulls
                    feathersForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'feathers');
                    if (!hasEnoughSkulls(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        feathersForThisSlot = 0;
                    }
                    break;
                case 110: // Nightwatch
                case 115: // Gwen
                    feathersForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'feathers');
                    break;
            }

            feathersForThisSlot *= AppComponent.instance.UniversalAura(arenaNumber);
            feathersPerSecond += feathersForThisSlot;
        }
    }

    return feathersPerSecond;
}

function hasEnoughSkulls(arenaNumber: number, slotNumber, amountNeeded: number): boolean {
    if (isImuneFromConsumingSkulls(arenaNumber, slotNumber)) {
        return true;
    }
    let result = AppComponent.instance.gameState.resources['arena' + arenaNumber].skulls >= amountNeeded;
    return result;
}

function popCardOutOfSlot(arenaNumber: number, slotNumber: number, cardId: number): void {
    AppComponent.instance.removeCardFromArena(arenaNumber, slotNumber, cardId);
}

function isImuneFromConsumingSkulls(arenaNumber: number, slotNumber: number): boolean {
    return AppComponent.instance.cardIsImmuneFromConsumingResource(arenaNumber, slotNumber, 'skulls');
}

function rankAdjustedModifier(cardId: number, arenaNumber: number, modifierIndex: number): number {
    return AppComponent.instance.rankAdjustedModifier(cardId, arenaNumber, modifierIndex);
}
