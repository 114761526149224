import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'tooltip',
    template: `<span [innerHtml]="currentString"></span>`
})
export class TooltipComponent {
    public static instance: TooltipComponent = null;

    public currentString: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {
        TooltipComponent.instance = this;
    }

    public showTooltip(newVal: string) {
        newVal = this.replaceAllInString(newVal, "gold", "<img src='assets/images/resources/gold.png' alt='Gold' title='Gold' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "wood", "<img src='assets/images/resources/wood.png' alt='Wood' title='Wood' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "iron", "<img src='assets/images/resources/iron.png' alt='Iron' title='Iron' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "ether", "<img src='assets/images/resources/ether.png' alt='Ether' title='Ether' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "skulls", "<img src='assets/images/resources/skulls.png' alt='Skulls' title='Skulls' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "feathers", "<img src='assets/images/resources/feathers.png' alt='Feathers' title='Feathers' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "ATK", "<img src='assets/images/icons/attack.png' alt='Attack' title='Attack' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "DEF", "<img src='assets/images/icons/armor.png' alt='Defense' title='Defense' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "HEALTH", "<img src='assets/images/icons/health.png' alt='Health' title='Health' class='resource-icon-in-tooltip'>");
        newVal = this.replaceAllInString(newVal, "MAG", "<img src='assets/images/icons/magic.png' alt='Magic' title='Magic' class='resource-icon-in-tooltip'>");
        this.currentString = this.sanitizer.bypassSecurityTrustHtml(newVal);
    }

    public hideTooltip() {
        this.currentString = "";
    }

    private replaceAllInString = function(targetString: string, search: string, replacement: string) {
        return targetString.split(search).join(replacement);
    };
}
