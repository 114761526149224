import { Component, Input, OnInit } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { KongregateComponent } from './kongregate.component';
import { GetCardById } from './CardModels';

@Component({
    selector: 'upgrade-window',
    templateUrl: './UpgradeWindow.component.html'
})
export class UpgradeWindowComponent implements OnInit {
    
    public static instance: UpgradeWindowComponent = null;
    public isVisible: boolean = false;
    public appComponent: AppComponent;

    public arenaNumber: number;
    public cardId: number;
    public isMonsterCard: boolean = false;

    public isAwaitingKredPurchaseResponse: boolean = false;

    objectKeys = Object.keys;

    constructor() {
        UpgradeWindowComponent.instance = this;
    }

    ngOnInit() {
        this.appComponent = AppComponent.instance;
    }

    public show(arenaNumber: number, cardId: number): void {
        if (this.isAwaitingKredPurchaseResponse) {
            return;
        }

        this.arenaNumber = arenaNumber;
        this.cardId = cardId;
        this.isMonsterCard = GetCardById(this.cardId).cardType == 'monster';

        this.isVisible = true;
    }

    public pressedOk(): void {
        if (this.isAwaitingKredPurchaseResponse) {
            return;
        }
        this.isVisible = false;
    }

    public hide(): void {
        if (this.isAwaitingKredPurchaseResponse) {
            return;
        }
        this.isVisible = false;
    }

    public upgradeWithQty(): void {
        AppComponent.instance.tryToRankUpCard(this.arenaNumber, this.cardId);
    }

    public upgradeWithGems(): void {
        if (this.isAwaitingKredPurchaseResponse) {
            return;
        }
        AppComponent.instance.tryToRankUpCardWithGems(this.arenaNumber, this.cardId);
    }

    public combineCards(): void {
        if (this.isAwaitingKredPurchaseResponse) {
            return;
        }
        AppComponent.instance.tryToRankUpCard(this.arenaNumber, this.cardId);
    }

    public massCombineCards(): void {
        if (this.isAwaitingKredPurchaseResponse) {
            return;
        }
        AppComponent.instance.tryToRankUpAllCards(this.arenaNumber);
    }

    public gemCount(): number {
        return AppComponent.instance.gameState.gems;
    }

    public upgradeWithKreds(): void {
        this.isAwaitingKredPurchaseResponse = true;
        
        KongregateComponent.instance.buyMicrotransaction('upgradecardlevel', { arenaNumber: this.arenaNumber, cardId: this.cardId });
    }

    public kredUpgradeCallback(info: any): void {
        let cardId = parseInt("" + info['cardId']);
        let arenaNumber = parseInt("" + info['arenaNumber']);
        AppComponent.instance.forceToRankUpCard(arenaNumber, cardId);
    }

    public reduceLevel(): void {
        AppComponent.instance.rankDownCard(this.arenaNumber, this.cardId);
    }

}
