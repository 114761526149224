import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BoosterPackPurchaserComponent } from './booster-pack-purchaser.component';

@Component({
    selector: 'booster-pack-opening',
    templateUrl: 'booster-pack-opening.component.html'
})
export class BoosterPackOpeningComponent {

    public static instance: BoosterPackOpeningComponent = null;

    @Input() imageFilename: string;
    @Input() cards: any[]; // unused?

    public timer: number = 0;
    public isActive: boolean = false;
    public skipAnimations: boolean = false;

    private timerInterval = null;
    public cardsReceived: any[];

    public hasPurchasedCardsBefore: boolean = true;
    
    constructor(private sanitizer: DomSanitizer) {
        BoosterPackOpeningComponent.instance = this;
    }

    public start(imageFilename: string, cardsReceived: any[]) {
        this.hasPurchasedCardsBefore = (AppComponent.instance.gameState.hasPurchasedCardsBefore == true);
        AppComponent.instance.gameState.hasPurchasedCardsBefore = true;
        this.skipAnimations = !AppComponent.instance.gameState.enableAnimations;

        this.imageFilename = imageFilename;
        this.cardsReceived = cardsReceived;
        this.isActive = true;
        this.timer = 0;

        if (this.timerInterval != null) {
            clearInterval(this.timerInterval);
        }
        this.timerInterval = setInterval(this.intervalFunction.bind(this), 10);
    }

    public intervalFunction() {
        this.timer += 10;
        if (this.timer > 2000) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
        }
    }

    public backgroundImageVal(): string {
        return "url(" + this.imageFilename + ")";
    }

    public transformStyle(): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(
            this.scaleValue() + " " + this.rotationValue()
        );
    }

    public scaleValue(): SafeStyle {
        if (this.timer < 1000) {
            return " scale(" + 2*Math.abs(Math.sin(this.timer/300 / 3.14)) + ")";
        }
        else {
            return " scale(" + (2 - (this.timer-1000)/1000) + ")";
        }
    }

    public rotationValue(): SafeStyle {
        if (this.timer < 1000) {
            return "rotate(" + 2*(10*Math.abs(Math.sin(this.timer/50 / 3.14))) + "deg)";
        }
        else {
            return "";
        }
    }

    public opacityVal() {
        if (this.skipAnimations) {
            return 0;
        }
        if (this.timer < 1000) {
            return 1;
        }
        else if (this.timer < 2000) {
            return 1 - Math.abs(Math.sin( (this.timer - 1000) / 300 / 3.14));
        }
        else {
            return 0;
        }
    }

    public cardsReceivedOpacityVal() {
        if (this.skipAnimations) {
            return 1;
        }
        if (this.timer < 1500) {
            return 0;
        }
        if (this.timer >= 2000) {
            return 1;
        }
        return (this.timer-1500)/500;
    }
}
