import { Component, Input } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'error-message',
    templateUrl: './error-message.component.html'
})
export class ErrorMessageComponent {
    
    public static instance: ErrorMessageComponent = null;
    public isVisible: boolean = false;
    
    public message: string = '';

    constructor(private sanitizer: DomSanitizer) {
        ErrorMessageComponent.instance = this;
    }

    public show(message: string) {
        this.message = message;
        this.isVisible = true;
    }

    public pressedOk(): void {
        this.isVisible = false;
    }

    public getMessage() {
        return this.sanitizer.bypassSecurityTrustHtml(this.message);
    }

}
