import { Component, Input } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'save-layout-dialog',
    templateUrl: './save-layout-dialog.component.html'
})
export class SaveLayoutDialogComponent {
    
    public static instance: SaveLayoutDialogComponent = null;
    public isVisible: boolean = false;
    public existingLayouts: any[] = [];

    public selectedLayoutIndex: number = null;
    public newLayoutName: string = '';
    public arenaNumber: number;


    constructor() {
        SaveLayoutDialogComponent.instance = this;
    }

    public show(existingLayouts: any[], arenaNumber: number): void {
        this.selectedLayoutIndex = null;
        this.newLayoutName = '';
        this.arenaNumber = arenaNumber;
        this.existingLayouts = existingLayouts;
        this.isVisible = true;
    }

    public hide(): void {
        this.isVisible = false;
    }

    public pressedOk(): void {
        this.newLayoutName = this.newLayoutName.substring(0, 50); // trim anything past 50 characters, just in case
        AppComponent.instance.saveLayout(this.arenaNumber, this.selectedLayoutIndex, this.newLayoutName);
        this.isVisible = false;
    }

    public pressedCancel(): void {
        this.isVisible = false;
    }

    public selectedLayoutChanged(): void {
        console.log(this.selectedLayoutIndex);
        if (this.selectedLayoutIndex == null) {
            this.newLayoutName = '';
        }
        else {
            if (this.existingLayouts[this.selectedLayoutIndex]) {
                this.newLayoutName = this.existingLayouts[this.selectedLayoutIndex].name;
            }
            else {
                this.newLayoutName = '';
            }
        }
    }

    public deleteSelectedLayout(): void {
        AppComponent.instance.deleteSavedLayout(this.arenaNumber, this.selectedLayoutIndex);
        this.selectedLayoutIndex = null;
    }

}
