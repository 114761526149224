import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { TooltipComponent } from './tooltip.component';
import { BoosterPackOpeningComponent } from './booster-pack-opening.component';
import { CardModels, GetCardById } from './CardModels';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NiceNumberPipe } from './NiceNumber.pipe';
import { ErrorMessageComponent } from './error-message.component';
import { GetAchievementById } from './Achievements';

@Component({
    selector: 'achievement-shower',
    templateUrl: 'AchievementShower.component.html'
})
export class AchievementShowerComponent implements OnInit {
    
    public static instance: AchievementShowerComponent = null;

    timer: number = 0;
    currentAchievement: any = null;
    queuedAchievements: any[] = [];
    processingInterval: any = null;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        AchievementShowerComponent.instance = this;
    }

    public show(achievementData: any): void {
        this.queuedAchievements.push(achievementData);
        if (this.currentAchievement == null) {
            this.showNextAchievement();
        }
    }

    private showNextAchievement(): void {
        this.currentAchievement = this.queuedAchievements[0];
        this.queuedAchievements.splice(0, 1);

        if (this.processingInterval == null) {
            this.processingInterval = setInterval(this.perTick.bind(this), 16);
        }
    }

    public perTick(): void {
        this.timer += 16/1000;

        if (this.timer > 5) {
            this.timer = 0;
            this.currentAchievement = null;
            clearInterval(this.processingInterval);
            this.processingInterval = null;

            if (this.queuedAchievements.length > 0) {
                this.showNextAchievement();
            }
        }
    }

    public getStyle() {
        let opacityVal = 0;
        if (this.timer > 1 && this.timer < 4) {
            opacityVal = 1;
        }
        else {
            if (this.timer < 1) {
                opacityVal = Math.sin(0.5*3.14 * this.timer);
            }
            else { // this.timer > 4
                opacityVal = Math.sin(0.5*3.14 * (1 - this.timer));
            }
        }
        return this.sanitizer.bypassSecurityTrustStyle("opacity: " + opacityVal);
    }

}
