
export const arena2DefaultValues: any = {
    
    cardSlots: [
        {
            x: 0,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 10,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 1,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 50,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 2,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 100,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 3,
            y: 0,
            slotIsUnlocked: false,
            unlockCurrency: 'iron',
            unlockPrice: 1000,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 0,
            y: 1,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 750,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 1,
            y: 1,
            slotIsUnlocked: true,
            unlockCurrency: 'wood',
            unlockPrice: 0,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 2,
            y: 1,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 200,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 3,
            y: 1,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 750,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 0,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'iron',
            unlockPrice: 10,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 1,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 200,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 2,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'iron',
            unlockPrice: 50,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 3,
            y: 2,
            slotIsUnlocked: false,
            unlockCurrency: 'iron',
            unlockPrice: 125,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 0,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'iron',
            unlockPrice: 200,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 1,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'iron',
            unlockPrice: 100,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 2,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 1250,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        },
        {
            x: 3,
            y: 3,
            slotIsUnlocked: false,
            unlockCurrency: 'wood',
            unlockPrice: 500,
            cardInSlot: null,
            multipliers: {
                gold: [],
                iron: [],
                wood: [],
                ether: [],
                blood: []
            }
        }
    ]
};