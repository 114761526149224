import { Component, OnInit } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { KongregateComponent } from './kongregate.component';
import { ErrorMessageComponent } from './error-message.component';
import { Achievements, AchievementIsUnlocked } from './Achievements';

@Component({
    selector: 'other-tab',
    templateUrl: './other-tab.component.html'
})
export class OtherTabComponent implements OnInit {
    public appComponent: AppComponent;
    public subtab: string = 'achievements';

    ngOnInit() {
        this.appComponent = AppComponent.instance;
    }

    getAllAchievements() {
        return Achievements;
    }

    achievementIsUnlocked(achievementId: number): boolean {
        return AchievementIsUnlocked(achievementId);
    }
}
