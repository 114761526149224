import { AppComponent } from './app.component';

export function AmountOfEtherPerSecond(arenaNumber: number) {
    var etherPerSecond = 0;

    var cardSlots = AppComponent.instance.gameState['arena' + arenaNumber].cardSlots;
    for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
        if (cardSlots[slotNumber].cardInSlot != null) {
            var currentCardId = cardSlots[slotNumber].cardInSlot.id;

            var etherForThisSlot: number = 0;

            switch (currentCardId) {
                case 69: // Priestess
                    etherForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'ether');
                    break;
                case 72: // Necromancer (+1.25 ether/sec, -1 skulls/sec)
                    etherForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'ether');
                    if (!hasEnoughSkulls(arenaNumber, slotNumber, Math.abs(rankAdjustedModifier(currentCardId, arenaNumber, 1)))) {
                        popCardOutOfSlot(arenaNumber, slotNumber, currentCardId);
                        etherForThisSlot = 0;
                    }
                    break;
                case 73: // Possessed
                    etherForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'ether');
                    break;
                case 80: // Death Imp
                    etherForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'ether');
                    break;
                // plain Ether/sec cards
                case 81: // Frog
                case 88: // Sneaky Barrel
                case 99: // Crawler
                case 100: // Slug
                case 102: // Winged Imp
                case 104: // Baby Dragon
                case 108: // Lucy
                case 115: // Gwen
                case 119: // Icestalker
                    etherForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'ether');
                    break;
                case 93: // Fisherman
                    etherForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    // additional Ether/sec if an Ice Fish is below
                    let cardBelow = AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1);
                    if (cardBelow != null) {
                        let cardIdBelow = AppComponent.instance.GetCardIdAtSlot(arenaNumber, cardBelow);
                        if (cardIdBelow == 92) {
                            etherForThisSlot += rankAdjustedModifier(currentCardId, arenaNumber, 1);
                        }
                    }
                    etherForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'ether');
                    break;
                case 114: // Sharky
                    etherForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 1) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'ether');
                    break;
            }

            etherForThisSlot *= AppComponent.instance.UniversalAura(arenaNumber);
            etherPerSecond += etherForThisSlot;
        }
    }

    return etherPerSecond;
}

function rankAdjustedModifier(cardId: number, arenaNumber: number, modifierIndex: number): number {
    return AppComponent.instance.rankAdjustedModifier(cardId, arenaNumber, modifierIndex);
}

function hasEnoughSkulls(arenaNumber: number, slotNumber, amountNeeded: number): boolean {
    if (isImuneFromConsumingSkulls(arenaNumber, slotNumber)) {
        return true;
    }
    let result = AppComponent.instance.gameState.resources['arena' + arenaNumber].skulls >= amountNeeded;
    return result;
}

function popCardOutOfSlot(arenaNumber: number, slotNumber: number, cardId: number): void {
    AppComponent.instance.removeCardFromArena(arenaNumber, slotNumber, cardId);
}

function isImuneFromConsumingSkulls(arenaNumber: number, slotNumber: number): boolean {
    return AppComponent.instance.cardIsImmuneFromConsumingResource(arenaNumber, slotNumber, 'skulls');
}
