import { AppComponent } from './app.component';

export function AmountOfGoldPerSecond(arenaNumber: number) {
    var goldPerSecond = 0;

    var cardSlots = AppComponent.instance.gameState['arena' + arenaNumber].cardSlots;
    for (var slotNumber = 0; slotNumber < cardSlots.length; slotNumber++) {
        if (cardSlots[slotNumber].cardInSlot != null) {
            var currentCardId = cardSlots[slotNumber].cardInSlot.id;

            var goldForThisSlot: number = 0;

            switch (currentCardId) {
                case 1: // Ghoul
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 2: // Crab
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    if (AppComponent.instance.cardExistsInArena(arenaNumber, 4)) { // if there's a mushroom in play, increase gold per second further
                        goldForThisSlot += rankAdjustedModifier(currentCardId, arenaNumber, 1);
                    }
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 3: // Gold Mine
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 4: // Mushroom
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 5: // Mace
                    goldForThisSlot = 0;
                    break;
                case 6: // Gem
                    goldForThisSlot = 0;
                    break;
                case 7: // Beer
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0) * AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 8: // Greedy Bird
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    if (AppComponent.instance.getNumberOfCardsInPlay(arenaNumber) == 1) {
                        goldForThisSlot += rankAdjustedModifier(currentCardId, arenaNumber, 1); // +5 gold if this is the only card in play
                    }
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 9: // Windmill
                    goldForThisSlot = 0;
                    break;
                case 10: // Scaredy Cat
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    if (AppComponent.instance.getSlotNumbersOfCardsInRow(arenaNumber, cardSlots[slotNumber].y, slotNumber).length != 0) {
                        goldForThisSlot = 0; // reset to 0 if this isn't the only card in its row
                    }
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 11: // Ice Sword
                    goldForThisSlot = 0;
                    break;
                case 12: // Coin
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 13: // Ghost
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 14: // Adventuring Fox
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 15: // Cultist
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 16: // Skeleton
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 17: // Sheep
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 18: // Carrot.  12 gold production, unless something is above or below it.
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    if (AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, -1) != null) {
                        goldForThisSlot = 0;
                    }
                    if (AppComponent.instance.getCardSlotNumberAtXYOffset(arenaNumber, slotNumber, 0, 1) != null) {
                        goldForThisSlot = 0;
                    }
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 19: // Golem.  13 gold, not affected by any bonuses.
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    break;
                case 20: // Naga
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                    break;
                case 21: // Meteor
                    goldForThisSlot = rankAdjustedModifier(currentCardId, arenaNumber, 0);
                    goldForThisSlot *= AppComponent.instance.getTotalMultiplierForSlot(arenaNumber, slotNumber, 'gold');
                break;
            }

            goldForThisSlot *= AppComponent.instance.UniversalAura(arenaNumber);
            goldPerSecond += goldForThisSlot;
        }
    }

    return goldPerSecond;
}

function rankAdjustedModifier(cardId: number, arenaNumber: number, modifierIndex: number): number {
    return AppComponent.instance.rankAdjustedModifier(cardId, arenaNumber, modifierIndex);
}