import { Component, Input } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { GetCardById } from './CardModels';

@Component({
    selector: 'empty-card-slot',
    template: `
        <div (click)="clicked()" [class]="getCssClass()" *ngIf="isUnlocked" droppable [dragOverClass]="'drag-target-border'" (onDrop)="cardDroppedHere($event)">
            <div style="padding: 10px;" *ngIf="!isUnlocked" [innerHtml]="getUnlockText()">
            </div>
        </div>
        <div (click)="clicked()" [class]="getCssClass()" *ngIf="!isUnlocked">
            <div style="padding: 10px;" [innerHtml]="getUnlockText()">
            </div>
        </div>
        <div *ngIf="x == cardsPerRow - 1" class="break-float"></div>`,
    styleUrls: ['./empty-card-slot.component.css']
})
export class EmptyCardSlotComponent {
    @Input() arenaNumber: number;
    @Input() slotNumber: number;
    @Input() isUnlocked: boolean;
    @Input() unlockPriceText: string;
    @Input() clickable: boolean;
    @Input() x: number;
    @Input() y: number;
    @Input() cardsPerRow: number = 3;
    @Input() slotType: string = "normal";

    constructor(private sanitizer: DomSanitizer) {
    }

    public showTooltip() {
    }

    public hideTooltip() {
        TooltipComponent.instance.hideTooltip();
    }

    public cardDroppedHere(ev) {
        let cardDroppedIsCollectionCard: boolean = ev.dragData.isCollectionCard;
        let slotNumber: number = ev.dragData.slotNumber;
        let arenaNumber: number = ev.dragData.arenaNumber;
        let cardData: any = ev.dragData.cardData;

        if (this.isUnlocked) {
            if (!cardDroppedIsCollectionCard) {
                // the card being dragged over here was one already in the grid. so remove it from the grid first.
                AppComponent.instance.removeCardFromArena(arenaNumber, slotNumber, cardData.id);
            }
            AppComponent.instance.tryToPlaceCardInEmptySlot(this.arenaNumber, this.slotNumber, cardData, true);            
        }
    }

    public clicked() {
        if (!this.isUnlocked) {
            AppComponent.instance.tryToBuyEmptySlot(this.arenaNumber, this.slotNumber);
            return;
        }
        else if (AppComponent.instance.isSelectingEmptySlot && AppComponent.instance.cardIdToPutInEmptySlot != null) {
            AppComponent.instance.tryToPlaceCardInEmptySlot(this.arenaNumber, this.slotNumber, GetCardById(AppComponent.instance.cardIdToPutInEmptySlot), true);
        }
    }

    public getCssClass(): string {
        let classStr = "empty-card-slot left-float " + (this.clickable ? " clickable " :"");
        classStr += this.slotType + " ";
        return classStr;
    }

    public getUnlockText(): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(this.unlockPriceText);
    }
}
