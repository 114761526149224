import { Component, Input } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { AppComponent } from './app.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { NiceNumberPipe } from './NiceNumber.pipe';

@Component({
    selector: 'loot-drop-animation',
    templateUrl: './loot-drop-animation.component.html'
})
export class LootDropAnimationComponent {
    @Input() cardData: any;
    @Input() secondsRemainingInAnimation: number;
    @Input() arenaNumber: number;
    @Input() monsterBoostCard: any = null;

    constructor(private sanitizer: DomSanitizer, private niceNumber: NiceNumberPipe) {
    }

    public getStyle() {
        let topAmount = 0;
        let leftAmount = 0;

        topAmount = Math.sin(0.25*3.14 * (1 - this.secondsRemainingInAnimation)) * -300;
        leftAmount = Math.sin(0.25*3.14 * (1 - this.secondsRemainingInAnimation)) * -20;
        let opacityAmount = Math.min(1, 0.5 + this.secondsRemainingInAnimation);

        let styleStr: string = "position: absolute; top: " + topAmount + "px; left: " + leftAmount + "px; opacity: " + opacityAmount;
        return this.sanitizer.bypassSecurityTrustStyle(styleStr);
    }

    public getHtml() {
        let result = "";

        switch (this.arenaNumber) {
            case 4:
                // arena 4 has gold and wood drops
                let goldDropped = AppComponent.instance.rankAdjustedModifier(this.cardData.id, this.arenaNumber, 4);
                let woodDropped = AppComponent.instance.rankAdjustedModifier(this.cardData.id, this.arenaNumber, 5);
                
                // apply universal aura (aka ascension boost)
                goldDropped *= AppComponent.instance.UniversalAura(this.arenaNumber);
                woodDropped *= AppComponent.instance.UniversalAura(this.arenaNumber);

                if (this.monsterBoostCard) {
                    goldDropped *= 1+(AppComponent.instance.rankAdjustedModifier(this.monsterBoostCard.id, this.arenaNumber, 4)* AppComponent.instance.UniversalAura(this.arenaNumber));
                    woodDropped *= 1+(AppComponent.instance.rankAdjustedModifier(this.monsterBoostCard.id, this.arenaNumber, 5)* AppComponent.instance.UniversalAura(this.arenaNumber));
                }

                if (goldDropped > 0) {
                    result += "<div>" + this.niceNumber.transform(goldDropped) + "</div><img src='assets/images/resources/gold.png' alt='gold' class='loot-drop-animation-resource-icon'><br>";
                }
                if (woodDropped > 0) {
                    result += "<div>" + this.niceNumber.transform(woodDropped) + "</div><img src='assets/images/resources/wood.png' alt='wood' class='loot-drop-animation-resource-icon'>";
                }
                break;
        }


        return result;
    }
}
